import {D5BaseElement} from 'middlewares/userScript/elems/D5BaseElement';
import {D5Button} from 'middlewares/userScript/elems/public-interfaces';
import {IButtonOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import ModifiableArray from 'middlewares/userScript/utils/modifiable/modifiableArray';
import {BUTTON_TYPE} from 'services/interfaces/global-interfaces';
import LayoutType from 'utilsOld/systemObjects/LayoutType';

export declare interface D5FormButtonOptions {
  name: string;
  formItems: ModifiableArray<ILayoutItem<IButtonOptions>>;
}

export class D5FormButton extends D5BaseElement<IButtonOptions> implements D5Button {
  constructor(options: D5FormButtonOptions) {
    super(options.formItems);
    this._name = options.name;
    this.index = this.collection.findIndex(item => item.name === this._name && LayoutType.isButton(item.itemType));
    if (this.index === -1) {
      throw new Error(`Button ${this._name} is not found`);
    }
    const item = this.collection.item(this.index);
    this._id = item.id;
  }

  get title() {
    return this.getOption('title');
  }

  get icon() {
    return this.getOption('icon');
  }

  set icon(icon: string) {
    this.setOption('icon', icon);
  }

  get isPressed() {
    return this.getOption('isPressed');
  }

  set isPressed(value: boolean) {
    this.setOption('isPressed', value);
  }

  set title(title: string) {
    this.setOption('title', title);
  }

  get isDisabled(): boolean {
    return !!this.getOption('disabled');
  }

  set isDisabled(value: boolean) {
    this.setOption('disabled', !!value);
  }

  get type(): BUTTON_TYPE {
    return this.getOption('buttonType');
  }
}
