export const actionTypes = {
  SET: 'elementsUserSettings/SET',
};

export type ElementsUserSettingsState = Record<string, ElementsUserSettingsItem>;

export type ElementsUserSettingsInitialState = {
  [formKey: string]: Record<string, ElementsUserSettingsItem>;
};

export type ElementUserSettingsObj = {formKey: string; elementsUserSettings: ElementsUserSettingsState};

export enum ELEMENTS_NAMES_STATE {
  TOOLBAR_BUTTONS = 'toolbarButtons',
}

export type ElementsUserSettingsItem = {
  [ELEMENTS_NAMES_STATE.TOOLBAR_BUTTONS]: {
    isDisabled?: boolean;
  };
};
