export enum actionTypes {
  SET_CLEAR_INDICATORS = 'clearIndicators/set',
  CLEAN_CLEAR_INDICATORS = 'clearIndicators/clean',
}

export const setClearIndicator = ({name, indicator}: {name: string; indicator: boolean}) => ({
  type: actionTypes.SET_CLEAR_INDICATORS,
  payload: {
    name,
    indicator,
  },
});

export const cleanClearIndicator = () => ({
  type: actionTypes.CLEAN_CLEAR_INDICATORS,
});
