import {ClearIndicatorActionTypes, ClearIndicatorState} from './types';
import {actionTypes} from './actions';

const initialState: ClearIndicatorState = {};

export const clearIndicatorReducer = (
  state = initialState,
  {type, payload}: ClearIndicatorActionTypes
): ClearIndicatorState => {
  switch (type) {
    case actionTypes.SET_CLEAR_INDICATORS:
      return {...state, [payload!.name]: payload!.indicator};
    case actionTypes.CLEAN_CLEAR_INDICATORS:
      return initialState;
    default:
      return state;
  }
};
