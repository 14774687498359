import {system} from 'services/objects';
import {SysForm} from 'services/interfaces/sysObjects';
import {FormCreateMode, FormType} from 'services/interfaces/global-interfaces';
import SysFormFieldCollection from 'utilsOld/systemObjects/SysFormFieldCollection';
import {isDefined, isEmptyValue} from '../services/SecondaryMethods/typeUtils';

export function getSysDisplayField(fld: string) {
  return system.SYSTEM_PREFIX + '_display_' + fld;
}

export function getSysDisplayFieldAndStyleField(fld: string, styleFld: string) {
  return system.SYSTEM_PREFIX + '_display_' + fld + '_' + styleFld;
}

export function isTagStyleField(fld: string, styleFld: string, sourceField: string) {
  return getSysDisplayFieldAndStyleField(fld, styleFld) === sourceField;
}

export function getSysField(fld: string) {
  return system.SYSTEM_PREFIX + fld;
}

export function isSysField(fld = '') {
  return fld.indexOf(system.SYSTEM_PREFIX) === 0;
}

export function getLookupDisplayField(objectField: string, displayField: string) {
  return objectField + '.' + displayField;
}

export function fieldByID(id: number | undefined, sysForm: SysForm) {
  if (!isDefined(id)) return;
  const fields = new SysFormFieldCollection(sysForm.Sys_FormFields);
  return fields.findByID(id!);
}

export const tableLike = (formType: FormType) =>
  formType === FormType.LIST || formType === FormType.TREE || formType === FormType.KANBAN || formType === FormType.SCHEDULER || formType === FormType.TILE_LIST;

export const NODE_HAS_CHILDREN_FIELD = getSysField(system.HasChildren);

export const getDefaultMode = (id?: number | string | (number | string)[]) => {
  return isEmptyValue(id) ? FormCreateMode.ADD : FormCreateMode.EDIT;
};
