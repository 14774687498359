import './wdyr';
import {getConfigData} from 'configCustom';
import {saveDataToLS} from 'utilsOld/configJSONUtils';
import {UserSettingsState} from 'services/userSettings/types';
import {system} from './services/objects';
import config from 'devextreme/core/config';
import './shims';
import './index.scss';

config({licenseKey: process.env.REACT_APP_DX_LICENCE});

(async () => {
  const [userConfig] = await Promise.all([getConfigData()]);

  const config: {
    application: UserSettingsState;
  } = {
    ...userConfig,
    [system.CONFIG_PARAMS.APPLICATION]: {
      ...userConfig[system.CONFIG_PARAMS.APPLICATION]
    }
  };

  if (window) {
    // @ts-ignore
    window.D5AutoTest = {};
  }

  // @ts-ignore
  saveDataToLS(config); //set default themes to local storage app_settings

  try {
    const {default: initApplication} = await import(/* webpackChunkName: "initApplication" */ './initApplication');
    initApplication(config);
    const element = document.getElementById('global-spiner-wrapper');
    element?.remove();
  } catch (e) {}
})();
