import {DrawerKey, OpenDrawerArgs, SecondaryDrawerItem, SecondaryDrawerState, Type} from './types';
import {SecondaryDrawerStateSaver} from 'utilsOld/DrawerStateSaver';
import {createAction, createReducer} from '@reduxjs/toolkit';
import {secondaryDrawerOpenedSelector} from './selectors';
import {isDefined} from '../SecondaryMethods/typeUtils';
import {userIdSelector} from '../globalSelectors';
import {createAsyncThunk} from '../reduxUtils';

// const {UserID} = JSON.parse(localStorage.getItem(system.current_user) || '{}');
// const secDrawerSaver = new SecondaryDrawerStateSaver(UserID);
const initialState = (): SecondaryDrawerState => ({});

export const handleSecondaryDrawerOpen = createAsyncThunk<SecondaryDrawerItem & {drawerKey: DrawerKey}, OpenDrawerArgs>(
  'secondaryDrawer/open',
  (args, thunkAPI) => {
    const {saveOpenedState = false, ...rest} = args ?? {opened: false};
    let drawerOpened = !secondaryDrawerOpenedSelector(thunkAPI.getState(), rest.drawerKey);
    if (isDefined(rest.opened)) {
      drawerOpened = rest.opened;
    }
    if (saveOpenedState) {
      let userId = userIdSelector(thunkAPI.getState());
      const saver = new SecondaryDrawerStateSaver('' + userId);
      saver.opened = drawerOpened;
    }

    return Promise.resolve({
      ...rest,
      opened: drawerOpened
    });
  }
);

export const closeSecondaryDrawer = createAction<{drawerKey: DrawerKey; type?: Type}>('secondaryDrawer/close');

export const closeRightDrawer = createAction<{drawerKey: DrawerKey; formKey: string}>('rightDrawer/close');

const removeOrClose = (state: SecondaryDrawerState, {drawerKey, type}: {drawerKey: DrawerKey; type?: Type}) => {
  const stack = state[drawerKey] ?? [];
  if (type) {
    const indexOfDrawerPanel = stack.findIndex(item => item.options?.type === type);

    if (indexOfDrawerPanel !== -1) {
      stack.splice(indexOfDrawerPanel, 1);
    }
  } else {
    stack?.pop();
  }

  if (!stack?.length) delete state[drawerKey];
};

const removeOrCloseRightDrawers = (
  state: SecondaryDrawerState,
  {drawerKey, formKey}: {drawerKey: DrawerKey; formKey: string}
) => {
  const stack = state[drawerKey] ?? [];
  const indexOfDrawerPanel = stack.findIndex(item => item.formKey === formKey);
  if (indexOfDrawerPanel !== -1) {
    stack.splice(indexOfDrawerPanel, 1);
  }
  if (!stack?.length) delete state[drawerKey];
};

const secondaryDrawerReducer = createReducer(initialState, builder => {
  builder
    .addCase(handleSecondaryDrawerOpen.fulfilled, (state, {payload}) => {
      if (!payload.opened) {
        removeOrClose(state as SecondaryDrawerState, {drawerKey: payload.drawerKey});
      } else {
        if (!state[payload.drawerKey]) state[payload.drawerKey] = [];
        const stack = state[payload.drawerKey];
        const indexOfDrawerPanel = stack.findIndex(item => {
          return item.formKey === payload.formKey && item.options?.type === payload.options?.type;
        });
        //Если мы открываем один и тот же тип дровера, то нужно просто положить его сверху
        if (indexOfDrawerPanel !== -1) {
          const [item] = stack.splice(indexOfDrawerPanel, 1);
          stack.push(item);
        } else {
          stack.push(payload);
        }
      }
    })
    .addCase(closeSecondaryDrawer, (state, {payload}) => {
      removeOrClose(state as SecondaryDrawerState, payload);
    })
    .addCase(closeRightDrawer, (state, {payload}) => {
      removeOrCloseRightDrawers(state as SecondaryDrawerState, payload);
    });
});
export default secondaryDrawerReducer;
