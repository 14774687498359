import {IButtonOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {ModifiableArray} from 'middlewares/userScript/utils/modifiable';
import {D5FormButton} from 'middlewares/userScript/elems';
import {D5Button} from 'middlewares/userScript/elems/public-interfaces';
import {ElementsUserSettingsState} from './elementsUserSettingsState';
import {BUTTON_POSITION} from '../../../services/interfaces/global-interfaces';
import {D5ToolbarButton} from '../elems/D5ToolbarButton';

export class TableButtonsState extends ModifiableArray<ILayoutItem<IButtonOptions>> {
  public elementsUserSettings: ElementsUserSettingsState;

  constructor(buttons: any, elementsUserSettings: ElementsUserSettingsState) {
    super(buttons);
    this.elementsUserSettings = elementsUserSettings;
  }

  /** Конвертирует кнопку тулбара в пользовательский интерфейс D5Button
   * @param name - имя кнопки
   */
  getD5Button(name: string): D5Button | undefined {
    let tableButton = this.find(btn => btn.name === name);
    if (!tableButton) {
      return;
    }
    const isToolbar = (pos: BUTTON_POSITION) =>
      [BUTTON_POSITION.TOOLBAR, BUTTON_POSITION.CONTEXT_MENU, BUTTON_POSITION.ALL].includes(pos);
    if (isToolbar(tableButton.options.position)) {
      return new D5ToolbarButton({
        formItems: this,
        name,
        elementsUserSettings: this.elementsUserSettings,
      });
    }
    return new D5FormButton({
      formItems: this,
      name,
    });
  }

  /** Конвертирует все кнопки тулбара в пользовательский интерфейс D5Button
   */
  getD5Buttons(): D5Button[] {
    return this.map(
      btn =>
        new D5FormButton({
          formItems: this,
          name: btn.name,
        })
    );
  }
}
