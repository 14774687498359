import {sharedData} from 'utilsOld/sharedData';
import {formatMessage} from 'utilsOld/formatMessage';
import {Messages} from 'services/lang/messages';
import {formatDateToAPI} from 'utilsOld/valueCasters';
import {checkDateInMinMaxRange} from '../../../FilterPanel/utils';
import {FIELD_EDITOR_TYPE} from 'services/interfaces/global-interfaces';
import {isDefined} from 'services/SecondaryMethods/typeUtils';

export function createCustomDateRule(formName: string) {
  return (caption: string) => ({
    type: 'custom',
    message: formatMessage(Messages.Errors.FiltersDateOutOfRange, [caption]),
    reevaluate: true,
    validationCallback: ({
      column: {visible,dataType},
      value,
    }: {
      column: {visible: boolean; required: boolean,dataType:string};
      value: any;
    }) => {

      if (dataType!==FIELD_EDITOR_TYPE.DATE && dataType!==FIELD_EDITOR_TYPE.DATETIME) return true;
      /**
       * @type {RowValidator}
       */
      //@ts-ignore
      const validator = sharedData.rowValidators[formName];
      if (!visible || !validator?.saving || !isDefined(value)) return true;

      return checkDateInMinMaxRange(formatDateToAPI(value));
    },
  });
}
