import {mainDrawerOpenedSelector} from './selectors';
import {isDefined} from '../SecondaryMethods/typeUtils';
import {userIdSelector} from '../globalSelectors';
import {MainDrawerStateSaver} from 'utilsOld/DrawerStateSaver';
import {
  clearLocalAndSessionStorage,
  setApplicationLang,
  setRegionalSettings,
  setSettings
} from '../SecondaryMethods/userSettings';
import {fields, system} from '../objects';
import {LanguageCode, Locale, NEW_WINDOW_OPENING_MODE} from '../interfaces/global-interfaces';
import {createAsyncThunk} from '../reduxUtils';
import {mainDrawerToggle, OverlayState, partialUpdateState, resetSettingsState} from './reducer';

export const defaultRegionalLocale = Locale.uk;

export const handleMainDrawerOpen = createAsyncThunk<void, boolean | undefined>(
  'overlay/handleMainDrawerOpen',
  (open, {dispatch, getState}) => {
    let drawerOpened = !mainDrawerOpenedSelector(getState());
    let userId = userIdSelector(getState());
    if (isDefined(open)) {
      drawerOpened = !!open;
    }

    const saver = new MainDrawerStateSaver(String(userId));
    saver.opened = drawerOpened;
    dispatch(mainDrawerToggle(drawerOpened));
  }
);

export const resetSettings = createAsyncThunk<void, void>('overlay/resetSettings', (_, {dispatch}) => {
  const {
    USER_SETTINGS: { NEW_WINDOW_OPENED_MODE},
  } = system;

  clearLocalAndSessionStorage();
  setRegionalSettings({[fields.RegionalLocale]: defaultRegionalLocale})
  setApplicationLang(LanguageCode.ua)
  setSettings(NEW_WINDOW_OPENED_MODE, NEW_WINDOW_OPENING_MODE.LAST_OPENED_FORM);
  dispatch(resetSettingsState());
});

export const setSettingsAction = createAsyncThunk<void, Partial<OverlayState>>(
  'overlay/setSettingsAction',
  (settings, {dispatch}) => {
    const {
      USER_SETTINGS: {
        ALTER_LINES_COLOR,
        HORIZ_SEPAR_LINES,
        VERT_SEPAR_LINES,
        NEW_WINDOW_OPENED_MODE,
      },
    } = system;

    let newState: Partial<OverlayState> = {};
    if (settings.regionalSettings) {
      setRegionalSettings({[fields.RegionalLocale]: settings.regionalSettings});
      newState.regionalSettings = settings.regionalSettings;
    }

    if (settings.lang) {
      setApplicationLang(settings.lang)
    }

    if (settings.newWindowOpenedMode) {
      setSettings(NEW_WINDOW_OPENED_MODE, settings.newWindowOpenedMode);
    }

    if (settings.altColor) {
      setSettings(ALTER_LINES_COLOR, settings.altColor);
      newState.altColor = settings.altColor;
    }

    if (settings.horizSeparLines) {
      setSettings(HORIZ_SEPAR_LINES, settings.horizSeparLines);
      newState.horizSeparLines = settings.horizSeparLines;
    }

    if (settings.vertSeparLines) {
      setSettings(VERT_SEPAR_LINES, settings.vertSeparLines);
      newState.vertSeparLines = settings.vertSeparLines;
    }

    if (settings.editorStylingMode) {
      setSettings(system.USER_SETTINGS.EDITOR_STYLING_MODE, settings.editorStylingMode);
      newState.editorStylingMode = settings.editorStylingMode;
    }

    dispatch(partialUpdateState(newState));
  }
);
