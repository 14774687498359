import {FilterItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {createFilterItemsHashMap, getFilterName} from 'services/SecondaryMethods/filterUtils';
import {isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {DocPanelStorageFilter} from '../FilterSettings/types';
import {findVisibleFilter} from '../utils';
import {FilterStateObject} from 'services/filters/types';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {toID, toSymbol} from '../../forms/utils';
import {getFilteredCustomConditionList} from '../../forms/hooks';
import {FILTER_OPERATIONS} from '../../../services/interfaces/global-interfaces';

export function updateByParentItems(
  items: FilterItem[],
  parentItems: FilterItem[],
  parentFilter: Record<string, FilterStateObject>
): FilterItem[] {
  const parentFilterItems = createFilterItemsHashMap(parentItems);
  return items.map(item => {
    if (!LayoutType.isFilter(item.itemType)) {
      return item;
    }
    const filterName = getFilterName({
      objectFieldIDName: item.options.filterField.objectFieldIDName,
      name: item.options.filterField.name,
    });

    if (!parentFilterItems[filterName]) {
      return item;
    }

    let realOperation = parentFilter[filterName].operation;
    if (
      parentFilterItems[filterName].options.isCustomConditionList &&
      !parentFilterItems[filterName].options.conditionList.includes(toID(realOperation))
    ) {
      const filteredCustomConditionList = getFilteredCustomConditionList(parentFilterItems[filterName].options.conditionList)
      if (filteredCustomConditionList?.[0]) {
        realOperation = toSymbol(filteredCustomConditionList?.[0]) as FILTER_OPERATIONS;
      }
    }

    return {
      ...item,
      isRequired: !!parentFilterItems[filterName].isRequired,
      isVisible: !!parentFilterItems[filterName].isVisible,
      isReadOnly: !!parentFilterItems[filterName].isReadOnly,
      options: {
        ...item.options,
        realOperation,
        hasIsBlank: !!parentFilter[filterName].hasIsBlank,
        isDisabled: !!parentFilterItems[filterName].options.isDisabled,
        value: parentFilter[filterName].value,
        label: parentFilterItems[filterName].options.label,
        conditionList: parentFilterItems[filterName].options.conditionList,
        isCustomConditionList: parentFilterItems[filterName].options.isCustomConditionList,
        filter: isEmptyValue(parentFilterItems[filterName].options.filter)
          ? item.options.filter
          : parentFilterItems[filterName].options.filter,
      },
    };
  });
}

export function filterDocPanelItems(
  items: FilterItem[],
  visibleFilters: DocPanelStorageFilter[],
  customizableMap: Record<string, boolean>
) {
  const newItems: FilterItem[] = [];
  items.forEach(item => {
    const filter = findVisibleFilter(visibleFilters, item.id);
    if (filter) {
      let disabled = customizableMap[filter.id];

      if (filter.isVisible || filter.isCustomizable) {
        newItems.push({
          ...item,
          isVisible: (disabled ? item.isVisible : filter.isVisible) || !isEmptyValue(item.options.value),
        });
      }
    } else {
      newItems.push(item);
    }
  });

  return newItems;
}
