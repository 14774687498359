import {userFunction} from '../userFunction';
import {D5FormButton} from 'middlewares/userScript/elems';
import {commonResult} from 'middlewares/userScript/results';

/**
 * Event fires after a button on a form was clicked (not Grid ToolBar)
 * @param {ItemsState} items
 * @param {GroupsState} groups
 * @param {function} dispatch
 * @param {FormObject[]} subForms
 * @param {string} eventName
 * @param {number} eventType
 * @param {FormObject} parentForm
 * @param {FormObject} processedForm
 */
export const onButtonClick = ({items, groups, eventType, subForms, processedForm, dispatch, parentForm, name}) => {
  const buttonItems = () => {
    const isKanbanCardButton = processedForm.kanbanCardItems.getButtons().some(btn => btn.name === name);

    if (isKanbanCardButton) return processedForm.kanbanCardItems;
    return items;
  };

  const currButton =
    processedForm.editorButtons.getD5Button(name) ||
    new D5FormButton({
      name,
      formItems: buttonItems()
    });

  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    name,
    processedForm,
    args: [currButton],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
