import store from 'store';
import {SNACKBAR_TYPES} from '../interfaces/global-interfaces';
import {showSnackbar} from '../snackbar/reducer';
import {SnackBarCreator} from '../snackbar/types';

export const showNotification = ({msg, title, type}: {msg: string; title?: string; type: SNACKBAR_TYPES}) => {
  return store.dispatch(
    showSnackbar({
      message: msg,
      type,
      title,
    })
  );
};

type NotificationArgs = {
  msg: string; 
  title?: string; 
  docUrl?: string; 
  creator?: SnackBarCreator;
  responseId?: string;
  codeError?: string;
} | string;

const transformArgToObject = (args: NotificationArgs): {message: string; title?: string; docUrl?: string, responseId?: string, codeError?: string} => {
  return typeof args === 'string'
    ? {message: args}
    : {
        ...args,
        message: args.msg,
      };
};

export function showErrorNotification(args: NotificationArgs) {
  return store.dispatch(
    showSnackbar({
      type: SNACKBAR_TYPES.Error,
      ...transformArgToObject(args),
    })
  );
}

export const showSuccessNotification = (args: NotificationArgs) => {
  return store.dispatch(
    showSnackbar({
      type: SNACKBAR_TYPES.Success,
      ...transformArgToObject(args),
    })
  );
};

export const showWarningNotification = (args: NotificationArgs) => {
  return store.dispatch(
    showSnackbar({
      type: SNACKBAR_TYPES.Warning,
      ...transformArgToObject(args),
    })
  );
};

export const showHelpNotification = (args: NotificationArgs) => {
  return store.dispatch(
    showSnackbar({
      type: SNACKBAR_TYPES.Help,
      ...transformArgToObject(args),
    })
  );
};
