import {ELayoutType} from '../services/SecondaryMethods/formItems/itemInterfaces';
import {FILTER_LAYOUT} from '../services/interfaces/global-interfaces';

function createDataTestId(formName: string, itemType: ELayoutType, itemName: string) {
  return `${formName}-${itemType}-${itemName}`;
}

export function createFieldTestId(formName: string, itemName: string) {
  return createDataTestId(formName, ELayoutType.FORM_FIELD, itemName);
}

function createFilterTestId(formName: string, itemName: string, layout: FILTER_LAYOUT) {
  return createDataTestId(formName, ELayoutType.FILTER, itemName) + `-${layout}`;
}

export function createDocFilterTestId(formName: string, itemName: string) {
  return createFilterTestId(formName, itemName, FILTER_LAYOUT.DOCK_PANEL);
}

export function createHeaderFilterTestId(formName: string, itemName: string) {
  return createFilterTestId(formName, itemName, FILTER_LAYOUT.HEADER_FILTER);
}

export function createLayoutFilterTestId(formName: string, itemName: string) {
  return createFilterTestId(formName, itemName, FILTER_LAYOUT.LAYOUT);
}

export function createFormFilterTestId(formName: string, itemName: string) {
  return createFilterTestId(formName, itemName, FILTER_LAYOUT.FORM_FILTER);
}
