import {SysForm, SysFormFilterFields} from 'services/interfaces/sysObjects';
import {DocPanelStorageFilter, StorageVisibleFilter} from './FilterSettings/types';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import SysFormFilterFieldCollection from 'utilsOld/systemObjects/SysFormFilterFieldCollection';
import {LayoutFilterItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {isArray, isDefined, isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {formatMessage} from 'utilsOld/formatMessage';
import {Messages} from 'services/lang/messages';
import {validateGroup} from 'services/SecondaryMethods';
import {FILTER_OPERATIONS} from '../../services/interfaces/global-interfaces';
import {FilterStateObject} from '../../services/filters/types';
import {MIN_MAX_DATE, system} from '../../services/objects';
import {formatDateToAPI} from '../../utilsOld/valueCasters';

export const formFilterToVisibleFilter = (filters: SysFormFilterFields[] = []): DocPanelStorageFilter[] =>
  filters.map(field => ({
    id: field.ID,
    isVisible: !!field.IsVisible,
    title: field.Title,
    order: field.Order,
    required: !!field.IsRequired,
    isCustomizable: !!field.IsCustomizable
  }));

export function findVisibleFilter(visibleFilters: StorageVisibleFilter[], id: number) {
  return visibleFilters.find(element => element.id === id);
}

export function getDisabledFilters(sysForm: SysForm, parentFilter: Record<string, FilterStateObject>) {
  const collection = new SysFormFilterFieldCollection(new SysFormWrapper(sysForm).filterFields || []);
  return collection.toArrayOfInstances().reduce((res, fld) => {
    res[fld.ID] = !(parentFilter[fld.name] || fld).isCustomizable;
    return res;
  }, {} as Record<string, boolean>);
}

// проверка для дат, value может прийти с массивом пустых значений (после удаления дат)
const checkEmptyValue = (value: Array<any>) => {
  if (isArray(value)) {
    return value.every(item => isEmptyValue(item));
  }
  return isEmptyValue(value);
};

type DateType = Date | string | undefined;

/**
 * @returns true если дата входит в промежуток MIN/MAX
 */
export const checkDateInMinMaxRange = (date: DateType | DateType[]): boolean => {
  let dates: DateType[] = isArray(date) ? (date as DateType[]) : [date as DateType];
  dates = dates.filter(value => isDefined(value));
  return !dates.some(date => {
    const dateObj = new Date(date!);
    return dateObj <= MIN_MAX_DATE.MIN || dateObj >= MIN_MAX_DATE.MAX;
  });
};
export const validateEditors = (validationGroup: string, formItems: LayoutFilterItem[]) => {
  const {isblank, isnotblank} = FILTER_OPERATIONS;

  const emptyRequiredFields = formItems
    .filter(
      filter =>
        ![isblank, isnotblank].includes(filter.options.realOperation) &&
        filter.isRequired &&
        checkEmptyValue(filter.options.value)
    )
    .map(item => item.options.label.text || item.name);

  const checkInvalidDate = formItems
    .filter(item => {
      const itemValue = isArray(item.options.value) ? item.options.value : formatDateToAPI(item.options.value);

      return (
        isDefined(item.options.value) &&
        item.options.fieldType === system.FIELD_TYPE.DATE &&
        !checkDateInMinMaxRange(itemValue)
      );
    })
    .map(item => item.options.label.text || item.name);

  if (!emptyRequiredFields.length && !checkInvalidDate.length) return;

  validateGroup(validationGroup);
  if (checkInvalidDate.length) {
    throw new Error(formatMessage(Messages.Errors.FiltersDateOutOfRange, [`${checkInvalidDate.join(';')};`]));
  }
  throw new Error(formatMessage(Messages.Errors.FiltersAreRequired, [`${emptyRequiredFields.join(';')};`]));
};

export const updateItemsWithVisibility = (items: LayoutFilterItem[], visibleFilters: StorageVisibleFilter[]) => {
  return items.map((element: LayoutFilterItem) => {
    const item = visibleFilters?.find(formItem => element.id === formItem.id);
    if (item) {
      return {
        ...element,
        isVisible: item.isVisible
      };
    }
    return element;
  });
};
