import {fields, objects} from '../objects';
import {checkFilterCustomConditions} from '../SecondaryMethods/errors/checks';
import {FormType, USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';

/**
 *
 * @param {SysSubForm} subForm
 * @returns {SysSubForm}
 */
const prepareSysSubFrom = subForm => {
  return {
    ...subForm,
    DetailFormID: subForm[fields.DetailFormID_Name],
    FormID: subForm[fields.FormID_Name]
  };
};
/**
 *
 * @param {SysFormFields} formField
 * @returns {SysFormFields}
 */
const prepareSysFormFields = formField => {
  return {
    ...formField,
    FormID: formField[fields.FormID_Name],
    [fields.ObjectFieldID_LinkedObjectID_FormForSelectByDefault]:
      formField[fields.ObjectFieldID_LinkedObjectID_FormForSelectByDefault_Name]
  };
};
/**
 *
 * @param {SysFormFilterFields} formField
 * @returns {SysFormFields}
 */
const prepareSysFormReportFields = formField => {
  const eventTypeForReportFields = {
    [USER_EVENT_TYPE.ON_INIT_LOOKUP_FILTER]: USER_EVENT_TYPE.ON_INIT_LOOKUP_FIELD,
    [USER_EVENT_TYPE.ON_FILTER_VALUE_CHANGED]: USER_EVENT_TYPE.ON_FIELD_VALUE_CHANGED
  };

  const updatedObj = {
    ...formField,
    [fields.ObjectFieldID_IsRequired]: formField[fields.IsRequired]
  };
  for (let key in updatedObj) {
    if (key.includes('LinkedObjectID')) {
      updatedObj[`ObjectFieldID.${key}`] = updatedObj[key];
      delete updatedObj[key];
    }
    if (key === objects.SysFormFilterFieldEvents) {
      updatedObj[objects.SysFormFieldEvents] = updatedObj[key]?.map(fieldEvent => ({
        ...fieldEvent,
        EventTypeID: eventTypeForReportFields[fieldEvent.EventTypeID] || fieldEvent.EventTypeID
      }));
      delete updatedObj[key];
    }
  }

  return {
    ...updatedObj,
    FormID: formField[fields.FormID_Name],
    [fields.ObjectFieldID_FieldType]: formField[fields.FieldType],
    [fields.ObjectFieldID_Name]: formField[fields.ObjectFieldID_Name] || formField[fields.Name],
    [fields.ObjectFieldID_LinkedObjectID_FormForSelectByDefault]:
      formField[fields.ObjectFieldID_LinkedObjectID_FormForSelectByDefault_Name]
  };
};
/**
 *
 * @param {SysFormFilterFields} formField
 * @returns {SysFormFilterFields}
 */
const prepareSysFormFilterFields = formField => {
  checkFilterCustomConditions(formField);
  return {
    ...formField,
    FormID: formField[fields.FormID_Name]
  };
};
/**
 *
 * @param {SysFormButton} btn
 * @returns {SysFormButton}
 */
const prepareSysFormButton = btn => {
  return {
    ...btn,
    FormID: btn[fields.FormID_Name],
    ActionFormID: btn[fields.ActionFormID_Name]
  };
};
/**
 *
 * @param {SysFormGroups} group
 * @returns {SysFormGroups}
 */
const prepareSysFormGroups = group => {
  return {
    ...group,
    FormID: group[fields.FormID_Name]
  };
};
/**
 *
 * @param {Sys_FormDecorationElements} decor
 * @returns {Sys_FormDecorationElements}
 */
const prepareSysFormDecors = decor => {
  return {
    ...decor,
    FormID: decor[fields.FormID_Name]
  };
};
/**
 * @param {SysForm} sysForm
 * @returns {SysForm}
 */
export const prepareSysFrom = sysForm => {
  return {
    ...sysForm,
    ExID: sysForm.ID,
    ID: sysForm.Name,
    Sys_SubForms: (sysForm.Sys_SubForms || []).map(prepareSysSubFrom),
    Sys_FormFields: sysForm.Type === FormType.REPORT
      ? (sysForm.Sys_FormFilterFields || []).map(prepareSysFormReportFields)
      : (sysForm.Sys_FormFields || []).map(prepareSysFormFields),
    Sys_FormFilterFields: (sysForm.Sys_FormFilterFields || []).map(prepareSysFormFilterFields),
    Sys_FormButtons: (sysForm.Sys_FormButtons || []).map(prepareSysFormButton),
    Sys_FormGroups: (sysForm.Sys_FormGroups || []).map(prepareSysFormGroups),
    Sys_FormDecorationElements: (sysForm.Sys_FormDecorationElements || []).map(prepareSysFormDecors)
  };
};
/**
 * @param {SysSubsystems} subSystem
 * @returns {SysSubsystems}
 */
export const prepareSysSubsystem = subSystem => {
  return {
    ...subSystem,
    FormID: subSystem[fields.FormID_Name]
  };
};
