const errors = {
  //field & form errors & warns
  E1001: 'Field "{0}": Lookup initialization error. Display field "{1}" is not found',
  E1002: 'DefaultFilter parse error: "{0}". Error message: "{1}"',
  E1003: 'Accordion initialization error. Accordion must contain only Accordion Item. Check element with Name "{0}"',
  E1004: 'Accordion Item must be wrapped in to Accordion. Check Accordion Item with Name "{0}"',
  E1005: 'GoTo operation failed. FormFilterField "{0}" is not found on the form "{1}"',
  E1006: 'Form configuration error. Using forms with the same identification is not allowed. Duplicated form id: "{0}"',
  E1007: 'Form configuration error. Wrong field "{0}" value: "{1}"',
  E1008: 'Form configuration error. Object primary key is not defined',
  E1009: 'Decoration element error. Wrong field "{0}" must be a valid JSON string',
  E1010: 'Error getting field "{0}". Value getter can only be used on event valueChanged',
  E1011: 'Error setting field "{0}". Value setter can only be used on event valueChanged',
  E1012: 'Error setting field "{0}". This field is read only',
  E1013: 'Request: SysForms. {0}',
  E1014: 'Form configuration error. Type of the form {0} must be Edit or Multi Edit.',
  E1015:
    'Error in field "{0}". Setting datasource in enumerators is allowed only for string, number and boolean data types',
  E1016: 'In "{0}" button can\'t be empty DisplayCustomParams',
  E1017: 'MaxRowsCount in "{0}" button can\'t be empty undefined',
  E1018: 'Incorrect LocationOnGrid value in "{0}"',
  E1019: 'Error in "{0}" filter value: value must be {1}.',
  E1020: 'Error occurred while saving Mod operation. The key value is not specified.',
  E1021: 'Error occurred while parsing response. {0} operation',
  E1022: '"{0}" form configuration error. There are two or more Sys_FormFilterFields with the same object "{1}"',
  E1023:
    '"{0}" form configuration error. Type of FormField {1} must be the same as type of linked object key field, but got "{2}"',
  E1024: `Failed to parse the Ins request "{0}". Please check your response.`,
  E1025: 'It is not possible to start inline editing if cell editing is enabled on a form',
  E1026: `Invalid CustomParam value in field "{0}": EndValueField is equal "{1}"`,
  E1027: `URL has invalid filter values`,
  E1028: `Form configuration error. In form "{0}" amount of groups in a parent element with resizable one must be strongly equal to 2`,
  E1029: `Form configuration error. In form "{0}" a parent element with a resizable group must contain only groups or subForms but has: \n {1}`,
  E1030: `Filter configuration error. Unexpected filter operation "{0}"`,
  E1031: `SubSystem configuration error. Name property must not contain specific symbols but found {0}`,
  E1032: `Current editing mode is invalid. Form will use regular mode`,
  E1033: `Form configuration error. Form "{0}" has some configurations with empty values. Please, check the configurations`,
  E1034: `Event "{0}" is unexpected for such pagination type`,
  E1035: `Value type error. Incoming value "{1}" has type "Array", but the field expected value with type "{0}"`,
  E1036: 'Value error in {0} filter',
  E1037: 'Only one selected row allowed for this action',
  E1038: 'Filter condition is not provided in the destination field. Please check the filter condition settings',
  E1039: 'In the filter conditions {0} settings, that are not allowed for these settings were selected. Therefore, only permissible conditions are displayed',
  E1040: `Incorrect isMultiselect settings for "{0}" field or check ObjectField isArray property`,
  E1041: `Invalid form type specified in Action Form for the COPY button`,

  W1015: 'Detail SysForm with name: "{0}" not found',
  W1016: 'Group "{0}" is empty',
  W1017: 'No PagePredict value in Response, check server script settings in "{0}" object',
  W1018: 'Filter name: "{0}" - Specify filter condition list in CustomConditionList or set IsCustomConditionList = 0',
  E3001: 'DisplayType "{0}" is not available for {1}',

  //User Scripts
  W2001: 'Element type: "{0}". Element "{1}" is not found',
  W2003: 'No subsystem by this FormID',
  W2004: 'Required key: "{0}" for select is not found',
  E2005: 'delRows: "keys" must be type of array of string or number',
  E2006: 'editRows: "key" must be type of string or number',
  E2007: 'addRow: "newRow" must be type of object',
  W2008: 'addSubForm: Argument "groupID" is deprecated. Use "groupName" instead.',
  E2009: 'addSubForm: Group is not found "{0}".',
  E2010: 'Form "{0}" user event "{1}" error. Error text: \n "{2}"',
  E2011:
    'Your saved filter is applied with the filtering condition `Between`,' +
    ' but the Form has property `Period` as display type.',
  W2013: 'Not allowed to call the method "{0}" on init and show events',
  E2014: 'Not allowed to set filter to the enumerator. Field: {0}',
  E2015: 'Argument "name" is required in insertField()',
  E2016: 'Field with IsMultiSelect property must contain an array or null value. Field: {0}',
  E2017: 'Do not use the same name buttons. Button name {0} has already exist',
  E2018: 'Function "{0}" does not exist',
  E2019: 'Such functions as: {0} are initialized, but have not been declared in userscript',
  E2020:
    'The field "{0}" is not yet initialized.\n' +
    'Please, use only initialized fields. Or events that use already initialized fields',
  E2021: 'Event OnRowUpdated is not working with method navigateToNode(). Please, use another events for this method',
  E2022: 'SummaryValue must be a number',
  E2023: 'It is not allowed to fix a column that is in a group',
  E2024: 'Method "exportAsFile" is available only for fields with type "File" and set "IsFile" property',

  //DataWorker
  E3000: 'Unknown event: {0}'
};

export default errors;
