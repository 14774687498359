import {makeItemsSelector} from '../currentForms/selectors';
import {updateFilterItems} from '../../middlewares/userScript/utils';
import {updateCurrentForm} from '../currentForms/currentFormActions';
import {FilterStateObject} from './types';
import {getFormKey} from '../SecondaryMethods/getFormKey';
import {makeDocPanelFiltersSelector} from '../tables/selectors';
import {createFilterItemsHashMap, updateAndSaveToSessionStorage} from '../SecondaryMethods/filterUtils';
import {updateByParentItems} from '../../components/FilterPanel/Content/utils';
import {setDocFilter} from '../tables/actions';
import {updateFilters} from './actions';
import {RootState} from '../../store';
import {createAsyncThunk} from '@reduxjs/toolkit';

export const updateLayoutFilterItemsByFilterStore =
  (formID: string, filter: Record<string, any>) => (dispatch: any, getState: any) => {
    const items = makeItemsSelector()(getState(), formID);

    const newItems = updateFilterItems(items, (filterName: string) => {
      if (filterName in filter) {
        return {
          realOperation: filter[filterName].operation,
          hasIsBlank: filter[filterName].hasIsBlank,
          value: filter[filterName].value,
        };
      }
      return {value: undefined};
    });

    dispatch(
      updateCurrentForm({
        formID,
        options: {
          items: newItems,
        },
      })
    );
  };
/**
 * resetPrev - флаг указывающий нужно ли ресетнуть предыдущие значения
 */
export const setFilter = (formKey: string, filter: Record<string, any>, resetPrev = false) =>
  setFilters([{formKey, filter, resetPrev}]);

export const updateFilterPanel = createAsyncThunk<
  void,
  {formID: string; parentFilter: Record<string, FilterStateObject>},
  {state: RootState}
>('table/updateFilterPanel', async ({formID, parentFilter}, {dispatch, getState}) => {
  const parentItems = makeItemsSelector()(getState(), formID);
  const formKey = getFormKey(formID, 'filter');
  const panelItems = makeItemsSelector()(getState(), formKey);
  const docFilters = makeDocPanelFiltersSelector()(getState(), formKey);

  const panelItemsHashMap = createFilterItemsHashMap(panelItems, 'id');
  const parentItemsHashMap = createFilterItemsHashMap(parentItems, 'id');

  const changedVisibleItems = docFilters?.map(item => {
    if (!!panelItemsHashMap[item.id]?.isVisible === !!parentItemsHashMap[item.id]?.isVisible) return item;
    return {...item, isVisible: !!parentItemsHashMap[item.id]?.isVisible};
  });

  dispatch(setFilter(formKey, parentFilter));
  dispatch(
    updateCurrentForm({
      formID: formKey,
      options: {
        items: updateByParentItems(panelItems, parentItems, parentFilter),
      },
    })
  );

  dispatch(setDocFilter(formKey, changedVisibleItems ?? []));
});

interface SetFiltersActionArgs {
  formKey: string;
  filter: Record<string, any>;
  resetPrev?: boolean;
}

export const setFilters = (filters: SetFiltersActionArgs[]) => (dispatch: any, getState: () => RootState) => {
  dispatch(updateFilters(updateAndSaveToSessionStorage(getState().filters, filters)));
};
