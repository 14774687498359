import {D5FormWithToolbar} from './D5FormWithToolbar';
import {D5FormOptions} from "../inner-interfaces";

export default class D5TileList extends D5FormWithToolbar {
  protected _selectedItems: Record<string, any> = [];

  constructor(options: D5FormOptions) {
    super(options);
    this._selectedItems = options.currentForm.selectedRows;
  }
  get fields() {
    // нельзя обращаться к полям
    return [];
  }

  field() {
    // нельзя обращаться к полям
    return undefined;
  }
  get selectedTiles() {
    return  this._selectedItems.map((item: any) => {
      return {key: item.ID, data: item} || [];
    });
  }

  public async refresh() {
    const {events} = this.currentForm;
    if (typeof events.refresh === 'function') {
      await events.refresh({params: this.operationsParams});
    }

    return true;
  }
}
