import {forwardRef, useEffect, useState} from 'react';
import Resizable from 'devextreme-react/resizable';
import {useFormStateManager} from '../forms/FormStateManager';

const style = {
  width: '100%'
};

const DEFAULT_MAX_HEIGHT = 600;
const DEFAULT_MAX_HEIGHT_FOR_DIALOGS_PROMPT = 150;
export const DEFAULT_MIN_HEIGHT = 30;
const DEFAULT_MIN_WIDTH = 100;
const HEIGHT = '100%';
const WIDTH = '100%';

/**
 * @typedef {Object} HOCResizableOptions
 * @property {number} [defaultHeight]
 * @property {number | null} [maxHeight]
 * @property {number | null} [maxWidth]
 * @property {number | null} [minWidth]
 * @property {number | null} [minHeight]
 * @property {number | null} [height]
 * @property {number | null} [width]
 * @property {function | null} [updateDimensions]
 * @property {function | null} [setDynamicSize]
 * @property {string} [handles]
 * @property {string} [classNames]
 */

/**
 * @param Component
 * @param {HOCResizableOptions} [options]
 */
const HOCResizable = (Component, options) => {
  const MAX_HEIGHT = options?.maxHeight || DEFAULT_MAX_HEIGHT;
  const MAX_WIDTH = options?.maxWidth || window.innerWidth;
  const DEFAULT_HEIGHT = options?.defaultHeight;
  const MIN_WIDTH = options?.minWidth ?? DEFAULT_MIN_WIDTH;
  const MIN_HEIGHT = options?.minHeight ?? DEFAULT_MIN_HEIGHT;
  const HANDLES = options?.handles || 'bottom';
  const ELEMENT_ATTRIBUTE = {
    class: options?.classNames ?? ''
  };

  const HOCResizableComponent = forwardRef((props, ref) => {
    const fieldName = props.fieldName;
    const isDialogPrompt = props.isDialogPrompt;
    const isQRWidget = props.QRSight;
    const formStateManager = useFormStateManager();
    const storedDimensions = formStateManager?.getFieldDimensions(fieldName);
    const storedQRCam = formStateManager?.getQRCam();
    const storedWidth = storedDimensions?.width;
    const storedHeight = storedDimensions?.height;
    const storedWidthQRCam = storedQRCam?.aimBoxSize;
    const [changedSize, setChangedSize] = useState(storedWidth || storedHeight);
    const [isStarted, setIsStarted] = useState(false);
    const [componentWidth, setComponentWidth] = useState(WIDTH);
    const [componentHeight, setComponentHeight] = useState(HEIGHT);
    const updateDimensionsState = (width, height) => {
      width && setComponentWidth(width);
      height && setComponentHeight(height);
    };
    const propsWithResizable = {
      ...props,
      isFormBodyScrollable: true
    };

    useEffect(() => {
      if (options?.width && options?.height) {
        updateDimensionsState(options?.width, options?.height);
      }
    }, [options?.height, options?.width]);

    const isVerticalHandle = HANDLES === 'bottom' || HANDLES === 'top';

    return (
      <Resizable
        style={style}
        elementAttr={ELEMENT_ATTRIBUTE}
        keepAspectRatio={false}
        onInitialized={({element}) => {
          const calculatedWidth = isQRWidget ? storedWidthQRCam : storedWidth;
          if (storedHeight) {
            return updateDimensionsState(calculatedWidth, storedHeight);
          }

          /* 8 - это марджины и падинги в поле*/
          const textAreaScrollHeight = element.querySelector('textarea')?.scrollHeight + 8;

          const calculatedHeight =
            DEFAULT_HEIGHT || // For HtmlEditor
            (textAreaScrollHeight > MAX_HEIGHT
              ? isDialogPrompt
                ? DEFAULT_MAX_HEIGHT_FOR_DIALOGS_PROMPT // for DialogsPrompts
                : MAX_HEIGHT
              : textAreaScrollHeight); // For TextAreaController

          updateDimensionsState(calculatedWidth, calculatedHeight);
        }}
        onResizeStart={() => {
          setIsStarted(true);
        }}
        onResize={e => {
          const {width, height} = e;
          updateDimensionsState(width, height);
        }}
        onResizeEnd={({width, height}) => {
          updateDimensionsState(width, height);
          formStateManager.saveFieldDimensions({fieldName, width, height});
          if (!changedSize) {
            setChangedSize(true);
          }
          if (typeof options?.updateDimensions === 'function') {
            options?.updateDimensions({width, height});
          }
          setIsStarted(false);
        }}
        maxHeight={MAX_HEIGHT}
        maxWidth={MAX_WIDTH}
        minWidth={MIN_WIDTH}
        minHeight={MIN_HEIGHT}
        //якщо ресайзити можна тільки по вертикалі, то ширину фіксувати не потрібно
        width={isVerticalHandle ? WIDTH : componentWidth}
        height={componentHeight}
        handles={HANDLES}
      >
        <Component
          storedHeight={storedHeight}
          changedSize={changedSize}
          setComponentHeight={setComponentHeight}
          isStarted={isStarted}
          {...propsWithResizable}
          ref={ref} />
      </Resizable>
    );
  });
  return HOCResizableComponent;
};

export default HOCResizable;
