import {ElementsUserSettingsInitialState, ElementUserSettingsObj} from './types';
import {createReducer} from '@reduxjs/toolkit';
import {setElementsUserSettings} from './actions';

export const elementsUserSettingsReducer = createReducer({} as ElementsUserSettingsInitialState, builder => {
  builder.addCase(setElementsUserSettings, (state, {payload}) => {
    payload.forEach(({formKey, elementsUserSettings}: ElementUserSettingsObj) => {
      state[formKey] = elementsUserSettings;
    });
  });
});
