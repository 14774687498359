import {D5BaseElement} from 'middlewares/userScript/elems/D5BaseElement';
import {ItemsState} from 'middlewares/userScript/storeStates/itemsState';
import {IDecorOptions} from 'services/SecondaryMethods/formItems/itemInterfaces';
import LayoutType from '../../../utilsOld/systemObjects/LayoutType';

declare interface D5FormDecorationElementOptions {
  name: string;
  formItems: ItemsState;
}

export class D5FormDecorationElement extends D5BaseElement<IDecorOptions> {
  constructor(options: D5FormDecorationElementOptions) {
    super(options.formItems);
    this._name = options.name;
    this.index = this.collection.findIndex(item => item.name === this._name && LayoutType.isDecoration(item.itemType));

    if (this.index === -1) {
      throw new Error(`Decoration element ${this._name} is not found`);
    }

    this._id = this.getCommonOption('id');
  }

  get title() {
    return this.getOption('title');
  }

  set title(title: string) {
    this.setOption('title', title);
  }

  get displayType() {
    return this.getOption('displayType');
  }

  set displayType(displayType: string) {
    this.setOption('displayType', displayType);
  }

  get displayCustomParam() {
    return this.getOption('displayCustomParam');
  }

  set displayCustomParam(displayCustomParam: string) {
    this.setOption('displayCustomParam', displayCustomParam);
  }

  get lineCount() {
    return this.getOption('lineCount');
  }

  set lineCount(lineCount: number) {
    this.setOption('lineCount', lineCount);
  }
}
