import {
  fields,
  FILTER_URL,
  KANBAN_URL,
  LIST_PART,
  NAV_EDIT_PART,
  REPORT_VIEWER_STR,
  SUBSYSTEM_STR,
  system
} from 'services/objects';
import {FormType, ICreateEditUrl, NEW_WINDOW_OPENING_MODE} from 'services/interfaces/global-interfaces';
import {SysForm} from 'services/interfaces/sysObjects';
import {formatToAPI, IFormatFilterToAPI} from 'services/SecondaryMethods/filterUtils';
import {addServicePrefix, isEmptyObject} from '../helpers';
import {getSubsystemFromState} from 'services/requests/operation';
import {createFilterQuery, encodeUrl} from 'utilsOld/filterQuery';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import Messages from 'services/lang/en';
import {getKeyForAppSettings, getNewWindowOpenedMode} from 'services/SecondaryMethods/userSettings';
import {isObject} from 'services/SecondaryMethods/typeUtils';

/**
 * возвращает url для открытия формы редактирования
 */
export const createEditUrl = (props: ICreateEditUrl): string => {
  let {parentRoute = '/', mode, formName, id} = props;
  let editPart: string | number = '';
  const {
    FORM_EDIT_CREATE_MODE: {MULTI_EDIT, EDIT, ADD}
  } = system;

  if (!mode) {
    throw new Error("Editing Mode can't be empty");
  }
  if (mode === ADD && id) {
    throw new Error("Add Mode can't be with ID");
  }
  if (mode === MULTI_EDIT || (mode === EDIT && id)) {
    editPart = id || '';
  }

  if (parentRoute[parentRoute.length - 1] !== '/') parentRoute += '/';
  return `${parentRoute}${mode}/${formName.toLowerCase()}/${editPart}`;
};

export const createSectionRoute = (subsystemName: string) => {
  return `/${SUBSYSTEM_STR}/${subsystemName.toLowerCase()}`;
};

export const createNavEditRoute = (formName: string, parentRoute = '') => {
  return parentRoute + `/${NAV_EDIT_PART}/${formName}`;
};

export const createListRoute = (formName: string, parentRoute = '', type: FormType) => {
  return parentRoute + `/${LIST_PART}/${formName}/${type}`;
};

/**
 * возвращает url для открытия формы
 * @param location {Object} обьект window location
 * @param name {string} имя сабсистемы
 * @param servicePrefix {string} имя сабсистемы
 */
export const createSubSystemUrl = (location: Record<string, any>, name: string, servicePrefix: string): string => {
  const {origin, pathname} = location;
  if (!name) {
    throw new Error("Name can't be empty");
  }
  return `${origin}${pathname}#/${servicePrefix ? servicePrefix + '/' : ''}${SUBSYSTEM_STR}/${name}`;
};

/**
 * создает родительский путь т.е. из какого каталога строиться путь
 * @param hash document.location.hash
 * @example /section/subsysname
 */
export const createParentRoute = (hash: string): string => {
  const {
    SERVICE_PREFIX: {ADMIN, CONFIGURATOR, FORM},
    FORM_EDIT_CREATE_MODE: {ADD}
  } = system;

  /**
   * @example /admin/section/countries
   * @example /configurator/section/countries
   * @example /section/countries
   */
  const regExp = new RegExp(
    `\\/((${ADMIN}|${CONFIGURATOR})\\/)?((${SUBSYSTEM_STR}/${ADD}|${SUBSYSTEM_STR}|${KANBAN_URL}|${FORM})\\/)\\w+`
  );
  const parentRoute = hash.match(regExp);
  return parentRoute !== null ? parentRoute[0] : '/';
};

export const servicePrefixFromRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {ADMIN, CONFIGURATOR, FORM}
  } = system;

  const cleanRoute = routeFromHash(route);
  const prefix = cleanRoute.split('/')[1] ?? '';
  return prefix === ADMIN || prefix === CONFIGURATOR || prefix === FORM ? prefix : '';
};

export const isAdminRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {ADMIN}
  } = system;
  return servicePrefixFromRoute(route) === ADMIN;
};

export const isConfigRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {CONFIGURATOR}
  } = system;
  return servicePrefixFromRoute(route) === CONFIGURATOR;
};
export const isSingleFormRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {FORM}
  } = system;
  return servicePrefixFromRoute(route) === FORM;
};

export const redirectToFilterLink = (link: string) => {
  return link.replace(SUBSYSTEM_STR, FILTER_URL);
};

export const createStoreFilterUtl = (
  sysForm: SysForm,
  storeFilters: IFormatFilterToAPI['storeFilters'],
  servicePrefix = ''
) => {
  const apiFilter = formatToAPI({storeFilters, sysForm});
  if (isEmptyObject(apiFilter)) {
    showErrorNotification(Messages.Errors.FilterNotSet);
    throw new Error('Filter is not set');
  }

  return createApiFilterUtl(sysForm.Name, apiFilter, servicePrefix);
};

export const createApiFilterUtl = async (formName: string, apiFilter: Record<string, any>, servicePrefix = '') => {
  const {
    location: {origin, pathname}
  } = window;

  if (isEmptyObject(apiFilter)) {
    return null;
  }

  const subsystem = await getSubsystemFromState({formName});
  if (isEmptyObject(subsystem)) {
    throw new Error('Subsystems are empty!');
  }
  const routeFormName = subsystem[fields.Name].toLowerCase();
  const prefixWithSlash = servicePrefix ? addServicePrefix(servicePrefix) + '/' : '/';
  let url = `${prefixWithSlash}${FILTER_URL}/${routeFormName}`;

  return `${origin}${pathname}#${url}?filter=${encodeUrl(createFilterQuery(apiFilter))}`;
};
export const checkNewWindowOpenedMode = () => {
  const newWindowMode = getNewWindowOpenedMode();
  if (newWindowMode) {
    return newWindowMode;
  } else {
    const key = getKeyForAppSettings();
    const appSetts = JSON.parse(localStorage.getItem(key) || '{}');
    const appSettings = {
      ...appSetts,
      new_window_opened_mode: NEW_WINDOW_OPENING_MODE.LAST_OPENED_FORM
    };
    localStorage.setItem(key, JSON.stringify(appSettings));
  }
};
export const createReportListLink = (reportName: string, selectedKeys: any[], queryString: string = '') => {
  const {hostname} = window.location;
  if (queryString && !queryString.startsWith('&')) throw new Error('queryString must start with &');

  const keyValueToArray = selectedKeys.length > 1 ? JSON.stringify(selectedKeys) : selectedKeys[0];
  return `${REPORT_VIEWER_STR}/${reportName}?host=${hostname}&keyvalue=${keyValueToArray}${queryString}`;
};

/**
 * Парсит хеш с window.location и убирает # чтобы получить роут
 */
export function routeFromHash(hash: string) {
  let result = hash;
  if (hash.indexOf('#') === 0) {
    result = hash.slice(1);
  }
  return result.replace(/\/$/, '') || '/';
}

export const getSubsystemName = () => {
  const {
    location: {hash}
  } = window;
  const pathname = routeFromHash(hash);
  const locationArr = pathname.split('/');
  const sectionIndex = locationArr.findIndex(urlPart => urlPart === SUBSYSTEM_STR || urlPart === 'form');
  return locationArr[sectionIndex + 1];
};

export function reportQueryParamsToString(params: Record<string, any> | undefined): string {
  if (!isObject(params)) return '';

  const queryParams = Object.keys(params!)
    .map(key => `${key}=${params![key]}`)
    .join('&');

  return queryParams && `&${queryParams}`;
}
