import {fields} from 'services/objects';
import {gridConstants} from 'components/Layout/utils/variables';
import {
  ButtonCreators,
  ELayoutType,
  IDecorOptions,
  ILayoutItem
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {SysFormDecorationElement} from 'services/interfaces/sysObjects';
import {DECORATION_DISPLAY_TYPE_TEXT} from '../../interfaces/global-interfaces';
import {getTextAlignmentStr, getTranslatedTitle} from './utils';

const {
  MIN_WIDTH: {DECOR_MIN_WIDTH},
  MIN_HEIGHT: {DECOR_MIN_HEIGHT}
} = gridConstants;

export const getDecorItems = (
  decorationElements: SysFormDecorationElement[],
  isFormBodyScrollable: boolean
): ILayoutItem<IDecorOptions>[] => {
  return decorationElements.map((item): ILayoutItem<IDecorOptions> => {
    const decorOptions: IDecorOptions = {
      displayCustomParam: item[fields.DisplayCustomParam]!,
      displayType: item[fields.DisplayType],
      formID: item[fields.FormID],
      lineCount: item[fields.LineCount]!,
      title: getTranslatedTitle(item[fields.Translations]) || item[fields.Title],
      width: item[fields.Width]!,
      hasScroll: isFormBodyScrollable,
      titleFontStyle: item[fields.TitleFontStyle],
      textAlignment: getTextAlignmentStr(item[fields.TextAlignment]),
      textFontStyle: item[fields.TextFontStyle]
    };

    return {
      creator: ButtonCreators.FORM,
      groupID: item[fields.GroupID],
      id: item[fields.ID],
      isVisible: Boolean(item[fields.IsVisible]),
      itemType: ELayoutType.DECORATION,
      displayType: item[fields.DisplayType],
      name: item[fields.Name],
      cols: item[fields.Cols],
      rows: item[fields.Rows],
      minWidth: DECOR_MIN_WIDTH,
      minHeight: DECOR_MIN_HEIGHT,
      className: `item-margin d-layout-item decor-${
        DECORATION_DISPLAY_TYPE_TEXT[decorOptions.displayType]
      } label-text-color -decor`,
      idAttr: `decoration-${item[fields.Name]}`,
      options: decorOptions,
      order: item[fields.Order],
      parentID: item[fields.GroupID]!
    };
  });
};
