import {
  ELayoutType,
  IButtonOptions,
  IGroupOptions,
  ILayoutItem,
  LayoutButton,
  LayoutButtons,
  LayoutGroup,
  LayoutGroups
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {BUTTON_POSITION} from 'services/interfaces/global-interfaces';
import {textIconOptionsByTitleType, updateLayoutItemOptions} from 'services/SecondaryMethods/formItems/utils';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import {ButtonSettingDSRow, DataSource, DataSourceCol, DataSourceRowCol, GridColumn} from '../column-interfaces';
import {showWarningNotification} from 'services/SecondaryMethods/snackbars';
import {groupBy} from 'utilsOld/utility';

export enum ShowTitleButtons {
  On = 1,
  Off = 0,
  Default = -1,
}

export interface ButtonSettingsProps {
  buttons: LayoutButtons;
  groups: LayoutGroups;
  defButtons: LayoutButtons;
  defGroups: LayoutGroups;
  onUpdateItems: (params: {buttons?: LayoutButtons; groups?: LayoutGroups}) => void;
}

type SingleItemOption = keyof IButtonOptions;

export interface IUpdateItemOption {
  defButtons: LayoutButtons;
  defGroups: LayoutGroups;
  option: SingleItemOption;
}

export interface IUpdateDefaultItemOptions extends IUpdateItemOption {
  items: ILayoutItem<any>[];
}

export interface ITbButtonsPosition {
  id: number;
  position: BUTTON_POSITION;
  itemType: Exclude<ELayoutType, 'button | group'>;
  title: string;
}

interface IShouldUpdateParentButtonArgs {
  items: (LayoutButton | LayoutGroup)[],
  updatedItem?: LayoutButton | LayoutGroup;
  parentItem?: LayoutGroup,
  nextPosition: BUTTON_POSITION;
}

interface IUpdateButtons {
  buttons: LayoutButtons;
  groups: LayoutGroups;
  visibleInToolbar: boolean;
  visibleInContextMenu: boolean;
  ID: number | string;
  itemType: LayoutButton['itemType'] | LayoutGroup['itemType'];
}

interface IUpdateItemsResult {
  newButtons: LayoutButtons;
  newGroups: LayoutGroups;
}

interface IGetButtonGroups {
  lsButtons?: ITbButtonsPosition[];
  buttonGroups: LayoutGroups;
}

interface IGetButtonsSettings {
  lsButtons?: ITbButtonsPosition[];
  storeButtons?: LayoutButtons;
}

interface IVisibility {
  visibleInContextMenu: boolean;
  visibleInToolbar: boolean;
}

const {CONTEXT_MENU, TOOLBAR, NONE, ALL} = BUTTON_POSITION;

const updateToolbarItemSettings = ({
  items,
  lsSettings,
}: {
  items: LayoutButtons;
  lsSettings: ITbButtonsPosition[];
}): LayoutButtons => {
  return updateLayoutItemOptions(
    items,
    button => {
      return button.options.isCustomizable;
    },
    (button: LayoutButton) => {
      const currentButtonSettings = lsSettings.find(lsButton => lsButton.id === button.id);
      if (!currentButtonSettings) return {};
      return {
        position: currentButtonSettings.position,
        // Якщо у нас в LS налаштуваннями не передабачено title
        // то може використовуватись defaultTitle , а це може ламати логіку відображення тайтлів
        title: currentButtonSettings.title ?? ''
      };
    }
  );
};

const applyStoredSettingsToToolbarItems = <OptionType>({
  lsButtons,
  items,
  type,
}: {
  lsButtons?: ITbButtonsPosition[];
  items: ILayoutItem<OptionType>[];
  type: ELayoutType.BUTTON | ELayoutType.GROUP;
}): ILayoutItem<OptionType>[] => {
  if (!items?.length || !lsButtons) return items;

  const lsSettings = lsButtons.filter(button => button.itemType === type);
  // @ts-ignore
  return updateToolbarItemSettings({items, lsSettings});
};

export const applyStoredSettingsToToolbarGroups = ({lsButtons, buttonGroups = []}: IGetButtonGroups) => {
  return applyStoredSettingsToToolbarItems({lsButtons, items: buttonGroups, type: ELayoutType.GROUP});
};

export const applyStoredSettingsToToolbarButtons = ({lsButtons, storeButtons = []}: IGetButtonsSettings) => {
  return applyStoredSettingsToToolbarItems({lsButtons, items: storeButtons, type: ELayoutType.BUTTON});
};

const shouldUpdateParentButton = ({
  items,
  updatedItem,
  parentItem,
  nextPosition
}: IShouldUpdateParentButtonArgs) => {
  if ((updatedItem?.groupID || updatedItem?.parentID) && parentItem) {
    const itemsWithSameGroup = items.filter(item => (item.groupID || item.parentID) === parentItem.id);
    const excludeUpdatedItems = itemsWithSameGroup.filter(item => item.id !== updatedItem.id);
    const allPosEqual = excludeUpdatedItems.every(item => item.options.position === nextPosition);

    //Если все позиции у дочерних кнопок одинаковые обновить позиции парент кнопки
    if (allPosEqual && parentItem.options.position !== nextPosition) {
      return true;
    }
  }

  return !!nextPosition && parentItem?.options.position !== BUTTON_POSITION.ALL;
};

export const getNewPosition = (visibleInContextMenu: boolean, visibleInToolbar: boolean) => {
  switch (true) {
    case visibleInContextMenu && visibleInToolbar:
      return ALL;
    case visibleInContextMenu:
      return CONTEXT_MENU;
    case visibleInToolbar:
      return TOOLBAR;
    default:
      return NONE;
  }
};

const getCurrVisibility = (position: BUTTON_POSITION | undefined): IVisibility => {
  switch (position) {
    case ALL:
      return {
        visibleInContextMenu: true,
        visibleInToolbar: true
      };
    case CONTEXT_MENU:
      return {
        visibleInContextMenu: true,
        visibleInToolbar: false
      };
    case TOOLBAR:
      return {
        visibleInContextMenu: false,
        visibleInToolbar: true
      };
    default:
      return {
        visibleInContextMenu: false,
        visibleInToolbar: false
      };
  }
};

const getChangedPosition = (currVisibility: IVisibility, nextVisibility: IVisibility, groupVisibility: IVisibility) => {
  const hasChangedInMenu =
    currVisibility.visibleInContextMenu !== nextVisibility.visibleInContextMenu &&
    groupVisibility.visibleInContextMenu !== nextVisibility.visibleInContextMenu;
  const hasChangedInToolbar =
    currVisibility.visibleInToolbar !== nextVisibility.visibleInToolbar &&
    groupVisibility.visibleInToolbar !== nextVisibility.visibleInToolbar;

  if (hasChangedInMenu) {
    return getNewPosition(nextVisibility.visibleInContextMenu, currVisibility.visibleInToolbar);
  }
  if (hasChangedInToolbar) {
    return getNewPosition(currVisibility.visibleInContextMenu, nextVisibility.visibleInToolbar);
  }

  return getNewPosition(currVisibility.visibleInContextMenu, currVisibility.visibleInToolbar);
};

export const updateSingleItem = <T extends {options: IButtonOptions | IGroupOptions}>(
  item: T,
  position: BUTTON_POSITION
): T => {
  return {
    ...item,
    options: {
      ...item.options,
      position
    }
  };
};

export const handleButtonUpdate = ({
   buttons,
   groups,
   visibleInContextMenu,
   visibleInToolbar,
   ID
}:IUpdateButtons): IUpdateItemsResult => {
  const updatedButton = buttons.find(btn => btn.id === ID);
  const updatedGroup = groups.find(group => group.id === ID);

  const parentGroupOnDependItem = groups.find(group => group.id === (updatedGroup?.parentID || updatedButton?.groupID));

  const nextPosition = getNewPosition(visibleInContextMenu, visibleInToolbar);

  const shouldUpdateParent = shouldUpdateParentButton({
    items: [...groups, ...buttons],
    updatedItem: updatedButton || updatedGroup,
    parentItem: parentGroupOnDependItem,
    nextPosition
  });

  const nestedItems = updatedGroup ? findNestedItems(buttons, groups, updatedGroup?.id) : [];

  const newButtons = buttons.map((button) => {
    const isNestedButton = updatedGroup ? nestedItems.some((item: LayoutButton | LayoutGroup) => item.id === button.id) : false;
    if (updatedGroup && updatedGroup.id === button.groupID) {
      return updateSingleItem<LayoutButton>(button, getChangedPosition(
        getCurrVisibility(button?.options.position),
        {visibleInContextMenu, visibleInToolbar},
        getCurrVisibility(updatedGroup?.options.position)
      ));
    }

    if ((updatedButton && updatedButton.id === button.id) || isNestedButton) {
      return updateSingleItem<LayoutButton>(button, nextPosition);
    }

    return button;
  });

  const newGroups = groups.map((group) => {
    const isNestedGroup = updatedGroup ? nestedItems.some((item: LayoutButton | LayoutGroup) => item.id === group.id) : false;
    if (shouldUpdateParent && (updatedButton || updatedGroup) && parentGroupOnDependItem?.id === group.id) {
      return updateSingleItem<LayoutGroup>(group, nextPosition);
    }
    if (shouldUpdateParent && updatedGroup && parentGroupOnDependItem?.id === group.id) {
      return updateSingleItem<LayoutGroup>(group, getChangedPosition(
        getCurrVisibility(group?.options.position),
        {visibleInContextMenu, visibleInToolbar},
        getCurrVisibility(updatedGroup?.options.position)
      ));
    }
    if (updatedGroup && updatedGroup?.id === group.parentID) {
      return updateSingleItem<LayoutGroup>(group, nextPosition);
    }
    if ((updatedGroup && updatedGroup.id === group.id) || isNestedGroup) {
      return updateSingleItem<LayoutGroup>(group, nextPosition);
    }
    return group;
  });

  return {newButtons, newGroups};
};

export const transformLayoutButtonsToLocalStorage = (buttons: (LayoutButton | LayoutGroup)[]): ITbButtonsPosition[] => {
  return buttons.map(item => {
    return {
      id: item.id,
      position: item.options.position!,
      itemType: item.itemType,
      title: item.options.title
    };
  });
};

export const getDefaultItemOption = ({
  item,
  option,
  defButtons,
  defGroups,
}: IUpdateItemOption & {item: LayoutButton & LayoutGroup}) => {
  const currentItemDefaultOption = [...defButtons, ...defGroups].find(
    defItem => defItem.id === item.id
  ) as LayoutButton & LayoutGroup;

  const defaultParentItem = [...defButtons, ...defGroups].find(
    defButton => defButton.id === item.parentID
  ) as LayoutButton & LayoutGroup;

  if (isDefined(currentItemDefaultOption?.options[option])) {
    return currentItemDefaultOption.options[option];
  } else if (defaultParentItem) {
    return defaultParentItem.options[option];
  }
  return item.options[option];
};

const updateDefaultItemOptions = ({items, defButtons, defGroups, option}: IUpdateDefaultItemOptions) => {
  return updateLayoutItemOptions(
    items,
    b => !!b,
    button => ({
      [option]: getDefaultItemOption({
        item: button,
        option,
        defButtons,
        defGroups
      })
    })
  );
};

export const updateButtonOptionByGroup = (buttons: LayoutButtons, groups: LayoutGroups) => {
  return buttons.map(btn => {
    const group = groups.find(gr => gr.id === btn.groupID);

    if (!group || !!group?.options.position) return btn;

    return {
      ...btn,
      options: {
        ...btn.options,
        position: BUTTON_POSITION.NONE
      }
    };
  });
};

export const setDefaultItemOption = ({
  buttons,
  groups,
  defButtons,
  defGroups,
  onUpdateItems,
  option,
}: ButtonSettingsProps & {option: SingleItemOption}) => {
  const headerFilterButtons = buttons.filter(b => b.options.position === BUTTON_POSITION.HEADER_CONTEXT_MENU);
  const withoutHeaderFilterButtons = buttons.filter(b => b.options.position !== BUTTON_POSITION.HEADER_CONTEXT_MENU);

  const buttonsWithInitialOption = updateDefaultItemOptions({
    items: withoutHeaderFilterButtons,
    defButtons,
    defGroups,
    option
  });

  const groupsWithInitialOption = updateDefaultItemOptions({
    items: groups,
    defButtons,
    defGroups,
    option
  });

  const updButtons = updateButtonOptionByGroup(
    [...buttonsWithInitialOption, ...headerFilterButtons],
    groupsWithInitialOption
  );

  onUpdateItems({
    buttons: [...updButtons],
    groups: [...groupsWithInitialOption.map(gr => ({...gr, isVisible: !!gr.options.position}))]
  });
};

export const updateItemTitleType = <T extends ILayoutItem<any>[]>(items: T, value: ShowTitleButtons) => {
  return updateLayoutItemOptions(
    items,
    item => !!item,
    item => {
      const {icon: expectedIcon, title: expectedTitle} = textIconOptionsByTitleType({
        icon: item.options.defaultIcon,
        title: item.options.defaultTitle,
        titleType: item.options.titleType
      });

      switch (value) {
        case ShowTitleButtons.On: {
          return {
            title: item.options.defaultTitle,
            icon: expectedIcon
          };
        }
        case ShowTitleButtons.Off:
          return {
            title: '',
            icon: item.options.icon || item.options.defaultIcon
          };
        default:
          return {
            icon: expectedIcon,
            title: expectedTitle
          };
      }
    }
  ) as T;
};

export const getOnValueChangedFunc = ({
  buttons,
  groups,
  onUpdateItems,
}: Pick<ButtonSettingsProps, 'buttons' | 'groups' | 'onUpdateItems'>) => {
  const setNewTitleType = (value: ShowTitleButtons) => {
    const newGroups = updateItemTitleType<LayoutGroups>(groups, value);
    const newButtons = updateItemTitleType<LayoutButtons>(buttons, value);

    onUpdateItems({
      buttons: newButtons,
      groups: newGroups
    });
  };

  return ({value}: {value: ShowTitleButtons}) => {
    setNewTitleType(value);
  };
};

/**
 * @param newData
 * @param value
 * @param currentRowData
 * @param visibleItemsLeft - false - значить отменить действие
 * @param instance
 * @param name
 * @param message
 */
export const disableLastVisibleItem = (
  newData: {[string: string]: boolean},
  value: boolean,
  currentRowData: {ID: number},
  visibleItemsLeft: boolean,
  instance:
    | {
    repaintRows: (_: any[]) => void;
    getRowIndexByKey: (id: number) => number;
  }
    | undefined,
  name: string,
  message?: string
) => {
  const {ID: id} = currentRowData;
  if (!visibleItemsLeft && instance) {
    message && showWarningNotification(message);

    newData[name] = true;
    instance.repaintRows([instance.getRowIndexByKey(id)]);
  } else {
    newData[name] = value;
  }
};

export const checkLastVisibleButton = (
  buttons: ButtonSettingDSRow[],
  id: number,
  groupId: number,
  name: 'visibleInToolbar' | 'visibleInContextMenu'
) => {
  const groups = buttons.filter(item=>item.itemType==='group');
  const updatedGroup = groups.find(group => group.ID === id);
  const nestedItems = updatedGroup ? findDeepNestedDSRowItems(buttons, groups, id) : [];

  const a = buttons.map(button => {
    const isNestedBtn = nestedItems.find(item=>item.ID === button.ID);
    if (button.groupID === id || isNestedBtn) {
      return { ...button, [name]: false };
    }
    return button.ID === id || button.ID === groupId ? { ...button, [name]: !button[name] } : button;
  });

  return a.some(button => button[name]);
};
/**
 * @param columns
 * @param id
 * @param name
 * @returns false if no last visible columns left
 */
export const checkVisibleColumnLeft = (
  columns: DataSourceCol,
  id: number,
  name: 'visible' | 'isVisibleOnEditDockPanel'
): boolean => {
  let groupsMap: Record<string, DataSourceRowCol[]> = groupBy(columns, 'groupID');
  let columnsMap: Record<string, DataSourceRowCol[]> = groupBy(columns, 'ID');

  let column = columnsMap[id]?.[0];
  let isRoot = column?.groupID === -1;

  //если рутовая колонка, проверяем количество рутовых колонок
  if (isRoot) return columns.filter(col => col.groupID === -1 && col[name]).length > 1;

  //если есть родитель - проверяем количество соседних видимых
  let parentGroup = column?.groupID && column?.groupID !== -1 ? columnsMap[column?.groupID]?.[0] : undefined;
  return !!parentGroup?.[name] && groupsMap[parentGroup.ID].filter(col => col[name]).length > 1;
};

export const checkLastVisibleBoard = (boards: DataSource, id: number) => {
  const beforeChangedArray = boards.map(board => (board.ID === id ? {...board, visible: !board.visible} : board));
  return beforeChangedArray.some(board => board.visible);
};

export const headerCellTemplate = (header: HTMLElement, info: {column: {caption: string}}) => {
  const div = document.createElement('div');
  div.innerHTML = `<span class='header-item'>${info.column.caption}</span>`;
  header.appendChild(div);
};

export const getMaxSortIndexFromColumns = (columns: GridColumn[]) => {
  const maxSortIndex = columns.reduce((max, column) => {
    const sortIndex = column.sortIndex;

    if (sortIndex !== undefined && column.sortOrder && sortIndex > max) {
      return sortIndex;
    }

    return max;
  }, -1);
  return maxSortIndex;
};

export const toggleSortOrder = (columnItem: GridColumn) => {
  if (!columnItem.sortOrder) {
    return 'asc';
  } else if (columnItem.sortOrder === 'asc') {
    return 'desc';
  } else if (columnItem.sortOrder === 'desc') {
    return undefined;
  }
};
//Функція шукає вкладеність в звичайних лейаут кнопках зі стору
const findNestedItems = (buttons: LayoutButtons, groups: LayoutGroups, groupId: number | string) => {
  const nestedItems: (LayoutButton | LayoutGroup)[] = [];
  for (const group of groups) {
    if (group.parentID === groupId) {
      nestedItems.push(group);
      nestedItems.push(...findNestedItems(buttons, groups, group.id));
    }
  }
  for (const button of buttons) {
    if (button.groupID === groupId) {
      nestedItems.push(button);
    }
  }

  return nestedItems;
};

export const calculateLevelButton = (buttonId: number, buttons: LayoutButtons): number => {
  const button = buttons.find(button => button.id === buttonId);
  if (!button?.parentID) {
    return 0;
  }
  return 1 + calculateLevelButton(button.parentID, buttons);
};

// Функція шукає всі вкладені елементи в групі кнопок (власні вкладені кнопки,групи, кнопки вкладені в групи) в DS
// який створюється спеціально для Customization Panel за допомогою createButtonDataSource
export const findDeepNestedDSRowItems = (buttons: ButtonSettingDSRow[], groups: ButtonSettingDSRow[] , groupId: number | string) => {
  const nestedItems: (ButtonSettingDSRow)[] = [];

  const groupItems = buttons.filter(item => item.groupID === groupId);
  nestedItems.push(...groupItems);

  const nestedGroups = groups.filter(item => item.groupID === groupId && item.itemType === 'group');
  for (const group of nestedGroups) {
    nestedItems.push(group);
    const nestedButtons = findDeepNestedDSRowItems(buttons, groups, group.ID);
    nestedItems.push(...nestedButtons);
  }

  return nestedItems;
};
