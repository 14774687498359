import {Messages} from 'services/lang/messages';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import {wapiUrl} from '../baseUrl';
import {formatMessage} from 'utilsOld/formatMessage';
import {postWithPromise} from './baseRequests';

const createUrl = function (method, object) {
  let methods = {
    create: 'Ins',
    delete: 'Del',
    pass: 'Pass',
    read: 'List',
    update: 'Mod'
  };
  return [wapiUrl, object, methods[method]].join('/');
};

export async function sendRequest(instance, action, {data, success, error} = {}, headers) {
  const url = createUrl(action, instance.object);

  const response = await postWithPromise({headers, data, url});
  instance.response = response.plainResponse;

  if (!response.message) {
    return success(instance);
  }

  const { message, code, responseID } = response;

  showErrorNotification({
    msg: formatMessage(Messages.Errors.ErrorWhileRequest, [message]),
    responseId: responseID,
    codeError: code
  });

  if (typeof error === 'function') {
    error(instance, { message, responseId: responseID, codeError: code });
  }
}
