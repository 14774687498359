import {system} from 'services/objects';
import store from 'store';
import {modal} from 'services/modal/actions';
import {FormType} from 'services/interfaces/global-interfaces';

const {EDIT} = FormType;

const {
  LINKS: {
    OPEN: {MODAL: MODAL_MODE, FULL_SCREEN: FULL_SCREEN_MODE, IN_NEW_TAB, IN_NEW_WINDOW}
  }
} = system;

export function formStrategy(openMode, createLinkFunction) {
  switch (openMode) {
    case FULL_SCREEN_MODE:
      return ({value, formName, key, setMaxHeight, maxLineCount}) =>
        createLinkFunction({
          value,
          formName,
          key,
          target: '_self',
          setMaxHeight,
          maxLineCount,
          dataOpenMode: openMode
        });
    case IN_NEW_TAB:
      return ({value, formName, key, setMaxHeight, maxLineCount}) =>
        createLinkFunction({
          value,
          formName,
          key,
          setMaxHeight,
          maxLineCount,
          dataOpenMode: openMode
        });
    case IN_NEW_WINDOW:
      return ({value, formName, key, setMaxHeight, maxLineCount}) =>
        createLinkFunction({
          value,
          formName,
          key,
          features: 'toolbar=0,location=0,menubar=0',
          setMaxHeight,
          maxLineCount,
          dataOpenMode: openMode
        });
    case MODAL_MODE:
    default:
      return ({value, formName, key, setMaxHeight, maxLineCount}) =>
        createLinkFunction({
          value,
          formName,
          key,
          setMaxHeight,
          maxLineCount,
          dataOpenMode: openMode || MODAL_MODE,
          callback: actionFormId =>
            store.dispatch(
              modal.open({
                createMode: system.FORM_EDIT_CREATE_MODE.EDIT,
                actionFormId,
                //TODO нужно передавать parentFormID
                viewMode: system.VIEW_MODE.MODAL,
                formType: EDIT,
                id: [key]
              })
            )
        });
  }
}
