import SelectColumn from './SelectColumn';
import HeaderFilterDataSource from './HeaderFilterDataSource';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import {system} from 'services/objects';
import onInitLookupFieldShared, {createStorageKey} from 'utilsOld/onInitLookupFieldShared';
import {tagsColumnCellTemplate} from './TagsColumn';
import {columnType} from '../../../utilsOld/helpers';

const {
  ENUM_DS: {VALUE_EXPR, DISPLAY_EXPR}
} = system;

export default class EnumColumn extends SelectColumn {
  /**
   * @param {TableView} view
   * @param row
   */
  valueGetter(view, row) {
    const lookup = view.instance.columnOption(this.field.name, 'lookup.dataSource');
    const value = row[this.field.name];
    const data = lookup?.find(i => i[VALUE_EXPR] === value);
    return {text: data && data[DISPLAY_EXPR], value};
  }

  headerFilterLoadFun = instance => {
    const ds = new HeaderFilterDataSource({
      loadRows: instance.getAllRowsData.bind(instance),
      valueGetter: this.valueGetter.bind(this, instance),
      filterEmpty: true
    });
    return () => {
      return (
        ds
          .createLoadFun()()
          //дублируется (Blank). Компонент сам добавляет такое значение по этому фильтруем
          .filter(item => isDefined(item.value))
          //у справочников ключи должны быть такие как в компоненте
          .map(item => ({
            [VALUE_EXPR]: item.value,
            [DISPLAY_EXPR]: item.text
          }))
      );
    };
  };

  prop() {
    const options = super.prop();
    /**
     * @type {number| boolean}
     * */
    let maxLineCount = options.userData.maxLineCount;
    const storageKey = createStorageKey(this.field.getFormID(), this.field.name);
    let {
      multiselectViewCount,
      isMultiSelect,
      displayType,
      isOneValuePerLine,
      TagColorScheme,
      TagStylingMode,
      columnTextAlignment
    } = this.field;

    let multiselectProps = {};
    if (isMultiSelect) {
      multiselectProps = {
        isMultiSelectField: true,
        editCellTemplate: 'tags-column-editor-template',
        isEnum: true,
        multiselectViewCount,
        cellTemplate: tagsColumnCellTemplate(
          {
            displayType,
            maxTagsCount: multiselectViewCount,
            isMultiSelect,
            isOneValuePerLine,
            TagColorScheme,
            TagStylingMode,
            valExpr: VALUE_EXPR,
            displayExpr: DISPLAY_EXPR,
            maxLineCount,
            textAlignment: columnTextAlignment
          }
        ),
        maxLineCount,
        displayExpr: DISPLAY_EXPR,
        valueExpr: VALUE_EXPR,
        d5Type: this.field.d5EditorType()
      };
    }

    return {
      ...super.prop(),
      allowSorting: false,
      isMultiSelectField: false,
      calculateDisplayValue: undefined,
      isOneValuePerLine,
      TagColorScheme,
      TagStylingMode,
      fieldType: columnType(this.field.fieldType),
      ...multiselectProps,
      lookup: {
        allowClearing: true,
        dataSource: () => {
          return onInitLookupFieldShared.getDataSource(storageKey) || [];
        },
        displayExpr: DISPLAY_EXPR,
        valueExpr: VALUE_EXPR,
      },
    };
  }
}
