export default abstract class KeyboardEventHelper {
  public static ctrlKey(event: KeyboardEvent) {
    return event.ctrlKey || event.metaKey;
  }

  public static shiftKey(event: KeyboardEvent) {
    return event.shiftKey;
  }

  public static altKey(event: KeyboardEvent) {
    return event.altKey;
  }

  public static arrowDownKey(event: KeyboardEvent) {
    return event.key === 'ArrowDown';
  }

  public static arrowUpKey(event: KeyboardEvent) {
    return event.key === 'ArrowUp';
  }

  public static escKey(event: KeyboardEvent) {
    return event.key === 'Escape' || event.key === 'Esc';
  }

  public static enterKey(event: KeyboardEvent) {
    return event.key === 'Enter';
  }

  public static tabKey(event: KeyboardEvent) {
    return event.key === 'Tab';
  }

  public static backspaceKey(event: KeyboardEvent) {
    return event.key === 'Backspace';
  }
  public static spaceKey(event: KeyboardEvent) {
    return event.key === ' ';
  }
}
