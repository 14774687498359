import {SysForm} from 'services/interfaces/sysObjects';
import {fields, system} from 'services/objects';
import {getFormKey} from '../getFormKey';
import {gridConstants} from 'components/Layout/utils/variables';
import {SysSubForm} from 'services/interfaces/sysObjects/SysSubForm';
import {
  ButtonCreators,
  ELayoutType,
  ExtendedSubForm,
  ILayoutItem,
  ISubFormOptions
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {getTranslatedTitle, updateDetailProps} from 'services/SecondaryMethods/formItems/utils';

const {
  MIN_WIDTH: {TABLE_MIN_WIDTH},
  MIN_HEIGHT: {TABLE_MIN_HEIGHT}
} = gridConstants;

function getProps(subForm: ExtendedSubForm): ILayoutItem<ISubFormOptions> {
  return {
    creator: ButtonCreators.FORM,
    name: subForm.Name,
    id: subForm.ID,
    itemType: ELayoutType.SUBFORM,
    parentID: subForm.GroupID,
    order: subForm.Order,
    cols: subForm[fields.Cols],
    //TODO LocationOnGrid
    rows: subForm[fields.Rows],
    minWidth: TABLE_MIN_WIDTH,
    minHeight: TABLE_MIN_HEIGHT,
    isVisible: true,
    title: getTranslatedTitle(subForm.Translations) || subForm[fields.Title],
    options: {
      id: `detail-form-${subForm.DetailFormID}`,
      formID: subForm.DetailFormID,
      parentFormID: subForm.FormID,
      nestedFieldName: subForm[fields.NestedFieldID_Name],
      name: subForm.Name,
      formKey: getFormKey(subForm.DetailFormID, subForm.FormID),
      formType: subForm.formType,
      isDockPanel: subForm[fields.Position] === system.SUBFORM_POSITION.FILTER_DOCK_PANEL
    }
  };
}

export const getSubForms = ({formsSettings, subForms}: {subForms: SysSubForm[]; formsSettings: SysForm[]}) => {
  return updateDetailProps({formsSettings, subForms}).map(item => getProps(item as ExtendedSubForm));
};
