import {D5FormOptions} from '../inner-interfaces';
import {ItemsState} from '../storeStates/itemsState';
import {D5Button} from './public-interfaces';
import {D5FormWithToolbar} from './D5FormWithToolbar';
import {itemGetter} from '../utils';
import {ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {D5FormDecorationElement} from './D5FormDecorationElement';
import {D5FormField} from './D5FormField';
import DynamicFormFieldBase from './DynamicItems/DynamicFormFieldBase';

export default class D5Kanban extends D5FormWithToolbar {
  private _cardItems: ItemsState;
  private _cardButtons: D5Button[] | undefined;
  private _cardDecorationElements: D5FormDecorationElement[] | undefined;
  private _cardFields: (D5FormField | DynamicFormFieldBase)[] | undefined;
  constructor(options: D5FormOptions) {
    super(options);
    this._cardItems = options.kanbanCardItems!;
  }

  private initCardButtons() {
    if (!this._cardButtons) {
      this._cardButtons = this.initButtons(this._cardItems);
    }
  }

  private initCardDecorationElements() {
    if (!this._cardDecorationElements) {
      this._cardDecorationElements = this.initDecors(this._cardItems);
    }
  }

  private initCardFields() {
    if (!this._cardFields) {
      this._cardFields = this.initFields(this._cardItems, {});
    }
  }

  private getCardD5Button(name: string) {
    this.initCardButtons();

    return itemGetter(this._cardButtons!, item => item.name === name, ELayoutType.BUTTON, name);
  }

  private getCardD5DecorationElement(name: string) {
    this.initCardDecorationElements();
    return itemGetter(this._cardDecorationElements!, item => item.name === name, ELayoutType.DECORATION, name);
  }

  private getCardD5Field(name: string) {
    this.initCardFields();
    return itemGetter(this._cardFields!, item => item.name === name, ELayoutType.FORM_FIELD, name);
  }

  public field(name: string) {
    return this.getCardD5Field(name);
  }

  public button(name: string): D5Button | undefined {
    let result = super.button(name);
    return result || this.getCardD5Button(name);
  }

  public decorationElement(name: string): D5FormDecorationElement | undefined {
    let result = super.decorationElement(name);
    return result || this.getCardD5DecorationElement(name);
  }
}
