import dayjs from 'dayjs';
import {isArray, isDefined, isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {formatNumber as dxFormatNumber} from 'devextreme/localization';
import {system} from '../services/objects';
import {FIELD_EDITOR_TYPE, Locale} from 'services/interfaces/global-interfaces';

export const valueToArray = <T>(value: T): T[] => {
  if (isArray(value)) {
    // @ts-ignore
    return value;
  }

  if (isEmptyValue(value)) {
    return [];
  }

  return [value];
};

/**
 * метод нужен для конвертации массива в value
 * или вернуть value если он не массив
 */
export const arrayToValue = <T>(value: T[]): T | null => {
  if (isArray(value) && value.length) {
    const [innerValue] = value;
    return innerValue as T;
  }
  if (isArray(value) && !value.length) {
    return null;
  }
  // @ts-ignore
  return isArray(value) ? value[0] : value;
};

export function valueToRange<T>(value: any): Array<T | undefined> {
  const valueIsArray = isArray(value);
  if (value == null || (valueIsArray && value.every((v: T) => !v))) {
    return [];
  }

  if (valueIsArray) {
    return value.length === 1 ? [...value, undefined] : value;
  }

  return [value, undefined];
}

export function getDatePlaceholder(type: string, locale: Locale = Locale.en) {
  return formatText(type, new Date(), locale).replace(/(\d)/g, '0');
}

export function formatText(
  type: string,
  value: string | Date | number,
  locale: Locale = Locale.en,
  dxNumberFormatExpr?: string
): string {
  switch (type) {
    case FIELD_EDITOR_TYPE.DATE:
      return formatDate(
        value,
        {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        },
        locale
      );
    case FIELD_EDITOR_TYPE.DATETIME:
      return formatDate(
        value,
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        },
        locale
      );
    case FIELD_EDITOR_TYPE.NUMBER: {
      if (!isDefined(value)) return value as string;
      const strVal = value.toString();

      if (Number.parseFloat(strVal)) {
        return dxFormatNumber(Number.parseFloat(strVal), dxNumberFormatExpr ?? '#0.#######');
      }

      return strVal;
    }
    default:
      return value as string;
  }
}

export function formatNumber(value: number, options: Record<string, any> = {}, locale = 'default') {
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(value);
}

export function formatDate(value: any, options = {}, locale: Locale = Locale.en) {
  const formatter = new Intl.DateTimeFormat(locale, options);
  return value ? formatter.format(new Date(value)) : '';
}

export function formatDateToAPI(date: Date, withTime = false) {
  if (withTime) {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
  }
  return dayjs(date).format('YYYY-MM-DD');
}

export function valueToAPI(value: any, type: string, withTime: boolean) {
  const {dxDateBox, dxCheckBox, dxSwitch} = system.DX_CONTROLS;
  let result = value;
  if (type === dxDateBox) {
    if (Array.isArray(value)) {
      result = value.map(date => (!date ? date : formatDateToAPI(new Date(date), withTime)));
    } else {
      result = !value ? value : formatDateToAPI(new Date(value), withTime);
    }
  } else if (dxCheckBox === type || dxSwitch === type) {
    result = value != null ? +!!value : value;
  }

  return result;
}
