import {Columns, GridColumn} from '../column-interfaces';
import {isDefined} from '../../../services/SecondaryMethods/typeUtils';
import {CellsData} from './types';
import classNames from 'classnames';
import {BUTTON_TYPE, FormType, TOTAL_ROW_KIND} from '../../../services/interfaces/global-interfaces';
import DynamicLayoutFieldItemCreator from '../../../services/tables/DynamicItemCreators/DynamicFieldItemCreator';
import {toNumber} from '../../../utilsOld/formatLocaleNumber';
import * as aggregationFns from '../aggregationFunctions';
import {system} from '../../../services/objects';

const {LIST, TREE} = FormType;

const {SYSTEM_BUTTONS} = system;

const getDataGridColGroup = (tableElement: HTMLElement): HTMLElement | null =>
  tableElement.getElementsByClassName('dx-datagrid-rowsview')[0]?.querySelector('colgroup') ?? null;

const getTreeListColGroup = (tableElement: HTMLElement): HTMLElement | null => {
  const containers = [...tableElement.getElementsByClassName('dx-treelist-content')];
  return containers.find(el => !el.classList.contains('dx-treelist-content-fixed'))?.querySelector('colgroup') ?? null;
};

//Метод находит хедеры колонок в таблице и проставляет клеткам сумирующей строки ширину равную хедеру колонки
export const getVisibleColumnsSizes = (tableElement: HTMLElement, tableType: FormType.LIST | FormType.TREE) => {
  const colGroupByFormType = {
    [LIST]: getDataGridColGroup,
    [TREE]: getTreeListColGroup
  };
  const getColGroup = colGroupByFormType[tableType];
  const colgroup = getColGroup(tableElement);
  // @ts-ignore
  return [...(colgroup?.children || [])].map(col => col.style.width);
};

function getTextAlign(dataType: string): 'left' | 'right' | 'center' {
  const types = {
    number: 'right',
    boolean: 'center'
  };
  // @ts-ignore
  return types[dataType] || 'left';
}

export function visibleColumnsToCellData(
  summariesData: Record<string, string | number | null>,
  columns: Columns,
  visibleColumns: Columns
) {
  return (): CellsData[] => {
    const dataValues = summariesData || {};

    if (!columns) {
      return [];
    }
    return visibleColumns.map(colItem => {
      // @ts-ignore
      const {caption, visibleIndex, dataField, fixed, dataType, totalFunction} = colItem;

      const summaryValue = dataValues[dataField!];
      return {
        name: dataField,
        caption: caption,
        summary: isDefined(summaryValue) ? String(summaryValue) : '',
        // @ts-ignore
        textAlign: getTextAlign(dataType),
        visibleIndex,
        fixed: !!fixed,
        totalFunction
      };
    });

  };
}

export function separateFixedCellsData(cellsData: CellsData[]) {
  let fixed: CellsData[] = [],
    notFixed: CellsData[] = [];
  for (const data of cellsData) {
    fixed.push({
      ...data,
      summary: data.fixed && (data.summary || ' ')
    });
    notFixed.push({
      ...data,
      summary: !data.fixed && (data.summary || ' ')
    });
  }
  return [notFixed, fixed];
}

export function getWrapperClasses(isFixedTable: boolean) {
  const baseWrapperClasses = ['dx-datagrid-content', 'dx-datagrid-scroll-container'];
  return classNames(...baseWrapperClasses, {
    'dx-datagrid-content-fixed': isFixedTable,
    'dx-pointer-events-target': isFixedTable,
    'fixed-columns-summary-wrapper': isFixedTable
  });
}

export function getTableClasses(isFixedTable: boolean) {
  const baseClasses = ['dx-datagrid-table', 'dx-datagrid-table-fixed'];
  return classNames(...baseClasses, {
    'dx-pointer-events-none': isFixedTable
  });
}

export function getCellValueWrapperClasses(isFixedTable: boolean) {
  const baseClasses = ['dx-datagrid-summary-item', 'dx-datagrid-text-content'];
  return classNames(...baseClasses, {
    'dx-theme-background-color': isFixedTable
  });
}

export const getOriginalSummaryValue = (summaryValue: any) => {
  if (summaryValue && typeof summaryValue === 'string') {
    return toNumber(summaryValue.replace(/\s/g, ''));
  }
  return summaryValue;
}

export const getSummariesDataWithKeys = (cols: GridColumn[], summary: Record<string, any>) => {
  const result: Record<string, any> = {};

  cols.forEach(col => {
    if (summary.hasOwnProperty(col.name)) {
      let name = col.name;
      if (col.isDynamicColumn) {
        const creator = new DynamicLayoutFieldItemCreator({
          baseField: DynamicLayoutFieldItemCreator.getBaseFieldName(name)!
        });
        name = creator.dataName(name);
      }
      result[name] = getOriginalSummaryValue(summary[col.name]);
    }
  })

  return result;
}
export const getTableWrapperId = (formId: string) => {
  return 'table-' + formId;
};

export const totalKindToButtonType = (kind: TOTAL_ROW_KIND) => {
  const kindToType = {
    [TOTAL_ROW_KIND.NONE]: BUTTON_TYPE.TOGGLE_TOTALS_NONE,
    [TOTAL_ROW_KIND.BY_SELECTED]: BUTTON_TYPE.TOGGLE_TOTALS_BY_SELECTED,
    [TOTAL_ROW_KIND.ALL_DATA]: BUTTON_TYPE.TOGGLE_TOTALS_ALL_DATA
  };
  return kindToType[kind];
};

export const getTotalRowKind = (kind: TOTAL_ROW_KIND) => {
  const kindToType = {
    [TOTAL_ROW_KIND.NONE]: SYSTEM_BUTTONS.TOGGLE_TOTALS_NONE,
    [TOTAL_ROW_KIND.BY_SELECTED]: SYSTEM_BUTTONS.TOGGLE_TOTALS_BY_SELECTED,
    [TOTAL_ROW_KIND.ALL_DATA]: SYSTEM_BUTTONS.TOGGLE_TOTALS_ALL_DATA
  };
  return kindToType[kind];
};

export const aggregationFnsMap = {
  count: aggregationFns.count,
  avg: aggregationFns.avg,
  sum: aggregationFns.sum,
  min: aggregationFns.min,
  max: aggregationFns.max
};

export type AggregationFnsNames = keyof typeof aggregationFnsMap;
