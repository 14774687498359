import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {BaseUserEventArgs} from './types';

export type DataType = {direction: string, row: {key: number, data: Record<string, any>}};

export interface OnItemSwipeArgs extends BaseUserEventArgs {
  data: DataType;
}
export const onItemSwipe = async ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  ...rest
}: OnItemSwipeArgs) => {
  const {data} = rest;
  const args = {data};
  const {formData} = processedForm;
  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [args],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};