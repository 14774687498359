import {D5Error} from 'services/SecondaryMethods/errors';
import {DiagramCategory, ICustomShapes} from '../../components/newReactFormFields/Diagram/interfaces';
import {groupBy} from 'utilsOld/utility';
import {getDiagramConfigUrl, userResourceUrl} from '../baseUrl';
import {IApplication} from '../applications/reducer';
import axios from 'axios';

type Category = Required<DiagramCategory>['category'];

type DiagramConfig = {
  customShapes?: ICustomShapes[];
  categories: DiagramCategory[];
};

const loadDiagramConfig = async (applications: IApplication[]): Promise<DiagramConfig> => {
  try {
    let responses: {data: Pick<DiagramConfig, 'customShapes'>; appName: string}[] = await Promise.allSettled(
      applications.map(app => axios(getDiagramConfigUrl(app.name)).then(res => ({res, appName: app.name})))
    ).then(responses => {
      return (
        responses
          // @ts-ignore
          .filter(({status, value}) => status === 'fulfilled' && value.res.status === 200)
          // @ts-ignore
          .map(({value}) => value)
          .map(({res, appName}) => ({data: res.data, appName}))
      );
    });

    let customShapes: ICustomShapes[] = [];

    responses.forEach(({data, appName}) => {
      data.customShapes &&
        customShapes.push(
          ...data.customShapes.map(customShape => {
            let {backgroundImageUrl: url} = customShape;
            if (url && !/^(http|https):\/\//.test(url)) {
              url = url.startsWith('/') ? url.substring(1) : url;
              url = `${userResourceUrl(appName)}/${url}`;
            }
            customShape.backgroundImageUrl = url;
            return customShape;
          })
        );
    });

    const grouped = groupBy(customShapes || [], 'category') as Record<string, ICustomShapes[]>;

    let categories = Object.entries(grouped).map(([category, shapes]): DiagramCategory => {
      return {
        displayMode: shapes.some(shape => shape.backgroundImageUrl) ? 'icons' : 'texts',
        // shapes,
        category: category as Category,
        title: category
      } as const;
    });

    return {customShapes, categories};
  } catch (e) {
    D5Error.log('E1021');
  }

  return {customShapes: undefined, categories: []};
};

export default loadDiagramConfig;
