import {fields} from 'services/objects';
import {D5FormField} from 'middlewares/userScript/elems/D5FormField';
import {D5FormFilterField} from 'middlewares/userScript/elems/D5FormFilterField';
import {itemGetter} from 'middlewares/userScript/utils';
import {D5Form} from 'middlewares/userScript/elems/D5Form';
import {D5FormOptions} from 'middlewares/userScript/inner-interfaces';
import {ObjectSource} from 'middlewares/userScript/elems/dataSource';
import {D5ListForm} from 'middlewares/userScript/elems/listForm';
import {FormCreateMode} from 'services/interfaces/global-interfaces';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import deepCopy from 'utilsOld/deepCopy';
import {ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';

export class D5FormEdit extends D5Form {
  private keyValues: string[] | number[] = [];
  private readonly _selectedRowsData: Record<string, any>[] = [];
  public readonly fields: D5FormField[];
  public readonly filterFields: D5FormFilterField[];
  protected source: ObjectSource;

  constructor(props: D5FormOptions) {
    super(props);

    const {filters, sysForm, currentForm} = props;
    // @ts-ignore
    this.fields = this.initFields(this.formItems, this.formData);
    this.filterFields = this.initFilters(sysForm, filters);
    this.keyValues = Array.isArray(currentForm.id) ? currentForm.id : [];
    this._selectedRowsData = Array.isArray(currentForm.parentFormSelectedRows)
      ? currentForm.parentFormSelectedRows
      : [];
    this.source = new ObjectSource(this.formData);
  }

  get createMode(): FormCreateMode | null {
    return this.currentForm.createMode;
  }

  get keyValue() {
    return this.keyValues;
  }

  get rowsData() {
    return this._selectedRowsData;
  }

  get rows() {
    return this._selectedRowsData;
  }

  get readOnly() {
    return this.currentForm.readOnly;
  }

  //устанавливаем для fields - disabled согласно входящему value
  set readOnly(value: boolean) {
    this.currentForm.readOnly = !!value;
  }

  public field(name: string) {
    return itemGetter(this.fields, item => item.name === name, ELayoutType.FORM_FIELD, name);
  }

  public filterField(name: string) {
    return itemGetter(this.filterFields, item => item.name === name, ELayoutType.FILTER, name);
  }

  get operationsParams(): any {
    return this.currentForm.operationsParams;
  }

  set operationsParams(params: any) {
    this.currentForm.operationsParams = params;
  }

  public async save(silentMode: boolean, operation: 'Ins' | 'Mod') {
    if (typeof this.currentForm.events.saveAction === 'function') {
      const {error, response} = await this.currentForm.events.saveAction({
        silentMode,
        operation,
        localFormData: deepCopy(this.formData),
        localItems: this.formItems.getFields(),
      });
      if (error) throw error;

      const objectIdName = this.sysForm[fields.ObjectID_Name];
      const keyValues = response?.[objectIdName]?.[0]?.[this.keyField];
      this.keyValues = isDefined(keyValues) ? [keyValues] : this.keyValues;
      return response;
    }
    return true;
  }

  public async refresh() {
    const {events} = this.currentForm;
    if (typeof events.refresh === 'function') {
      let result = await events.refresh({
        items: this.formItems,
        params: this.operationsParams
      });

      if (typeof result === 'object' && result.hasOwnProperty('formData')) {
        //нужно обновить значения во всех вложенных таблицах
        this.subForms.forEach(form => {
          if (
            form instanceof D5ListForm &&
            form.nestedFieldName &&
            result.formData.hasOwnProperty(form.nestedFieldName)
          ) {
            form.datasource.data = result.formData[form.nestedFieldName];
            form._unsafeSetModified(false);
          }
        });

        this.currentForm.isModified = false;
        this.source.merge(result.formData);
        this.formItems.rewriteSource([...result.items]);
      }
      return true;
    }
    return false;
  }

  get datasource() {
    return {
      refreshData: this.refresh.bind(this),
      data: this.formData,
    };
  }
}
