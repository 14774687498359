import {
  ActionType,
  DefaultButtonOptions,
  ToolBarButtonOptions,
  toolBarButtonProps,
  ToolBarItem,
  ToolBarItemWidget,
  toolBarMenuProps
} from 'utilsOld/toolbar_buttons';
import {IButtonOptions, IGroupOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {getButtonAction} from 'services/SecondaryMethods/buttonUtils';
import {BUTTON_TYPE, GroupType} from 'services/interfaces/global-interfaces';
import {isSplitButton} from 'components/toolbar/button/utils';
import listToTree from 'utilsOld/listToTree';
import {isDefined} from 'services/SecondaryMethods/typeUtils';

type IOnClick = (event: any, action: any, id: any) => any;

function getItem(options: ToolBarButtonOptions) {
  const {items} = options;
  if (Array.isArray(items) && items.length) {
    return toolBarMenuProps(options);
  }
  return toolBarButtonProps(options);
}

export function getDefaultDisabledState(buttonType: BUTTON_TYPE) {
  const action: ActionType = getButtonAction(buttonType);
  // @ts-ignore
  return !!DefaultButtonOptions[action].disabled;
}

export function getOptions(button: ILayoutItem<IButtonOptions>): ToolBarButtonOptions {
  const {
    options: {
      buttonType,
      disabled,
      isHideInOtherActions,
      titleType,
      beginGroup,
      icon,
      hotkey,
      buttonFillingRules,
      title,
      hint,
      defaultIcon,
      defaultTitle,
      isPressed = false,
      formOpenMode,
      colorScheme,
      stylingMode
    },
    docUrl,
    isVisible
  } = button;
  const action: ActionType = getButtonAction(buttonType);

  //пустой обьект если генерируються кнопки сохраненных фильтров
  const def = DefaultButtonOptions[action] || {};

  return {
    action,
    disabled: disabled == null ? getDefaultDisabledState(buttonType) : disabled,
    elementAttr: {
      class: def.className
    },
    groupID: button.parentID,
    hint: '',
    colorScheme,
    stylingMode,
    help: hint || '',
    iconType: icon,
    defaultIcon,
    defaultTitle,
    id: button.id,
    locateInMenu: isHideInOtherActions ? 'always' : 'auto',
    formButtonFillingRules: buttonFillingRules,
    onClickCustom: (): void => {},
    order: button.order,
    text: title,
    isPressed,
    name: button.name,
    titleType,
    visible: isVisible,
    beginGroup,
    docUrl,
    hotkey,
    formOpenMode
  };
}

function groupToItem(group: ILayoutItem<IGroupOptions>): ToolBarButtonOptions {
  let widget = ToolBarItemWidget.Menu;
  if (group.options.groupType === GroupType.TOOLBAR_BUTTONS_SECTION) {
    widget = ToolBarItemWidget.Section;
  }

  return {
    action: ActionType.ArrowLeft,
    defaultIcon: group.options.defaultIcon,
    defaultTitle: group.options.defaultTitle,
    disabled: undefined,
    elementAttr: {},
    hotkey: undefined,
    isPressed: false,
    locateInMenu: group.options.isHideInOtherActions ? 'always' : 'auto',
    onClickCustom() {},
    visible: group.isVisible,
    groupID: group.parentID,
    iconType: group.options.icon || '',
    id: group.id,
    items: [],
    order: group.order,
    text: group.options.title,
    name: group.name,
    beginGroup: group.options.beginGroup,
    titleType: group.options.titleType,
    hint: '',
    help: group.options.hint || '',
    docUrl: group.options.docUrl,
    widget,
    colorScheme: group.options.colorScheme,
    stylingMode: group.options.stylingMode
  };
}

function combineItems(
  buttons: ToolBarButtonOptions[],
  groupItems: ILayoutItem<IGroupOptions>[]
): ToolBarButtonOptions[] {
  return listToTree<ToolBarButtonOptions>({
    list: [...buttons, ...groupItems.filter(gr => gr.isVisible).map(groupToItem)],
    childProp: 'items',
    parentProp: 'groupID',
    sortByProp: 'order',
    keyProp: 'id'
  }).filter(item => !isDefined(item.groupID)); // фильтруем только элементы верхнего уровня
}

function getButtonsOptions(buttons: ILayoutItem<IButtonOptions>[], onClickCustom: IOnClick): ToolBarButtonOptions[] {
  return buttons.map((btn: ILayoutItem<IButtonOptions>): ToolBarButtonOptions => {
    let options = getOptions(btn);
    options.onClickCustom = onClickCustom;
    options.splitButton = isSplitButton(options);
    return options;
  });
}

export default function getToolbarItems(
  buttons: ILayoutItem<IButtonOptions>[],
  groups: ILayoutItem<IGroupOptions>[],
  onClickCustom: IOnClick
): ToolBarItem[] {
  return combineItems(
    getButtonsOptions(buttons, onClickCustom),
    groups.map(gr => ({...gr, isVisible: !!gr.options.position}))
  ).map(getItem);
}
