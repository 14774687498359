import {isArray, isDefined, isObject} from 'services/SecondaryMethods/typeUtils';
import {loadObjectData} from 'utilsOld/common_requests';
import {ToolBarItem, ToolBarItemWidget} from 'utilsOld/toolbar_buttons';
import {fields} from 'services/objects';
import {FilterField} from 'utilsOld/systemObjects';
import {each, pluck} from 'utilsOld/utility';
import {BorderStyleStr, ButtonColorScheme, ButtonStylingMode} from '../../services/SecondaryMethods/formItems/itemInterfaces';
import {isMobile} from '../../utilsOld/device';
import FormTypeUtil from 'utilsOld/systemObjects/SystemFormType';

export const createPropertyValuePair = (initialValue: any, focusedObject: any, property: string, value: any) => {
  if (isArray(focusedObject)) {
    for (let key of focusedObject) {
      createPropertyValuePair(initialValue, key, property, value);
    }
  }
  if (isObject(focusedObject)) {
    for (let key in focusedObject) {
      createPropertyValuePair(initialValue, focusedObject[key], property, value);
    }
  }
  if (focusedObject && focusedObject.hasOwnProperty(property) && focusedObject.hasOwnProperty(value)) {
    initialValue[focusedObject[property]] = focusedObject[value];
  }
  return initialValue;
};

export const loadDictionaryValues = async (formField: FilterField, value: any) => {
  const data = await loadObjectData({
    columns: [formField.getKeyObjFieldName(), formField.displayField],
    filter: {
      [formField.getKeyObjFieldName()]: value
    },
    objectName: formField.getLinkedName()
  });
  if (!data?.length) return {};
  let result = data.reduce(
    (res: any, curr: Record<string, any>) => {
      res.value.push(curr[formField.getKeyObjFieldName()]);
      res.displayValue.push(curr[formField.displayField]);
      return res;
    },
    {value: [], displayValue: []}
  );

  if (result.displayValue.length === 1) {
    result.value = result.value[0];
    result.displayValue = result.displayValue[0];
  }
  return result;
};

export const prepareFormDataForRules = async ({
  buttonRules,
  selKeys,
  requestColumns,
  objectName,
  keyField,
  selectedRowData
}: {
  buttonRules: Record<string, any>[];
  selKeys: number[];
  requestColumns: string[];
  objectName: string;
  keyField: string;
  selectedRowData?: Record<string, any>;
}) => {
  let colNames = pluck(buttonRules, fields.SourceFieldID_Name);
  colNames = colNames.length ? colNames : requestColumns;

  let objectData: any[] = await loadObjectData({
    filter: {
      [keyField]: selKeys
    },
    columns: colNames,
    objectName
  });
  return getFormDataRules({buttonRules, objectData, selectedRowData});
};

export const getFormDataRules = ({
  buttonRules,
  objectData,
  selectedRowData
}: {
  buttonRules: Record<string, any>[];
  objectData: any[];
  selectedRowData?: Record<string, any>;
}) => {
  if (!buttonRules.length) {
    return objectData[0];
  }
  if (selectedRowData) {
    objectData = objectData.map(item => {
      Object.keys(item).forEach(key => {
        if (selectedRowData.hasOwnProperty(key)) {
          item[key] = selectedRowData[key];
        }
      });
      return item;
    });
  }
  const out: Record<string, any> = {};
  objectData.forEach((item: any) => {
    each(item, (value: any, key: any) => {
      const foundRule = buttonRules.filter((rule: {[x: string]: string}) => rule[fields.SourceFieldID_Name] === key);
      if (foundRule.length) {
        out[foundRule[0][fields.DestinationFieldID_Name]] = value;
      }
    });
  });
  return out;
};

export function delayRepaint(func: () => void, wait: number) {
  let timeout: NodeJS.Timeout | null;
  return function () {
    const args = arguments;
    const later = function () {
      timeout = null;
      // @ts-ignore
      func.apply(this, args);
    };
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
  };
}

const ICON_PADDING: number = isMobile() ? 18 : 14;
// const PADDING: number = 16;
const WIDGET_WIDTH: number = isMobile() ? 32 : 24;
const BEGIN_GROUP: number = 3;
const MEASURE = 0.7;
const SIZES: {font: number; icon: number} = {
  font: isMobile() ? 20 : 12,
  icon: isMobile() ? 20 : 14
};

const calculateSectionWidth = (items: ToolBarItem['options']['items']): number => {
  const [{items: innerItems = []}] = items || [];
  return innerItems.reduce(
    (res, item) =>
      res +
      getInvisibleItem({
        options: item,
        widget: ToolBarItemWidget.Button,
        beginGroup: false
      }),
    0
  );
};

// формула подсчета приблизительного размера одной кнопки
const getInvisibleItem = ({
  options: {iconType, text = '', items},
  beginGroup = false,
  widget
}: Pick<ToolBarItem, 'widget' | 'beginGroup' | 'options'>) => {
  if (widget === ToolBarItemWidget.Section) {
    return calculateSectionWidth(items);
  }
  const maxVisibleLengthTextSpace = 20;
  const maxViewSizeForTextWidth = 100;

  // расчет размера заполнение иконки
  const iconSpace = iconType ? ICON_PADDING + SIZES.icon : 0;
  // расчет размера заполнение beginGroup
  const beginGroupSpace = beginGroup ? BEGIN_GROUP : 0;
  // расчет размера заполнение widget(стрелочки вниз)
  const widgetSpace = widget === ToolBarItemWidget.Menu ? WIDGET_WIDTH : 0;
  // расчет размера заполнение текста
  const textSpace = text.length > maxVisibleLengthTextSpace ?
   maxViewSizeForTextWidth : text.length * (SIZES.font * MEASURE);
  // после общего обсчета используется эта часть SIZES для адаптивности
  return iconSpace + beginGroupSpace + widgetSpace + textSpace /* + PADDING*/;
};
export const separateVisibleItems = (
  updatedButtons: ToolBarItem[],
  toolbarWidth: number
): {
  visible: ToolBarItem[];
  hidden: ToolBarItem[];
} => {
  const autoItems: ToolBarItem[] = [],
    alwaysItems: ToolBarItem[] = [];

  updatedButtons.forEach(button => {
    button.locateInMenu === 'always' ? alwaysItems.push(button) : autoItems.push(button);
  });

  if (!toolbarWidth) {
    return {
      visible: autoItems,
      hidden: alwaysItems
    };
  }

  let lastVisibleIndex = 0;
  // Починаємо розрахунок з 38, бо це сума ширини групуючої кнопки "Інше"
  // Дефолтний Розрахнок відбувається без урахування її ширини
  let sum = isMobile() ? 0 : 38;

  while (autoItems[lastVisibleIndex] && sum <= toolbarWidth) {
    sum += getInvisibleItem(autoItems[lastVisibleIndex]);
    lastVisibleIndex++;
    if (sum > toolbarWidth) {
      lastVisibleIndex--;
    }
  }

  return {
    visible: autoItems.slice(0, lastVisibleIndex),
    hidden: [...autoItems.slice(lastVisibleIndex), ...alwaysItems]
  };
};

export const buttonColorSchemeToDX = (
  color: ButtonColorScheme | null | undefined
): 'danger' | 'default' | 'normal' | 'success' | 'warning' => {
  switch (color) {
    case ButtonColorScheme.Danger:
      return 'danger';
    case ButtonColorScheme.Success:
      return 'success';
    case ButtonColorScheme.Primary:
      return 'default';
    case ButtonColorScheme.Warning:
      return 'warning';
    default:
      return 'normal';
  }
};

export const buttonStylingModeToDX = (
  stylingMode: ButtonStylingMode | null | undefined
): 'text' | 'outlined' | 'contained' => {
  switch (stylingMode) {
    case ButtonStylingMode.Outlined:
      return 'outlined';
    case ButtonStylingMode.Contained:
      return 'contained';
    default:
      return 'text';
  }
};

export const borderStyleToButtonStylingMode = (borderStyle: BorderStyleStr) => {
  switch (borderStyle) {
    case BorderStyleStr.Default:
      return ButtonStylingMode.Outlined
    case BorderStyleStr.None:
      return ButtonStylingMode.None
    default:
      return ButtonStylingMode.Contained;
  }
}

export const getInitFormData = (mode: string, parentFormType: number | undefined, initialFormData: any) => {
  let resultFormData = {};

  const { StartDate, EndDate } = initialFormData || {};

  if (FormTypeUtil.isScheduler(parentFormType) && isDefined(StartDate) && isDefined(EndDate)) {
    resultFormData = initialFormData;
  }

  return mode === 'add' ? resultFormData : initialFormData;
}