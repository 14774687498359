import {applyStoredSettingsToToolbarButtons} from '../TableCoreOld/ColumnSettings/utils';
import {getButtons} from 'services/SecondaryMethods/formItems';
import {
  excludeCopyUrlButton,
  filterExportAllButton,
  setDefTotalRowKind
} from 'services/SecondaryMethods/formItems/utils';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {BUTTON_POSITION, LS_TOTAL_ROW} from 'services/interfaces/global-interfaces';
import {ToggleTotalButton} from '../table/TableToolBarDirector';

type LSButtons = Parameters<typeof applyStoredSettingsToToolbarButtons>[0]['lsButtons'];

export default function createToolbarButtons(
  sysForm: SysFormWrapper,
  formIsModal = false,
  lsButtons: LSButtons = [],
  lsTotalRowKind?: ToggleTotalButton
) {
  const {ALL, TOOLBAR, CONTEXT_MENU} = BUTTON_POSITION;
  let buttons = applyStoredSettingsToToolbarButtons({
    lsButtons,
    storeButtons: getButtons(sysForm.buttons, () => null).filter(
      ({options: {position}}) => position === TOOLBAR || position === CONTEXT_MENU || position === ALL
    )
  });

  if (formIsModal) {
    buttons = excludeCopyUrlButton(buttons);
  }

  if (sysForm.type.isTree() && sysForm.paginationType.isInfiniteScroll()) {
    buttons = filterExportAllButton(buttons);
  }

  // @ts-ignore
  buttons = setDefTotalRowKind(buttons, LS_TOTAL_ROW[lsTotalRowKind] ?? sysForm.totalRowKind);
  return buttons;
}
