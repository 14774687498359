import {D5FormOptions, UserScriptSchedulerResources} from '../inner-interfaces';
import {D5FormWithToolbar} from './D5FormWithToolbar';
import D5SchedulerResource from './D5SchedulerResource';
import {Draft} from '@reduxjs/toolkit';
import {SchedulerResource} from 'components/widgets/Scheduler/types';
import {getResources} from 'components/forms/scheduler/utils/utils';
import {SysFormSchedulerResources} from 'services/interfaces/sysObjects/SysFormSchedulerResources';

export default class D5Scheduler extends D5FormWithToolbar {
  protected _selectedItems: Record<string, any> = [];
  schedulerResources: UserScriptSchedulerResources;
  private _endDayHour: number;
  private _startDayHour: number;

  constructor(options: D5FormOptions) {
    super(options);
    this.schedulerResources = options.schedulerResources;
    this._selectedItems = options.currentForm.selectedRows;
    this._startDayHour = options.schedulerStartDayHour;
    this._endDayHour = options.schedulerEndDayHour;
  }

  get fields() {
    // в шедулере нельзя обращаться к полям
    return [];
  }

  field() {
    // в шедулере нельзя обращаться к полям
    return undefined;
  }

  get datasource() {
    return this.currentForm.dataSource;
  }

  get resources(): D5SchedulerResource[] {
    return this.schedulerResources.map(res => new D5SchedulerResource(res));
  }

  resource(name: string): D5SchedulerResource | undefined {
    const res = this.schedulerResources.find(res => res.fieldExpr === name);
    if (!res) return;

    return new D5SchedulerResource(res);
  }

  get selectedItems() {
    return this._selectedItems.map((item: any) => {
      return {key: item.ID, data: item} || [];
    });
  }

  public async refresh() {
    const {events} = this.currentForm;
    if (typeof events.refresh === 'function') {
      await events.refresh({params: this.operationsParams});
    }

    return true;
  }

  public addResource(newResource: SysFormSchedulerResources) {
    const updatedResources = getResources([newResource] as SysFormSchedulerResources[]);
    this.schedulerResources.push(...(updatedResources as unknown as Draft<SchedulerResource[]>));
  }

  get startDayHour(): number {
    return this._startDayHour;
  }

  get endDayHour(): number {
    return this._endDayHour;
  }

  set startDayHour(newHour: number) {
    if (newHour < 0 || newHour > 24) throw new Error('startDayHour property accepts only integer values from 0 to 24');

    this._startDayHour = newHour;
  }

  set endDayHour(newHour: number) {
    if (newHour < 0 || newHour > 24) throw new Error('endDayHour property accepts only integer values from 0 to 24');

    this._endDayHour = newHour;
  }
}
