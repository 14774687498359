import {IButtonOptions, ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';
import {EditorButtonsActionsTypes} from './actions';

const {SET_BUTTONS} = EditorButtonsActionsTypes;

const mergeArrays = (original: ILayoutItem<IButtonOptions>[] = [], newData: ILayoutItem<IButtonOptions>[]) => {
  newData.forEach(btn => {
    const index = original.findIndex(item => item.id === btn.id);
    if (index !== -1) {
      original[index] = btn;
    } else {
      original.push(btn);
    }
  });
  return original;
};

export const editorButtonsReducer = (
  state: Record<string, ILayoutItem<IButtonOptions>[]> = {},
  {type, payload}: {type: EditorButtonsActionsTypes; payload: {buttons: ILayoutItem<IButtonOptions>[]; formKey: string}}
): Record<string, ILayoutItem<IButtonOptions>[]> => {
  switch (type) {
    case SET_BUTTONS:
      return {
        ...state,
        [payload.formKey]: [...mergeArrays(state[payload.formKey], payload.buttons)]
      };
    default:
      return state;
  }
};
