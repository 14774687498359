import {fields} from 'services/objects';
import BaseField from './BaseField';
import {locateOnGrid} from '../gridUtils';
import {SysFormFieldEvent, SysFormFields} from 'services/interfaces/sysObjects';
import {FormType, TOTAL_FUNC_KINDS} from 'services/interfaces/global-interfaces';
import {SortByDefault} from 'middlewares/userScript/elems/public-interfaces';
import {getLookupDisplayField} from '../sysFormUtils';

export default class FormField extends BaseField<SysFormFields> {
  /**
   * @param {SysFormFields | {field: SysFormFields, [events]: []}} args
   */
  constructor(args: SysFormFields) {
    if (!args.hasOwnProperty('ID') || !args.hasOwnProperty('Name')) {
      throw new TypeError('FormField.constructor: args must be type of SysFormFields');
    }
    super(args);
  }

  //-----inherited fields---------------------
  get isMultiValueField() {
    return !!this._field[fields.IsMultiValueField];
  }

  get isShowInMainForm() {
    return false;
  }

  getKeyObjFieldName(): string | undefined {
    return this._field[fields.ObjectFieldID_KeyObjectFieldID_Name];
  }

  get keyObjFieldType(): number | undefined {
    return this._field[fields.ObjectFieldID_KeyObjectFieldID_FieldType];
  }

  get stringLength(): number | undefined {
    return this._field[fields.ObjectFieldID_StringLength];
  }

  get getObjLinkedFormForSelectByDefault(): number | undefined {
    return this._field[fields.ObjectFieldID_LinkedObjectID_FormForSelectByDefault];
  }

  get TagColorScheme(): number | undefined {
    return this._field[fields.TagColorScheme];
  }

  get TagStylingMode(): number | undefined {
    return this._field[fields.TagStylingMode];
  }

  get LookupTagColorSchemeFieldName(): string | undefined {
    return this._field[fields.LookupTagColorSchemeFieldName];
  }

  get LookupTagStylingModeFieldName(): string | undefined {
    return this._field[fields.LookupTagStylingModeFieldName];
  }

  get getObjLinkedFormForSelectByDefaultName(): string | undefined {
    return this._field[fields.ObjectFieldID_LinkedObjectID_FormForSelectByDefault_Name];
  }

  get formForSelectByDefault(): number | undefined {
    return this._field[fields.ObjectFieldID_LinkedObjectID_FormForSelectByDefault];
  }

  getLinkedName(): string | undefined {
    return this._field.LookupObjectName || this._field[fields.ObjectFieldID_LinkedObjectID_Name];
  }

  getFormID(): string {
    return this._field[fields.FormID];
  }

  get formType(): FormType {
    return this._field[fields.FormID_Type];
  }

  get required() {
    return !!this._field[fields.ObjectFieldID_IsRequired];
  }

  get fieldType(): number {
    if (this.isLookup()) {
      return this._field[fields.ObjectFieldID_KeyObjectFieldID_FieldType]!;
    }
    return this.objectFieldIDFieldType!;
  }

  get displayField(): string | undefined {
    return (
      this._field.LookupDisplayFieldName ||
      this._field[fields.DisplayField] ||
      this._field[fields.ObjectFieldID_DisplayObjectFieldID_Name]
    );
  }

  //-----inherited fields---------------------

  //-----own fields------------
  get sortByDefault(): SortByDefault {
    return this._field[fields.SortByDefault];
  }

  get lineCount(): number {
    return this._field[fields.LineCount]!;
  }

  get events(): Array<SysFormFieldEvent> {
    return this._field.Sys_FormFieldEvents || [];
  }

  set lineCount(lineCount) {
    this._field[fields.LineCount] = lineCount;
  }

  get cols() {
    return locateOnGrid(this._field).cols;
  }

  get offset() {
    return locateOnGrid(this._field).offset;
  }

  get newLine() {
    return locateOnGrid(this._field).newLine;
  }

  get rows(): number {
    return 0;
    // return this._field[fields.Rows] as number;
  }

  get lookupDisplayField(): string {
    return getLookupDisplayField(this.objectFieldIDName, this.displayField!);
  }

  get lookupTagColorScheme(): string | undefined {
    if (!this.LookupTagColorSchemeFieldName) return undefined;

    return getLookupDisplayField(this.objectFieldIDName, this.LookupTagColorSchemeFieldName!);
  }

  get lookupTagStylingMode(): string | undefined {
    if (!this.LookupTagStylingModeFieldName) return undefined;

    return getLookupDisplayField(this.objectFieldIDName, this.LookupTagStylingModeFieldName!);
  }

  get parentGroupID(): number | null {
    return this._field[fields.GroupID_ParentGroupID] as number;
  }

  get groupTitle(): string | undefined {
    return this._field[fields.GroupID_Title];
  }

  get name(): string {
    return this._field[fields.Name];
  }

  get isSortingAllowed(): number {
    return this._field[fields.IsSortingAllowed];
  }

  get totalFunction(): TOTAL_FUNC_KINDS {
    return this._field[fields.TotalFunction];
  }

  get viewFormat()  {
    return this._field[fields.ViewFormat];
  }

  get numberScale() {
    return this._field[fields.ObjectFieldID_NumberScale] ?? 0;
  }

  get isFixedLeft(): boolean {
    return !!this._field[fields.IsFixedLeft];
  }

  get parentFieldID() {
    return this._field[fields.ParentFieldID];
  }

  isTextArea() {
    return this.isText() && !!this.lineCount;
  }

  get isDynamicField() {
    return !!this._field[fields.IsDynamicField];
  }

  get translations() {
    return this._field[fields.Translations];
  }
}
