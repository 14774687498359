import {Map} from 'immutable';
import {actionTypes as modalTypes} from '../modal/actions';
import {actionTypes as loadingActions} from '../loading/actions';
import {getSpecificForm} from 'services/currentForms/specificForm';
import {actionTypes} from './actionTypes';
import {getFormKey} from '../SecondaryMethods/getFormKey';
import {isObject} from '../SecondaryMethods/typeUtils';
import {ELayoutType} from '../SecondaryMethods/formItems/itemInterfaces';

/**
 * @type {Map<string, CurrentFormState>}
 */
export const initialState = Map({});

const updateForm = (state, formKey, options, rewrite = false) => {
  if (!state.has(String(formKey))) {
    return state;
  }

  return Object.entries(options).reduce(
    (aState, [key, value]) =>
      aState.updateIn([String(formKey), key], curValue => {
        if (isObject(value) && isObject(curValue) && !rewrite) {
          return {...curValue, ...value};
        }
        return value;
      }),
    state
  );
};

/**
 * @param {string|number} formID
 * @param {{}[]} items
 * @param {{}[]} groups
 * @param {RowPerPage} rowsPerPage
 * @param {Map} state
 * @param parentFormID
 * @param formStatus {string | null} init/show
 * @param {Array<number | string>} selectedRowKeys
 * @param {Array<Object>} rowData
 */
const updateCurrentForm = (
  state,
  {formID, items, groups, rowsPerPage, parentFormID, selectedRowKeys, rowData, formStatus}
) => {
  let options = {};

  if (parentFormID) {
    options.parentFormID = parentFormID;
  }

  if (items) {
    options.items = items;
  }

  if (groups) {
    options.groups = groups;
  }

  if (rowsPerPage) {
    options.rowsPerPage = rowsPerPage;
  }

  if (selectedRowKeys) {
    options.selectedRowKeys = selectedRowKeys;
  }

  if (rowData) {
    options.selectedRows = rowData;
  }

  if (formStatus) {
    options.formStatus = formStatus;
  }

  return updateForm(state, formID, options, true);
};

const updateCurrentForms = (state, payload) => {
  return payload.reduce((aState, current) => {
    let {formID, options} = current;
    return updateForm(aState, formID, options);
  }, state);
};

/**
 * @param {string|number} formID
 * @param {string} key
 * @param {'setIn' | 'mergeIn'} [mergeOperation]
 * @param value
 * @param {Map} state
 */

const createOrUpdate = ({state, formID, key, value, mergeOperation = 'setIn'}) => {
  if (formID == null || formID === '') return state;

  if (state.get(String(formID))) {
    return state[mergeOperation]([String(formID), key], value);
  }

  return state.set(String(formID), {...getSpecificForm(), [key]: value});
};

/**
 * @param {string} formID
 * @param {{}|[]} dataSource
 * @param {Map} state
 */
function setDS(state, formID, dataSource) {
  return updateForm(state, formID, {dataSource}, true);
}

/**
 * @param {Map} state
 * @param {string} formID
 * @param {DataWorker} dataWorker
 */
function setDataWorker(state, formID, dataWorker) {
  return updateForm(state, formID, {dataWorker}, true);
}

/**
 * @param {string|number} formID
 * @param {number} toolBarViewType
 * @param {Map} state
 */
function setToolBarViewType(state, formID, toolBarViewType) {
  return updateForm(state, formID, {toolBarViewType}, true);
}

/**
 * @param {string|number} formID
 * @param {{}|[]} events
 * @param {Map} state
 */
function setEvents(state, formID, events) {
  return updateForm(state, formID, {events});
}

/**
 * @param {string} formKey
 * @param {Map} state
 */
function updateTableLoaded(formKey, state) {
  let [formID, parentID] = formKey.split('/');
  if (parentID == null) {
    parentID = formID;
  }

  let curForm = state.get(parentID);

  if (!curForm) return state;

  let changed = false;

  const newItems = curForm.items.map(item => {
    const {formID, parentFormID, loaded} = item.options;
    if (item.itemType !== ELayoutType.TABLE || getFormKey(formID, parentFormID) !== formKey || loaded) return item;
    changed = true;
    return {
      ...item,
      options: {
        ...item.options,
        loaded: true
      }
    };
  });
  return changed ? state.setIn([parentID, 'items'], newItems) : state;
}

/**
 * @param {Map} state
 * @param {number} groupID
 * @param {string} formID
 * @param {boolean} opened
 * @param {number} parentID
 * @param {boolean} IsOnlyOneExpandedItem
 */
function updateAccordion(state, {groupID, formID, opened, parentID, IsOnlyOneExpandedItem}) {
  const key = String(formID);
  const form = state.get(key);
  /**
   * @param <ILayoutItem<IGroupOptions>> group
   */
  const groups = form.groups.map(group => {
    if (groupID !== group.id) {
      if (IsOnlyOneExpandedItem && group.parentID === parentID) {
        return {
          ...group,
          options: {
            ...group.options,
            isCollapsed: true
          }
        };
      }
      return group;
    }
    return {
      ...group,
      options: {
        ...group.options,
        isCollapsed: !opened
      }
    };
  });

  return state.setIn([key, 'groups'], groups);
}

/**
 * @param {Object} arg
 * @param {Map} arg.state
 * @param {string} arg.formID
 * @param {ELayoutType} arg.itemType
 * @param {string} arg.name
 * @param {*} arg.options
 */
const updateItemOptions = ({state, formID, itemType, name, options}) => {
  const curForm = state.get(formID);
  let changed = false;
  const newItems = curForm.items.map(item => {
    if (item.itemType === itemType && item.name === name) {
      changed = true;
      return {
        ...item,
        options: {
          ...item.options,
          ...options
        }
      };
    }
    return item;
  });
  return changed ? state.setIn([formID, 'items'], newItems) : state;
};

/**
 * @param {Map} state
 * @param {number} groupID
 * @param {string} formID
 */
function activateTab(state, {groupID, formID}) {
  const form = state.get(formID);
  /**
   * @param <ILayoutItem<IGroupOptions>> activeGroup
   */
  const activeGroup = form.groups.find(gr => gr.id === groupID);
  if (!activeGroup) return state;

  /**
   * @param <ILayoutItem<IGroupOptions>> group
   */
  const groups = form.groups.map(group => {
    if (activeGroup.parentID !== group.parentID) {
      return group;
    }
    return {
      ...group,
      options: {
        ...group.options,
        isActive: activeGroup.id === group.id
      }
    };
  });

  return state.setIn([formID, 'groups'], groups);
}

/**
 * @param {Map<string, CurrentFormState>} state
 * @param {string} fomKey
 * @returns {Map<string, CurrentFormState>}
 */
function closeForm(state, fomKey) {
  const form = state.get('' + fomKey);
  form?.dataWorker?.terminate();
  return state.delete('' + fomKey);
}

function closeForms(state, ids) {
  return state.reduce((currState, formState) => {
    if (ids.some(id => String(id) === formState.actionFormId)) return closeForm(currState, formState.actionFormId);
    return currState;
  }, state);
}

/**
 * @param {Map<string, CurrentFormState>} state
 * @returns {Map<string, CurrentFormState>}
 */
function clear(state) {
  state.forEach(form => {
    form.dataWorker?.terminate();
  });
  return state.clear();
}

function readOnlyForm(state, formID, readOnly) {
  const key = formID;
  const form = state.get(key);
  const items = form.items.map(item => {
    return {
      ...item,
      isReadOnlyForm: readOnly
    };
  });
  state = state.setIn([key, 'items'], items);
  state = state.setIn([key, 'readOnly'], readOnly);
  return state;
}

/**
 * @param {Map<string, CurrentFormState>} state
 * @param type
 * @param payload
 * @returns {Map<string, CurrentFormState>}
 */
export const currentFormsReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case actionTypes.MOD_FORM_SUCCESS:
      let nextState = state;
      const form = state.get(String(payload.formID));

      form.items.forEach(formItem => {
        if (formItem.itemType === 'subform') {
          const subformID = String(formItem.options.formID);
          const isNested = !!formItem.options.nestedFieldName;

          if (isNested) {
            nextState = createOrUpdate({state: nextState, formID: subformID, key: 'isModified', value: false});
          }
        }
      });

      return createOrUpdate({state: nextState, formID: payload.formID, key: 'isModified', value: false});
    case actionTypes.GET_FORM_SUCCESS:
      return updateForm(state, payload.actionFormId, {formData: payload[payload.actionFormId].formData}, true);
    case actionTypes.EDIT_FIELD:
      return updateForm(state, payload.formID, {formData: payload.formData});
    case actionTypes.READ_ONLY:
      return readOnlyForm(state, payload.formID, payload.readOnly);
    case modalTypes.INIT_SUBFORM:
    case modalTypes.OPEN: {
      //setting init values for form and then apply payload
      const prevForm = state.get(String(payload.actionFormId)) || {};
      const specificForm = getSpecificForm();

      const newState = {
        formData: {},
        ...specificForm,
        dataSource: prevForm.dataSource || specificForm.dataSource,
        selectedRowKeys: prevForm.selectedRowKeys || specificForm.selectedRowKeys,
        selectedRows: prevForm.selectedRows || specificForm.selectedRows,
        events: prevForm.events || specificForm.events,
        userData: prevForm.userData,
        items: prevForm.items || [],
        groups: prevForm.groups || [],
        ...payload
      };

      return state.set(String(payload.actionFormId), newState);
    }
    case actionTypes.UPDATE_CURRENT_FORM:
      return updateForm(state, payload.formID, payload.options);
    case actionTypes.SET_FORM_ITEMS:
    case actionTypes.ROW_SELECTED:
    case actionTypes.SET_ROWS_PER_PAGE: {
      return updateCurrentForm(state, payload);
    }
    case actionTypes.INLINE_EDITING_START: {
      return updateForm(state, payload.formID, {editableRowData: payload.rowData}, true);
    }
    case actionTypes.INLINE_EDITING_ROW_UPDATE: {
      return updateForm(state, payload.formID, {editableRowData: payload.rowData});
    }
    case actionTypes.MODIFYING: {
      return updateForm(state, payload.formID, {isModifying: payload.start});
    }
    case actionTypes.INLINE_EDITING_END:
      return updateForm(state, payload.formID, {editableRowData: null}, true);
    case actionTypes.SET_INITIAL_FORM_DATA:
      return updateForm(state, payload.formID, {initialFormData: payload.initialData}, true);
    case actionTypes.SET_USER_DATA:
      return updateForm(state, payload.formID, {userData: payload.userData}, true);
    case actionTypes.SET_TB_VIEW_TYPE:
      return setToolBarViewType(state, payload.formID, payload.type);
    case actionTypes.OPEN_ACCORDION:
      return updateAccordion(state, payload);
    case actionTypes.ACTIVATE_TAB:
      return activateTab(state, payload);
    case actionTypes.SET_DATASOURCE:
      return setDS(state, payload.formID, payload.dataSource);
    case actionTypes.SET_DATA_WORKER:
      return setDataWorker(state, payload.formID, payload.dataWorker);
    case actionTypes.SET_ITEM_OPTIONS:
      return updateItemOptions({state: state, ...payload});
    case actionTypes.SET_EVENTS:
      return setEvents(state, payload.formID, payload.events);
    case actionTypes.SET_FOCUSED_CELL:
      return updateForm(state, payload.formID, {focusOn: payload}, true);
    case actionTypes.UPDATE_CURRENT_SCREEN:
      return updateCurrentForms(state, payload);
    case modalTypes.CLOSE:
      return closeForm(state, payload);
    case modalTypes.CLOSE_FORMS:
      return closeForms(state, payload.ids);
    case modalTypes.CLEAR_CURRENT_FORMS:
      return clear(state);
    case loadingActions.LOADER_END:
      return updateTableLoaded(payload.formKey, state);
    default:
      return state;
  }
};
