import {HashRequestBody, PAGES_PREDICT_DEFAULT} from './consts';
import {PAGINATION_TYPE} from 'services/interfaces/global-interfaces';
import PaginationTypeUtil from 'utilsOld/systemObjects/PaginationType';
import {FormField, SysFormFieldCollection, SysFormWrapper} from 'utilsOld/systemObjects';
import {getRequestColumns} from 'utilsOld/getRequestColumns';
import {FilterStateObject} from 'services/filters/types';
import {formatToAPI} from 'services/SecondaryMethods/filterUtils';
import CacheDataSource from './CacheDataSource';
import {SysFormFields} from 'services/interfaces/sysObjects';
import {getSysDisplayField, getSysField} from 'utilsOld/sysFormUtils';

export default class FormDataSource extends CacheDataSource {

  protected sysForm: SysFormWrapper;
  private _paginationType: PAGINATION_TYPE;
  protected _fixedOrder: boolean;
  private _fixedOrderField: string | null;
  private _storeFilters: Record<string, FilterStateObject> = {};
  private _paginationPage: number;
  protected keyField: string;
  protected systemKeyField: string;


  constructor(sysForm: SysFormWrapper) {
    super({
      requestColumns: getRequestColumns(sysForm.formFields, sysForm.keyField),
      objectName: sysForm.object,
    });

    this.sysForm = sysForm;
    this.keyField = this.sysForm.keyField;
    this.systemKeyField = getSysField(this.keyField);
    this._paginationType = this.sysForm.paginationType.toNumber();
    this._fixedOrder = this.sysForm.fixedOrder;
    this._fixedOrderField = this.sysForm.type.isList() ? this.sysForm.fixedOrderField : null;
    this.config.pagePredict = this.getPagePredict();
    this._paginationPage = 1;
  }

  /**опция для того, чтобы определить, существует ли N страниц с записями впереди. смотреть в вики*/
  protected getPagePredict() {
    const paginationTypePageSelector = PaginationTypeUtil.isPageSelector(this.paginationType);
    return paginationTypePageSelector ? PAGES_PREDICT_DEFAULT : 1;
  }

  protected prepareFilter(_parentIDs?: any) {
    return formatToAPI({
      storeFilters: this.storeFilters,
      sysForm: this.sysForm.asRaw(),
    });
  }

  /**
 * Return's example
 *
 * ['Name']
 * ['-Name']
 *
 * @returns {array}
 */
  protected prepareSortData(sort: {selector: string; desc: boolean}[] | undefined) {
    if (this._fixedOrderField && this._fixedOrder) {
      return [this._fixedOrderField];
    }

    if (!Array.isArray(sort)) {
      return [];
    }
    const mappedFields = new SysFormFieldCollection(this.sysForm.formFields).toMap();

    function getFieldBySelector(selector: string): SysFormFields {
      const dataField = selector.replace(getSysDisplayField(''), '');
      return mappedFields[dataField];
    }

    return sort
      .filter(sortItem => {
        const field = getFieldBySelector(sortItem.selector);
        return sortItem.selector !== this.systemKeyField && field;
      })
      .map(item => {
        const field = getFieldBySelector(item.selector);
        const formField = new FormField(field);

        const selector = formField.isLookup() ? formField.lookupDisplayField : formField.objectFieldIDName;
        return item.desc ? `-${selector}` : selector;
      });
  }

  public loadWithStoreFilters(
    storeFilter: Record<string, FilterStateObject>,
    apiFilter: Record<string, any> = {},
    aSort?: {selector: string; desc: boolean}[],
    itemsPerPage?: number
  ){
    this.storeFilters = storeFilter;
    const sort = this.prepareSortData(aSort);

    if (itemsPerPage === -1 || !itemsPerPage) {
      this.paginationPage = -1;
    }

    const data: HashRequestBody = {
      Page: this.paginationPage,
      Filters: {
        ...this.prepareFilter(),
        ...apiFilter
      },
    };

    if (sort.length) {
      data.Sorts = sort;
    }

    return this.loadData(data, itemsPerPage).then(({data, pagesPredict}) => {
      this.paginationPage = pagesPredict;
      return data;
    });
  }

  get paginationType() {
    return this._paginationType;
  }

  set paginationType(paginationType: PAGINATION_TYPE) {
    this._paginationType = paginationType;
  }

  get storeFilters(): Record<string, FilterStateObject> {
    return this._storeFilters;
  }

  set storeFilters(value: Record<string, FilterStateObject>) {
    this._storeFilters = value;
  }

  get paginationPage(): number {
    return this._paginationPage;
  }

  set paginationPage(value: number) {
    this._paginationPage = value;
  }
}
