export enum actionTypes {
  SET_FORM_ITEMS = 'form/SET_FORM_ITEMS',
  SET_DATASOURCE = 'form/SET_DATASOURCE',
  SET_DATA_WORKER = 'form/SET_DATA_WORKER',
  SET_EVENTS = 'form/SET_EVENTS',
  SET_TB_VIEW_TYPE = 'form/SET_TB_VIEW_TYPE',
  SET_ROWS_PER_PAGE = 'form/SET_ROWS_PER_PAGE',
  ON_INIT = 'form/ON_INIT',
  ON_SHOW = 'form/ON_SHOW',
  ON_SAVE = 'form/ON_SAVE',
  ON_CLOSE = 'form/ON_CLOSE',
  ON_FILTER_APPLY = 'form/ON_FILTER_APPLY',
  ON_ROW_UPDATED = 'form/ON_ROW_UPDATED',
  ON_DATA_LOADED = 'form/ON_DATA_LOADED',
  ON_ROW_INSERTED = 'form/ON_ROW_INSERTED',
  ON_ROW_DELETED = 'form/ON_ROW_DELETED',
  ON_APPLY = 'form/ON_APPLY',
  ON_FIELD_VALIDATION = 'form/ON_FIELD_VALIDATION',
  ON_SUMMARY_CALC = 'form/ON_SUMMARY_CALC',

  ON_ROW_INSERTING = 'form/ON_ROW_INSERTING',
  ON_ROW_EDITING = 'form/ON_ROW_EDITING',
  ON_ROW_DELETING = 'form/ON_ROW_DELETING',
  ON_BEFORE_ROW_EDITING = 'form/ON_BEFORE_ROW_EDITING',
  ON_BEFORE_ROW_INSERTING = 'form/ON_BEFORE_ROW_INSERTING',

  INLINE_EDITING_START = 'form/INLINE_EDITING_START',
  INLINE_EDITING_ROW_UPDATE = 'form/INLINE_EDITING_ROW_UPDATE',
  INLINE_EDITING_END = 'form/INLINE_EDITING_END',

  ON_CUSTOMIZE_ROW = 'form/ON_CUSTOMIZE_ROW',
  ON_CUSTOMIZE_CELL = 'form/ON_CUSTOMIZE_CELL',
  ON_CUSTOMIZE_CELL_FORM = 'form/ON_CUSTOMIZE_CELL_FORM',
  ON_FIELD_VALUE_CHANGED = 'form/ON_FIELD_VALUE_CHANGED',
  ON_INIT_LOOKUP_FIELD = 'form/ON_INIT_LOOKUP_FIELD',
  ON_INIT_LOOKUP_FILTER = 'form/ON_INIT_LOOKUP_FILTER',
  ON_KEY_DOWN = 'form/ON_KEY_DOWN',
  ON_BUTTON_CLICKED = 'form/ON_BUTTON_CLICKED',
  ON_FILTER_VALUE_CHANGED = 'form/ON_FILTER_VALUE_CHANGED',
  ON_SELECTION_CHANGED = 'form/ON_SELECTION_CHANGED',
  ON_FOCUSED_CELL_CHANGED = 'form/ON_FOCUSED_CELL_CHANGED',
  ON_CELL_CLICK = 'form/ON_CELL_CLICK',
  ON_CELL_DOUBLE_CLICK = 'form/ON_CELL_DOUBLE_CLICK',
  ROW_SELECTED = 'form/ROW_SELECTED',

  SET_USER_DATA = 'form/SET_USER_DATA',
  OPEN_ACCORDION = 'form/OPEN_ACCORDION',
  SET_INITIAL_FORM_DATA = 'form/setInitialFormData',
  SET_ITEM_OPTIONS = 'form/setItemOptions',
  ACTIVATE_TAB = 'form/ACTIVATE_TAB',
  MODIFYING = 'form/MODIFYING',
  READ_ONLY = 'form/READ_ONLY',
  UPDATE_CURRENT_FORM = 'form/UPDATE_CURRENT_FORM',
  UPDATE_CURRENT_SCREEN = 'form/UPDATE_CURRENT_SCREEN',

  EDIT_FIELD = 'form/EDIT_FIELD',
  SET_FOCUSED_CELL = 'form/SET_FOCUSED_CELL',

  GET_FORM_REQUEST = 'form/GET_FORM_REQUEST',
  GET_FORM_SUCCESS = 'form/GET_FORM_SUCCESS',
  GET_FORM_ERROR = 'form/GET_FORM_ERROR',

  MOD_FORM_REQUEST = 'form/MOD_FORM_REQUEST',
  MOD_FORM_SUCCESS = 'form/MOD_FORM_SUCCESS',
  MOD_FORM_ERROR = 'form/MOD_FORM_ERROR',

  ON_CUSTOMIZE_ROW_EDITOR = 'form/ON_CUSTOMIZE_ROW_EDITOR',

  ON_CARD_CLICK = 'form/ON_CARD_CLICK',
  ON_CARD_DOUBLE_CLICK = 'form/ON_CARD_DOUBLE_CLICK',
  ON_CARD_DRAG_DROP_START = 'form/ON_CARD_DRAG_DROP_START',
  ON_CARD_DRAG_DROP_FINISH = 'form/ON_CARD_DRAG_DROP_FINISH',

  ON_DIAGRAM_ITEM_CLICK = 'form/ON_DIAGRAM_ITEM_CLICK',
  ON_DIAGRAM_ITEM_DOUBLE_CLICK = 'form/ON_DIAGRAM_ITEM_DOUBLE_CLICK',
  ON_DIAGRAM_SELECTION_CHANGED = 'form/ON_DIAGRAM_SELECTION_CHANGED',

  ON_DECORATION_ELEMENT_CLICK = 'form/ON_DECORATION_ELEMENT_CLICK',
  ON_DECORATION_ELEMENT_DOUBLE_CLICK = 'form/ON_DECORATION_ELEMENT_DOUBLE_CLICK',

  ON_APPOINTMENT_ELEMENT_CLICK = 'form/ON_APPOINTMENT_ELEMENT_CLICK',
  ON_APPOINTMENT_ELEMENT_DOUBLE_CLICK = 'form/ON_APPOINTMENT_ELEMENT_DOUBLE_CLICK',

  ON_GROUP_BUTTON_CLICK = 'form/ON_GROUP_BUTTON_CLICK',

  ON_ITEM_SWIPE = 'form/ON_SWIPE_ITEM'
}
