import {RootState} from '../../store';

export const mainDrawerOpenedSelector = (state: RootState) => state.overlay.mainDrawerOpened;
/**
 * @param state
 * @returns {LanguageCode}
 */
export const regionalSettings = (state: RootState) => state.overlay.regionalSettings;
export const currentFormSelector = (state: RootState) => state.overlay.currentFormID;
export const globalLoaderSelector = (state: RootState) => state.overlay.globalLoaderIsVisible;
export const editorStylingMode = () => 'outlined';

export const currentFormID = (state: RootState) => state.overlay.currentFormID;
export const currentSubsystemID = (state: RootState) => state.overlay.currentSubsystemID;
export const currenDirSelector = (state: RootState) => state.overlay.currenDir;
export const servicePrefixSelector = (state: RootState) => state.overlay.servicePrefix;
export const userInteractionsSelector = (state: RootState) => state.overlay.userInteractionsDisabled;
export const altColorSelector = (state: RootState) => state.overlay.altColor;
export const horizSeparLinesSelector = (state: RootState) => state.overlay.horizSeparLines;
export const vertSeparLinesSelector = (state: RootState) => state.overlay.vertSeparLines;
export const languagesSelector = (state: RootState) => state.overlay.languages;
