import {Column} from 'components/TableCoreOld/columns';
import {Messages} from 'services/lang/messages';

import {SVGToString} from 'constantsOld/renderSVGToString';

const {DISABLED_CHECKBOX, ACTIVE_CHECKBOX, DISABLED_SWITCH, ACTIVE_SWITCH} = SVGToString;

// элементы типа чекбокс для таблицы и для дерева
const checkBoxCell = (cellElement, {value,column}) => {

  const div = document.createElement('div');
  column.alignment ? div.classList.add('svg-boolean-column-alignment-checkbox') : div.classList.add('svg-boolean-column-checkbox');

  if (value) {
    div.classList.add('dx-checkbox-checked');
    div.setAttribute('aria-checked', 'true');
    div.insertAdjacentHTML('afterbegin', ACTIVE_CHECKBOX);
  } else {
    div.setAttribute('aria-checked', 'false');
    div.insertAdjacentHTML('afterbegin', DISABLED_CHECKBOX);
  }

  cellElement.appendChild(div);
};

// элементы типа свитч для таблицы и для дерева
const switchCell = (cellElement, {value}) => {
  const div = document.createElement('div');

  if (value) {
    div.classList.add('dx-switch-on-value');
    div.setAttribute('aria-checked', 'true');
    div.insertAdjacentHTML('afterbegin', ACTIVE_SWITCH);
  } else {
    div.setAttribute('aria-checked', 'false');
    div.insertAdjacentHTML('afterbegin', DISABLED_SWITCH);
  }
  div.classList.add('column-switch-wrapper');
  div.classList.add('state-readonly');

  cellElement.appendChild(div);
};

const getButtonGroup = ({pressed, text, containerClasses}) => {
  const buttonContainer = document.createElement('div');
  buttonContainer.className = containerClasses;
  if (pressed) {
    buttonContainer.classList.add('dx-item-selected')
  }
  buttonContainer.setAttribute('role', "button");
  buttonContainer.setAttribute('aria-label', text);
  buttonContainer.setAttribute('aria-pressed', pressed);
  const button = document.createElement('div');
  button.classList.add('dx-button-content');
  const textContainer = document.createElement('span');
  textContainer.classList.add('dx-button-text');
  textContainer.innerText = text;
  button.appendChild(textContainer);
  buttonContainer.appendChild(button);
  return buttonContainer;
}

const boolSelectorCell = (cellElement, {value}) => {
  const div = document.createElement('div');
  div.className = 'dx-buttongroup-wrapper dx-widget dx-collection';

  div.appendChild(getButtonGroup({
    pressed: !!value,
    text: Messages.Controls.Yes,
    containerClasses: 'dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-first-item dx-shape-standard'
  }));

  div.appendChild(getButtonGroup({
    pressed: !value,
    text: Messages.Controls.No,
    containerClasses: 'dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-last-item dx-shape-standard'
  }));

  cellElement.appendChild(div);
  cellElement.style.paddingTop = '4px';
  cellElement.style.paddingBottom= '4px';
};

const dataSource = [
  {
    value: 1,
    text: Messages.Controls.Yes,
  },
  {
    value: 0,
    text: Messages.Controls.No,
  },
];

export default class BoolColumn extends Column {
  prop() {
    const boolSelectorAddon = this.field.isBooleanSelector() ? {
      editCellTemplate: 'boolean-column-editor-template',
      cellTemplate: boolSelectorCell,
    } : {};
    return {
      ...super.prop(),
      cellTemplate: this.field.isSwitch() ? switchCell : checkBoxCell,
      dataType: 'boolean',
      headerFilter: {
        search: {enabled: false},
        dataSource,
      },
      calculateCellValue: rowData => !!rowData[this.field.name],
      format: this.field.getFieldFormat(),
      ...boolSelectorAddon,
    };
  }
}
