import {actionTypes} from './actionTypes';
import {
  currentFormStateSelector,
  makeAllRelatedCurrentFormsSelector,
  makeGroupsSelector,
  makeParentFormSelector
} from './selectors';
import {CurrentFormState} from './types';
import {ELayoutType, IGroupOptions, ILayoutItem, LayoutGroups} from '../SecondaryMethods/formItems/itemInterfaces';
import DataWorker, {TableDataWorkerResponse} from '../../DataWorker';
import {RootState} from '../../store';
import {createAction} from '@reduxjs/toolkit';

export const getForm = {
  request: createAction(actionTypes.GET_FORM_REQUEST),
  success: createAction<any>(actionTypes.GET_FORM_SUCCESS),
  error: createAction<any>(actionTypes.GET_FORM_ERROR)
};

export const modForm = {
  request: createAction(actionTypes.MOD_FORM_REQUEST),
  success: createAction<any>(actionTypes.MOD_FORM_SUCCESS),
  error: createAction<any>(actionTypes.MOD_FORM_ERROR)
};

export const edit = {
  field: createAction<{formData: Record<string, any>; formID: string}>(actionTypes.EDIT_FIELD)
};

export const updateCurrentForm = createAction<{options: Omit<Partial<CurrentFormState>, 'formID'>; formID: string}>(
  actionTypes.UPDATE_CURRENT_FORM
);
export const updateCurrentScreen = createAction<{options: Omit<Partial<CurrentFormState>, 'formID'>; formID: string}[]>(
  actionTypes.UPDATE_CURRENT_SCREEN
);
export const openAccordion = createAction<{
  groupID: number;
  opened: boolean;
  formID: string;
  parentID: number | null;
  IsOnlyOneExpandedItem: boolean;
}>(actionTypes.OPEN_ACCORDION);
export const activateTab = createAction<{groupID: number; formID: string}>(actionTypes.ACTIVATE_TAB);
export const formModifying = createAction<{start: boolean; formID: string}>(actionTypes.MODIFYING);
export const formReadOnly = createAction<{readOnly: boolean; formID: string}>(actionTypes.READ_ONLY);

export const currentFormActions = {
  inlineEditingStart: createAction<{rowData: any; formID: string}>(actionTypes.INLINE_EDITING_START),
  updateInlineEditingRow: createAction<{rowData: any; formID: string}>(actionTypes.INLINE_EDITING_ROW_UPDATE),
  inlineEditingEnd: createAction<{formID: string}>(actionTypes.INLINE_EDITING_END),
  selectRow: createAction<{formID: string; rowData: Record<string, any>; selectedRowKeys: number[]}>(
    actionTypes.ROW_SELECTED
  ),
  setRowsPerPage: createAction<{rowsPerPage: number | 'all'; formID: string}>(actionTypes.SET_ROWS_PER_PAGE),
  setDataSource: createAction<{dataSource: any; formID: string}>(actionTypes.SET_DATASOURCE),
  setDataWorker: createAction<{dataWorker: DataWorker<TableDataWorkerResponse>; formID: string}>(
    actionTypes.SET_DATA_WORKER
  ),
  setFormEvents: createAction<{events: Record<string, any>; formID: string}>(actionTypes.SET_EVENTS),
  setItems: createAction<{
    groups?: ILayoutItem<IGroupOptions>[];
    items?: ILayoutItem<any>[];
    rowsPerPage?: number | 'all';
    parentFormID?: string;
    formID: string;
  }>(actionTypes.SET_FORM_ITEMS),
  setInitialFormData: createAction<{formID: string; initialData: Record<string, any>}>(
    actionTypes.SET_INITIAL_FORM_DATA
  )
};

export const setItemOptions = createAction<{
  formID: string;
  itemType: ELayoutType;
  name: string;
  options: Record<string, any>;
}>(actionTypes.SET_ITEM_OPTIONS);

export const setDiagramExportEvent =
  ({formID, name, fn}: {formID: string; name: string, fn: () => Promise<string | null>}) =>
    (dispatch: any) => {
      dispatch(setItemOptions({
        itemType: ELayoutType.FORM_FIELD,
        formID, name, options: {
          exportAsSVG: fn
        }
      }))
    };

export const toggleAllRelatedWorkers =
  ({formKey, stop}: {formKey: string; stop: boolean}) =>
  (_: any, getState: () => RootState) => {
    const state = getState();
    const currentFormState = currentFormStateSelector(state);
    const currentForm = currentFormState.find(form => form.formKey === formKey);
    if (!currentForm) return;

    const parentForm = makeParentFormSelector(currentForm.actionFormId!)(state);
    const parentFormID = parentForm && currentForm.isSubForm ? parentForm.actionFormId : currentForm.actionFormId;

    const allCurrentForms = makeAllRelatedCurrentFormsSelector()(state, parentFormID!);

    allCurrentForms.forEach(f => {
      if (!f.dataWorker?.active) {
        return f.dataWorker?.clearPause();
      }
      f.dataWorker.paused = stop;
      if (!stop) f.dataWorker?.startAutoRefresh();
    });
  };

export const mergeGroups = (formID: string, newGroups: LayoutGroups) => (dispatch: any, getState: () => RootState) => {
  const groups = makeGroupsSelector()(getState(), formID);
  groups &&
    dispatch(
      updateCurrentForm({
        formID,
        options: {
          groups: groups.map(group => {
            const newGroup = newGroups.find(t => t.id === group.id);
            if (!newGroup) return group;
            return newGroup;
          })
        }
      })
    );
};
