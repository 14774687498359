import {SysForm, SysFormFilterFields, SysFormGroup} from 'services/interfaces/sysObjects';
import {DEFAULT_APPLICATION_NAME, fields} from 'services/objects';
import {DefaultTreeExpand, EditMode, PaginationType, RowSelectionMode, SysFormType} from './sysFormUtils';
import {
  DEFAULT_MODAL_SIZE,
  ListViewItemMenuMode,
  ShowScrollbarMode,
  TileViewDirection,
  TOOLBAR_VIEW_TYPE,
  TOTAL_ROW_KIND
} from '../../services/interfaces/global-interfaces';
import FormField from './FormField';
import {FirstDayOfWeek} from 'devextreme/common';
import {SysFormSchedulerViews} from '../../services/interfaces/sysObjects/SysFormSchedulerViews';
import {SysFormSchedulerDayOff} from '../../services/interfaces/sysObjects/SysFormSchedulerDayOff';
import {SysFormSchedulerResources} from '../../services/interfaces/sysObjects/SysFormSchedulerResources';
import {isEmptyValue} from '../../services/SecondaryMethods/typeUtils';

export class SysFormWrapper {
  private readonly _sysForm: SysForm;
  private readonly _editMode: EditMode;
  private readonly _selectionMode: RowSelectionMode;
  private readonly _paginationType: PaginationType;
  private readonly _defaultTreeExpand: DefaultTreeExpand;

  constructor(sysForm: SysForm) {
    this._sysForm = sysForm;
    this._editMode = new EditMode(sysForm.EditMode);
    this._selectionMode = new RowSelectionMode(sysForm.RowSelectionMode);
    this._paginationType = new PaginationType(sysForm.PaginationType);
    this._defaultTreeExpand = new DefaultTreeExpand(sysForm.DefaultTreeExpandKind);
  }

  get title(): string {
    return this._sysForm.Title;
  }

  get name(): string {
    return this._sysForm.Name;
  }

  get id(): string {
    return this._sysForm.ID;
  }

  get isFilterOnShow(): boolean {
    return Boolean(this._sysForm[fields.IsFilterOnShow]);
  }

  get object(): string {
    return this._sysForm[fields.ObjectID_Name];
  }

  get application(): string {
    return this._sysForm[fields.Application] ?? DEFAULT_APPLICATION_NAME;
  }

  get events() {
    return this._sysForm.Sys_FormEvents ?? [];
  }

  get objectLinkedName(): string {
    return this._sysForm[fields.ObjectFieldID_LinkedObjectID_Name];
  }

  get KANBAN_GroupDisplayField(): string {
    return this._sysForm[fields.DefaultGroupFieldID_LinkedObjectID_DisplayObjectFieldID_Name];
  }

  get KANBAN_GroupKeyField(): string {
    return this._sysForm[fields.DefaultGroupFieldID_LinkedObjectID_KeyObjectFieldID_Name];
  }

  get KANBAN_GroupObjectName(): string {
    return this._sysForm[fields.DefaultGroupFieldID_LinkedObjectID_Name];
  }

  get KANBAN_GroupName(): string {
    return this._sysForm[fields.DefaultGroupFieldID_Name];
  }

  get SCHEDULER_DescriptionField(): string {
    return this._sysForm[fields.SchedulerPropertiesID_DescriptionFieldID_Name] ?? '';
  }

  get SCHEDULER_DisabledField(): string {
    return this._sysForm[fields.SchedulerPropertiesID_DisabledFieldID_Name] ?? '';
  }

  get SCHEDULER_EndDateField(): string {
    return this._sysForm[fields.SchedulerPropertiesID_EndDateFieldID_Name] ?? '';
  }

  get SCHEDULER_EndDayHour(): number | undefined {
    return this._sysForm[fields.SchedulerPropertiesID_EndDayHour] ?? 24;
  }

  get SCHEDULER_FirstDayOfWeek(): FirstDayOfWeek | undefined {
    return this._sysForm[fields.SchedulerPropertiesID_FirstDayOfWeek];
  }

  get SCHEDULER_IsAllowAppointmentSharing(): boolean {
    return !!this._sysForm[fields.SchedulerPropertiesID_IsAllowAppointmentSharing];
  }

  get SCHEDULER_IsAllowDrag(): boolean {
    return !!this._sysForm[fields.SchedulerPropertiesID_IsAllowDrag];
  }

  get SCHEDULER_IsAllowResizeAppointment(): boolean {
    return !!this._sysForm[fields.SchedulerPropertiesID_IsAllowResizeAppointment];
  }

  get SCHEDULER_IsShowNavigatorToolbar(): boolean {
    const value = this._sysForm[fields.SchedulerPropertiesID_IsShowNavigatorToolbar];
    return isEmptyValue(value) ? true : !!value;
  }

  get SCHEDULER_IsReadOnly(): boolean {
    return !!this._sysForm[fields.SchedulerPropertiesID_IsReadOnly];
  }

  get SCHEDULER_StartDateField(): string {
    return this._sysForm[fields.SchedulerPropertiesID_StartDateFieldID_Name] ?? '';
  }

  get SCHEDULER_StartDayHour(): number | undefined {
    return this._sysForm[fields.SchedulerPropertiesID_StartDayHour] ?? 0;
  }

  get SCHEDULER_TextField(): string {
    return this._sysForm[fields.SchedulerPropertiesID_TextFieldID_Name] || '';
  }

  get SCHEDULER_IsShowTooltip(): boolean {
    return !!this._sysForm[fields.SchedulerPropertiesID_IsShowTooltip];
  }

  get TileView_BaseItemHeight(): number {
    return this._sysForm[fields.TileViewProperties_BaseItemHeight] ?? 144;
  }

  get TileView_BaseItemWidth(): number {
    return this._sysForm[fields.TileViewProperties_BaseItemWidth] ?? 144;
  }

  get TileView_DefaultGroupFieldDisplayName(): string {
    return this._sysForm[fields.TileViewProperties_DefaultGroupFieldDisplayName] || '';
  }

  get TileView_DefaultGroupFieldKeyField(): string {
    return this._sysForm[fields.TileViewProperties_DefaultGroupFieldKeyField] || '';
  }

  get TileView_DefaultGroupFieldName(): string {
    return this._sysForm[fields.TileViewProperties_DefaultGroupFieldName] || '';
  }

  get TileView_DefaultGroupFieldObject(): string {
    return this._sysForm[fields.TileViewProperties_DefaultGroupFieldObject] || '';
  }

  get TileView_Direction() {
    return this._sysForm[fields.TileViewProperties_Direction] ?? TileViewDirection.HORIZONTAL;
  }

  get TileView_GroupStylingMode(): number {
    return this._sysForm[fields.TileViewProperties_GroupStylingMode] ?? 0;
  }

  get TileView_GroupsPerPage(): number {
    return this._sysForm[fields.TileViewProperties_GroupsPerPage] ?? 15;
  }

  get TileView_HeightRatioFieldName(): string {
    return this._sysForm[fields.TileViewProperties_HeightRatioFieldName] || '';
  }

  get TileView_IsUseGroupButton() {
    return this._sysForm[fields.TileViewProperties_IsUseGroupButton] ?? 0;
  }

  get TileView_ItemMargin(): number {
    return this._sysForm[fields.TileViewProperties_ItemMargin] ?? 20;
  }

  get TileView_ShowScrollbarMode() {
    return this._sysForm[fields.TileViewProperties_ShowScrollbarMode] ?? ShowScrollbarMode.onHover;
  }

  get TileView_TilesPerPage(): number {
    return this._sysForm[fields.TileViewProperties_TilesPerPage] ?? 15;
  }

  get TileView_RowsPerGroup(): number {
    return this._sysForm[fields.TileViewProperties_RowsPerGroup] ?? 1;
  }

  get TileView_WidthRatioFieldName(): string {
    return this._sysForm[fields.TileViewProperties_WidthRatioFieldName] || '';
  }

  get type() {
    return new SysFormType(this._sysForm.Type);
  }

  get totalRowKind() {
    return this._sysForm.TotalRowKind ?? TOTAL_ROW_KIND.NONE;
  }

  get paginationType() {
    return this._paginationType;
  }

  get editMode(): EditMode {
    return this._editMode;
  }

  get selectionMode(): RowSelectionMode {
    return this._selectionMode;
  }

  get keyField(): string {
    return this._sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  }

  get keyFieldType(): number {
    return this._sysForm[fields.ObjectID_KeyObjectFieldID_FieldType];
  }

  get fixedOrderField(): string | null {
    return this._sysForm[fields.FixedOrderField];
  }

  get isTreeDragDropAllowed(): boolean {
    return !!this._sysForm[fields.IsTreeDragDropAllowed];
  }

  get columnAutoWidth(): number | null {
    return this._sysForm[fields.IsColumnAutoWidth];
  }

  get fixedColumnWidth(): boolean {
    return !!this._sysForm[fields.IsFixedColumnWidth];
  }

  get defaultTreeExpandKind() {
    return this._defaultTreeExpand;
  }

  get defaultTreeExpandLevel() {
    return this._sysForm.DefaultTreeExpandLevel;
  }

  get isUseAlternativeRowColor(): boolean {
    return !!this._sysForm[fields.IsUseAlternativeRowColor];
  }

  get isShowColumnHeader(): boolean {
    return !!this._sysForm[fields.IsShowColumnHeader];
  }

  get isShowColumnLines(): boolean {
    return !!this._sysForm[fields.IsShowColumnLines];
  }

  get isShowColumnCustomization(): boolean {
    return !!this._sysForm[fields.IsShowColumnCustomization];
  }

  get isShowButtonCustomization(): boolean {
    return !!this._sysForm[fields.IsShowButtonCustomization];
  }

  get isShowSortCustomization(): boolean {
    return this.formFields.some(field => field.IsSortingAllowed === 1) && !this.fixedOrder;
  }

  get isSomeSortingAllowed(): boolean {
    return this.formFields.some(field => field.IsSortingAllowed === 1);
  }

  get isShowRowLines(): boolean {
    return !!this._sysForm[fields.IsShowRowLines];
  }

  get fixedColumnOrder(): boolean {
    return !!this._sysForm[fields.IsFixedColumnOrder];
  }

  get fixedOrder(): boolean {
    return !!this._sysForm[fields.IsFixedOrder];
  }

  get isShowTitle(): boolean {
    return !!this._sysForm[fields.IsShowTitle];
  }

  get isShowBorder(): boolean {
    return !!this._sysForm[fields.IsShowBorder];
  }

  get rowsPerPage() {
    return this._sysForm[fields.RowsPerPage];
  }

  get mainGroupID() {
    return this._sysForm[fields.MainGroupID];
  }

  get formFields() {
    return this._sysForm.Sys_FormFields || [];
  }

  get formFieldInstances() {
    return this.formFields.map(f => new FormField(f));
  }

  get decorationElements() {
    return this._sysForm.Sys_FormDecorationElements || [];
  }

  get buttons() {
    return this._sysForm.Sys_FormButtons || [];
  }

  get groups(): SysFormGroup[] {
    return this._sysForm.Sys_FormGroups || [];
  }

  get subForms() {
    return this._sysForm.Sys_SubForms || [];
  }

  get filterFields(): SysFormFilterFields[] {
    return this._sysForm.Sys_FormFilterFields || [];
  }

  get schedulerViews(): SysFormSchedulerViews[] {
    return this._sysForm.Sys_FormSchedulerViews || [];
  }

  get schedulerDayOffs(): SysFormSchedulerDayOff[] {
    return this._sysForm.Sys_FormSchedulerDayOff || [];
  }

  get schedulerResources(): SysFormSchedulerResources[] {
    return this._sysForm.Sys_FormSchedulerResources || [];
  }

  get defaultModalSize(): DEFAULT_MODAL_SIZE {
    return this._sysForm.DefaultModalSize;
  }

  get isFormBodyScrollable() {
    return !!this._sysForm.IsFormBodyScrollable;
  }

  get fieldTitleDefaultWidth() {
    return this._sysForm.FieldTitleDefaultWidth;
  }

  get docUrl(): string | null {
    return this._sysForm.DocURL;
  }

  get hint(): string | null {
    return this._sysForm.Hint;
  }

  get toolBarViewType(): TOOLBAR_VIEW_TYPE {
    return this._sysForm[fields.Toolbar_View_Type];
  }

  get autoRefreshTimeout() {
    return this._sysForm.AutoRefreshTimeout || 0;
  }

  get isAutoRefresh() {
    return !!this._sysForm.IsAutoRefresh;
  }

  get parentField() {
    return this._sysForm[fields.ParentFieldID_Name];
  }

  get parentFieldType() {
    return this._sysForm[fields.ParentFieldID_Type];
  }

  get rootName() {
    return this._sysForm[fields.RootName];
  }

  get isShowRoot() {
    return !!this._sysForm[fields.IsShowRoot];
  }

  get viewMode() {
    return this._sysForm.ViewMode;
  }

  get rowMaxLineCount() {
    let result = this._sysForm.RowMaxLineCount || 1;
    if (result < 1) result = 1;

    return result;
  }

  get translations() {
    return this._sysForm[fields.Translations];
  }

  get listViewIsMultiselect() {
    return !!this._sysForm[fields.ListViewProperties_IsMultiselect];
  }

  get listViewIsSelectionEnabled() {
    return !!this._sysForm[fields.ListViewProperties_IsSelectionEnabled];
  }

  get listViewItemContentPadding() {
    return this._sysForm[fields.ListViewProperties_ItemContentPadding];
  }

  get listViewItemHeight() {
    return this._sysForm[fields.ListViewProperties_ItemHeight] || 30;
  }

  get listViewItemMargin() {
    return this._sysForm[fields.ListViewProperties_ItemMargin] || 20;
  }

  get listViewItemMenuMode() {
    return this._sysForm[fields.ListViewProperties_ItemMenuMode] || ListViewItemMenuMode.ContextMenu;
  }

  get listViewMaxRowCount() {
    return this._sysForm[fields.ListViewProperties_MaxRowCount] || -1;
  }

  get listViewShowScrollbarMode(): ShowScrollbarMode {
    return this._sysForm[fields.ListViewProperties_ShowScrollbarMode] || ShowScrollbarMode.onHover;
  }

  asRaw() {
    return this._sysForm;
  }
}
