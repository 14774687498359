import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';

/**
 * @param {ItemsState} items
 * @param {GroupsState} groups
 * @param {function} dispatch
 * @param {FormObject[]} subForms
 * @param {string} eventName
 * @param {number} eventType
 * @param {FormObject} parentForm
 * @param {FormObject} processedForm
 */
export const onTableFieldValueChanged = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  name,
  newValue,
  prevValue
}) => {
  const {formData, columns} = processedForm;

  const currField = columns.getField(name);

  return userFunction({
    items,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    name,
    args: [currField, prevValue],
    processedForm,
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
    return newValue;
  });
};
