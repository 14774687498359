import {ClickEvent} from 'devextreme/ui/button';
import {gridConstants} from 'components/Layout/utils/variables';
import {FormButton} from 'utilsOld/systemObjects';
import {
  ButtonColorScheme,
  ButtonCreators,
  ButtonStylingMode,
  ELayoutType,
  IButtonOptions,
  ILayoutItem,
  LayoutButtons
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {getDefaultDisabledState} from 'components/toolbar/tableToolBarItems';
import {BUTTON_POSITION, BUTTON_TYPE, USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {SysFormButton} from 'services/interfaces/sysObjects';
import {exportButton, filterButton, tableSettingsButton} from 'services/SecondaryMethods/buttonUtils/systemButtons';
import {ActionType, DefaultButtonOptions} from '../../../utilsOld/toolbar_buttons';
import {getButtonAction} from '../buttonUtils';
import {getTranslatedTitle, textIconOptionsByTitleType} from './utils';
import {DefaultIcons} from '../../../constantsOld/DefaultIcons';

const {
  MIN_WIDTH: {BUTTON_MIN_WIDTH},
  MIN_HEIGHT: {BUTTON_MIN_HEIGHT}
} = gridConstants;

interface ButtonEvents {
  ID: number;
  EventTypeID: USER_EVENT_TYPE;
  FunctionName: string;
}

interface IGetProps {
  button: FormButton;
  onClick: (e: any, name: string) => any;
  events: ButtonEvents[] | undefined;
}

const getProps = ({button, onClick, events}: IGetProps): ILayoutItem<IButtonOptions> => {
  const clickEvent = (events || []).find(e => {
    return e.EventTypeID === USER_EVENT_TYPE.ON_BUTTON_CLICK;
  });

  const action: ActionType = getButtonAction(button.buttonType);

  //пустой обьект если генерируються кнопки сохраненных фильтров
  const def = DefaultButtonOptions[action] || {};

  const defaultIcon =
    button.buttonType === BUTTON_TYPE.CUSTOM && !button.icon
      ? DefaultIcons.FA_SQUARE_BOLT
      : button.icon || def.iconType;
  const defaultTitle = getTranslatedTitle(button.translations) || button.title || def.text;

  const {icon, title} = textIconOptionsByTitleType({
    icon: defaultIcon,
    title: defaultTitle,
    titleType: button.titleType
  });

  const getColorScheme = () => {
    if (action === ActionType.Add) return ButtonColorScheme.Primary;
    return button.colorScheme ?? ButtonColorScheme.Default;
  };

  const buttonOptions: IButtonOptions = {
    icon,
    defaultIcon,
    title: title,
    defaultTitle: defaultTitle,
    colorScheme: getColorScheme(),
    stylingMode: button.stylingMode ?? ButtonStylingMode.None,

    beginGroup: button.isStartToolbarSection,
    actionFormID: button.actionFormID,
    actionFormName: button.actionFormName,
    actionFormObjectName: button.actionFormObjectName,
    actionFormType: button.actionFormIDType,
    actionFormViewMode: button.actionFormViewMode,
    buttonFillingRules: button.formButtonFillingRules,
    buttonType: button.buttonType,
    customOperation: button.customOperation,
    customParameters: button.customParameters,
    disabled: getDefaultDisabledState(button.buttonType),
    formID: button.formID,
    formIDType: button.actionFormIDType,
    formOpenMode: button.formOpenMode,
    isHideInOtherActions: button.isHideInOtherActions,
    //Если это кнопка с типом 5, то нужно сразу передать в функцию имя кнопки, потому что дальше такой возможности нет
    onClick: [BUTTON_POSITION.EDITOR_BUTTON, BUTTON_POSITION.IN_GROUP].includes(button.position)
      ? (e: MouseEvent, _name: string) => onClick(e, button.name)
      : onClick,
    position: button.position,
    style: button.style,
    hotkey: button.hotkey,
    hint: button.hint,
    text: title,
    titleType: button.titleType,
    clickEventName: clickEvent && clickEvent.FunctionName,
    buttonWidth: button.buttonWidth,
    isEditButtonByDefault: button.isEditButtonByDefault,
    isCustomizable: button.isCustomizable
  };

  return {
    creator: ButtonCreators.FORM,
    groupID: button.groupID,
    id: button.id,
    isReadOnly: false,
    isVisible: button.isVisible,
    cols: button.cols,
    minHeight: BUTTON_MIN_HEIGHT,
    minWidth: BUTTON_MIN_WIDTH,
    itemType: ELayoutType.BUTTON,
    name: button.name,
    className: 'item-margin d-layout-item -button',
    idAttr: `${button.name ? `button-${button.name}` : ''}`,
    options: buttonOptions,
    order: button.order,
    parentID: button.groupID ?? null,
    location: 'after',
    formFieldID: button.formFieldID,
    hint: button.hint,
    docUrl: button.docUrl
  };
};

export const getButtons = (
  buttons: SysFormButton[],
  onButtonClick = (_e: ClickEvent, _name: string) => {}
): ILayoutItem<IButtonOptions>[] => {
  const {EXPORT, TABLE_SETTINGS, FILTER} = BUTTON_TYPE;

  return buttons.reduce((result, sysButton) => {
    const button = new FormButton(sysButton);
    //если это кнопка группа с вложенными системными кнопками - то создаем эти кнопки
    switch (button.buttonType) {
      case EXPORT:
        result.push(...exportButton(onButtonClick, button));
        break;
      case TABLE_SETTINGS:
        result.push(...tableSettingsButton(onButtonClick, button));
        break;
      case FILTER:
        result.push(...filterButton(onButtonClick, button));
        break;
    }

    result.push(getProps({button, onClick: onButtonClick, events: sysButton.Sys_FormButtonEvents}));
    return result;
  }, [] as LayoutButtons);
};
