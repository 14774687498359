import axios from 'axios';
import {APIRequest, APIResponse} from './types';

function getOptions(data: Record<string, any>, url: string, headers: Record<string, any>) {
  return {
    method: 'POST',
    data,
    url,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };
}

export const postWithPromise = ({
  data,
  url,
  headers,
}: {
  data: APIRequest | {Request: Record<string, any[]>};
  headers?: Record<string, any>;
  url: string;
}): Promise<APIResponse> => {
  let options = getOptions(data, url, headers!);

  return (
    // @ts-ignore
    axios(options)
      // @ts-ignore
      .then(({data}): APIResponse => {
        switch (data.ResponseCode) {
          case '000':
          case '200':
            return {
              code: data.ResponseCode,
              message: data.ResponseText,
              responseID: data.ResponseId,
              response: data.Response,
              page: data.Page,
              pagesPredict: data.PagesPredict,
              plainResponse: data,
            };
          case '101':
          case '401':
          case '403':
          case '408':
          case '900':
          case '909':
          case '919':
          case '999':
          default:
            console.error(data.ResponseText);
            return {
              code: data.ResponseCode,
              message: data.ResponseText,
              responseID: data.ResponseId,
              plainResponse: data,
              error: new Error(data.ResponseText),
            };
        }
      })
      .catch((error: any) => {
        return {
          code: error.response.data.ResponseCode || `${error.response.status}`,
          message: error.response.data.ResponseText || `${error.message}`,
          responseID: error.response.data.ResponseId,
          plainResponse: {
            ResponseCode: error.response.data.ResponseCode || `${error.response.status}`,
            ResponseText: error.response.data.ResponseText || `${error.message}`,
            ResponseId: error.response.data.ResponseId,
          },
          error: new Error(error.response.data.ResponseText || `${error.message}`),
        };
      })
  );
};
