import {
  BoolColumn,
  BoolModifiableColumn,
  Column,
  CombinedColumn,
  DateColumn,
  EnumColumn,
  FileColumn,
  IconColumn,
  ImageColumn,
  LinkColumn,
  NumberColumn,
  SelectColumn,
  TextColumn,
  TagsColumn,
} from 'components/TableCoreOld/columns';
import {system} from 'services/objects';

const {
  DX_CONTROLS: {
    dxCheckBox,
    dxSwitch,
    dxNumberBox,
    dxDateBox,
    dxSelectBox,
    dxTextBox,
    dxHtmlEditor,
    dxTextArea,
    dxTagBox,
    dxBooleanSelector,
  },
  DISPLAY_TYPE: {ICON, IMAGE, LINK, FILE, ENUMERATOR},
  FIELD_TYPE: {COMBINED},
} = system;

/**
 * @param {FormField} field
 * @param {EditMode} editMode
 */
export function getColProps(field, editMode) {
  switch (field.displayType) {
    case ICON:
      return IconColumn;
    case IMAGE:
      return ImageColumn;
    case LINK:
      return LinkColumn;
    case FILE:
      return FileColumn;
    case ENUMERATOR:
      return EnumColumn;
    case COMBINED:
      return CombinedColumn;
  }

  switch (field.getFieldType()) {
    case dxBooleanSelector:
    case dxCheckBox:
    case dxSwitch:
      if (editMode.isCheck) return BoolModifiableColumn;
      return BoolColumn;
    case dxNumberBox:
      if (field.isMultiSelectField) {
        return TagsColumn;
      }
      return NumberColumn;
    case dxDateBox:
      return DateColumn;
    case dxSelectBox:
      return SelectColumn;
    case dxTagBox:
      return TagsColumn;
    case dxTextBox:
    case dxHtmlEditor:
    case dxTextArea:
      if (field.isMultiSelectField) {
        return TagsColumn;
      }
      return TextColumn;
    default:
      return Column;
  }
}
