import {system} from 'services/objects';
import {ActionType} from 'utilsOld/toolbar_buttons';

/**
 * есть массив c ActionType которым нужно делать split кнопок (пока это сделано только для ShowModalFilter)
 * @param options {Object}
 * @returns {boolean}
 */
export const isSplitButton = options => {
  if (!('action' in options)) {
    return false;
  }
  const {ShowModalFilter} = ActionType;
  const allowedToSplitButtonsActions = [ShowModalFilter];
  return allowedToSplitButtonsActions.includes(options.action);
};
/**
 * проставляем класс для элементов - которые внутри hidden menu toolbar
 * @param button
 */
export const addHiddenMenuClass = button => {
  const {
    TOOLBAR_BTN_CLASSES: {HIDDEN_MENU_ITEM},
  } = system;

  if ('elementAttr' in button) {
    return (button.elementAttr.class += ` ${HIDDEN_MENU_ITEM}`);
  }

  button.elementAttr = {class: `${HIDDEN_MENU_ITEM}`};
};

//Функция для добавления класса с отоюбражением текущего размера интерфейса
//пока только для стилизации контекстного меню
export const assignRowSizeToContainer = (selector) => {
  const element = document.querySelector(selector);
  // rowSize
  element?.classList.add('row-size-medium');
  return element;
};
