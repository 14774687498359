import {GroupsState} from 'middlewares/userScript/storeStates/groupsState';
import {IGroupOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {BaseGroup} from './public-interfaces';

declare interface D5FormGroupOptions {
  groupName: string;
  formGroups: GroupsState;
}

export class D5FormGroup implements BaseGroup {
  private formGroups: GroupsState;
  private readonly index: number;
  private readonly _name: string;

  constructor(options: D5FormGroupOptions) {
    this.formGroups = options.formGroups;
    this._name = options.groupName;

    this.index = this.formGroups.findIndex(group => group.name === this._name);

    if (this.index === -1) {
      throw new Error(`Group ${this._name} is not found`);
    }
  }

  private getCommonOption(property: keyof ILayoutItem<IGroupOptions>): any {
    return this.formGroups.item(this.index)[property];
  }

  private setCommonOption(property: keyof ILayoutItem<IGroupOptions>, value: any) {
    //@ts-ignore
    this.formGroups.item(this.index)[property] = value;
  }

  private getOption(property: keyof IGroupOptions): any {
    return this.formGroups.item(this.index).options[property];
  }

  private setOption(property: keyof IGroupOptions, value: any) {
    let item = this.formGroups.item(this.index);
    item.options = {
      ...item.options,
      [property]: value,
    };
  }

  get title() {
    return this.getOption('title');
  }

  set title(title: string) {
    this.setOption('title', title);
  }

  get isCollapsed(): boolean {
    return !!this.getOption('isCollapsed');
  }

  set isCollapsed(isCollapsed: boolean) {
    this.setOption('isCollapsed', isCollapsed);
  }

  get isActive(): boolean {
    return !!this.getOption('isActive');
  }

  set isActive(isActive: boolean) {
    const activeIndex = this.formGroups.findIndex(group => group.options.isActive);
    if (activeIndex !== -1 && activeIndex !== this.index) {
      let item = this.formGroups.item(activeIndex);
      item.options = {
        ...item.options,
        isActive: false,
      };
    }
    this.setOption('isActive', isActive);
  }

  get isShowTitle() {
    return !!this.getOption('isShowTitle');
  }

  set isShowTitle(isShowTitle: boolean) {
    this.setOption('isShowTitle', isShowTitle);
  }

  get id(): number {
    return this.getCommonOption('id');
  }

  get isVisible(): boolean {
    return !!this.getCommonOption('isVisible');
  }

  set isVisible(isVisible: boolean) {
    this.setCommonOption('isVisible', isVisible);
  }

  get name(): string {
    return this._name;
  }

  get order(): number {
    return this.getCommonOption('order');
  }

  set order(value: number) {
    this.setCommonOption('order', value);
  }
}
