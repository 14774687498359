import axios from 'axios';
import {fields, system} from 'services/objects';
import {wapiUrl} from 'services/baseUrl';
import {Messages} from 'services/lang/messages';
import {postSysForms} from 'services/requests/operation';
import store from 'store';
import {postWithPromise} from 'services/requests/baseRequests';
import {formatMessage} from './formatMessage';

export const loadObjectData = async ({filter = {}, columns = [], page = -1, objectName = '', params = {}, sorts = []} = {}) => {
  if (!objectName) {
    return [];
  }

  const throwErr = ({message}) => {
    const msg = formatMessage(Messages.Errors.ErrorWhileRequest, [message]);
    throw new Error(msg);
  };

  const {response, error} = await postWithPromise({
    data: {
      Columns: columns,
      Filters: filter,
      Page: page || -1,
      Params: params,
      Sorts: sorts,
    },
    url: `${wapiUrl}/${objectName}/List`,
  });

  error && throwErr(error);

  return response[objectName];
};

export const sendPassed = async (objectName, isPassed, IDs) => {
  if (!objectName) {
    return Promise.resolve([]);
  }

  const requestData = IDs.map(id => ({
    ID: id,
    IsPassed: isPassed,
  }));

  let options = {
    method: 'POST',
    data: {
      Request: {
        [objectName]: requestData,
      },
    },
    url: `${wapiUrl}/${objectName}/Pass`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios(options);
  const {ResponseCode, Response, ResponseText} = response.data;

  if (ResponseCode !== '000') {
    throw new Error(ResponseText);
  }

  return Response && Array.isArray(Response[objectName]) ? Response[objectName][0] : null;
};

/**
 * функция которая возвращает промис с обектом {formID, formName, exactFormName}
 * @param formName {string}
 * @param recordID {number}
 * exactFormName - точное имя формы для запросов
 * formName - имя формы для построения url в lowerCase
 * formID - ID формы
 * @returns {Promise<{formID: *, formName: string, exactFormName: string }>}
 */
export async function getEditFormData(formName, recordID) {
  //эта проверка нужна временно пока поменяют все оставшиеся роуты у тестировщиков susbsytem/id/edit/actionformid/key
  //TODO Routes: после окончания изменений по susbsytem раскоментировать код 133-134 и удалить 136-138
  // const {MASTER_FORM_NAME} = system;
  // const filter = {[MASTER_FORM_NAME]: {'=~': `${formName}`}};
  const {MASTER_FORM_NAME, MASTER_FORM_ID} = system;
  const filterRequestKey = !isNaN(+formName) ? MASTER_FORM_ID : MASTER_FORM_NAME;

  const filterRecordID = recordID ? {RecordID: `${recordID}`} : {};

  const filter = {[filterRequestKey]: {'=~': formName.toLowerCase()}, ...filterRecordID};

  const forms = await store.dispatch(postSysForms({filter, formName}));

  if (forms) {
    //делаем поиск по имени (в роут может передаваться в разном регистре - привожу в lowerCase)
    const form = forms.find(formObject => formObject[fields.Name].toLowerCase() === formName.toLowerCase());
    //если поиск не дал результатов возвращаем Promise.reject
    if (!form) {
      return Promise.reject(Messages.Errors.EmptyRespInGetEditFormDataFunc);
    }
    const {[fields.ID]: formID, [fields.Name]: exactFormName} = form;

    return {
      formID,
      formName: exactFormName.toLowerCase(),
      exactFormName,
    };
  }
  return Promise.reject(Messages.Errors.EmptyRespInGetEditFormDataFunc);
}
