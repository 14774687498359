import React, {useImperativeHandle, useRef} from 'react';
import ContextMenu, {IContextMenuOptions} from 'devextreme-react/context-menu';

interface ContextMenuProps
  extends Pick<IContextMenuOptions, 'target' | 'onHiding' | 'width' | 'items' | 'visible' | 'itemRender'> {
  onHiding: () => void;
  cssClass?: string;
  onItemClick: (e: {itemData?: any}) => void;
}

export type Ref = ContextMenu | null;

const CustomContextMenu = React.forwardRef<Ref, ContextMenuProps>(
  ({itemRender, visible, items, width = 200, target, onHiding, onItemClick, cssClass}, ref) => {
    const inputRef = useRef<ContextMenu>(null);
    useImperativeHandle<Ref, Ref>(ref, () => inputRef.current);

    return (
      <ContextMenu
        ref={inputRef}
        hideOnOutsideClick={e => {
          if (e.which === 3) {
            onHiding && onHiding();
          }
          return true;
        }}
        cssClass={`d5-contextmenu ${cssClass}`}
        target={target}
        onItemClick={onItemClick}
        width={width}
        visible={visible}
        items={items}
        itemRender={itemRender}
        onHiding={onHiding}
      />
    );
  }
);

export default CustomContextMenu;
