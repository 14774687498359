import {D5FormFilterField} from './D5FormFilterField';
import {FiltersState} from '../storeStates/filtersState';
import {itemGetter} from '../utils';
import {D5Button} from './public-interfaces';
import {TOOLBAR_VIEW_TYPE} from 'services/interfaces/global-interfaces';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import Messages from 'services/lang/en';
import {createStoreFilterUtl} from 'utilsOld/routes/routes';
import {D5Form} from './D5Form';
import {D5FormOptions} from '../inner-interfaces';
import {ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';

export class D5FormWithToolbar extends D5Form {
  public readonly filterFields: D5FormFilterField[];
  protected _filters: FiltersState;

  constructor(props: D5FormOptions) {
    super(props);
    const {filters, sysForm} = props;

    this.filterFields = this.initFilters(sysForm, filters);
    this._filters = filters;
  }

  public filterField(name: string) {
    return itemGetter(this.filterFields, (item: any) => item.name === name, ELayoutType.FILTER, name);
  }

  /**
   * Кнопки на форме + кнопки тулбара
   */
  public button(name: string): D5Button | undefined {
    return this.getTableD5Button(name) ?? super.button(name);
  }

  get buttons(): D5Button[] {
    return [...this._buttons, ...this.getTableD5Buttons()];
  }

  get toolBarViewType() {
    return this.currentForm.toolBarViewType;
  }

  set toolBarViewType(toolBarViewType: TOOLBAR_VIEW_TYPE) {
    this.currentForm.toolBarViewType = toolBarViewType;
  }

  public async filterUrl() {
    if (this.parentForm) {
      showErrorNotification(Messages.Errors.ParentFormError);
      throw new Error('Can be invoked only on the parent form');
    }
    return await createStoreFilterUtl(this.sysForm, this._filters.get());
  }
}
