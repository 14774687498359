import {actionTypes} from './actions';
import {system} from '../objects';
import {isEmptyObject} from '../SecondaryMethods/typeUtils';

const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');

const userState = {
  isAuth: !isEmptyObject(currentUser),
  sid: currentUser.sid || '',
  Name: currentUser.Name || '',
  PersonID: currentUser.PersonID || null,
  UserID: currentUser.UserID || null,
  IsConfigurator: false,
  IsAdmin: false,
};

export const userReducer = (state = userState, {type, payload}) => {
  switch (type) {
    case actionTypes.SIGN_UP_SUCCESS:
      return {...state};
    case actionTypes.SIGN_IN_SUCCESS:
      return {...state, isAuth: true, ...payload};
    case actionTypes.SIGN_OUT_SUCCESS:
      return {
        isAuth: false,
      };
    default:
      return state;
  }
};
