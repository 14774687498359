import {fields} from 'services/objects';
import BaseField from './BaseField';
import {locateOnGrid} from '../gridUtils';
import {FILTER_OPERATIONS_ID} from '../../services/interfaces/global-interfaces';

export default class FilterField extends BaseField {
  getLinkedName() {
    return this._field.LookupObjectName || this._field[fields.LinkedObjectID_Name];
  }

  getKeyObjFieldName() {
    return this._field.LookupKeyFieldName || this._field[fields.LinkedObjectID_KeyObjectFieldID_Name];
  }

  get fieldType() {
    return this._field[fields.LinkedObjectID_DisplayObjectFieldID_FieldType] || this._field[fields.FieldType];
  }

  _isCustomConditionPeriod() {
    return this._field.isPeriod || this._field.isRangeSlider;
  }

  get isCustomConditionList() {
    if (this._isCustomConditionPeriod() || this._field.isWeekDateMode || this._field.isMonthDateMode) {
      return true;
    }

    return Boolean(this._field[fields.IsCustomConditionList]);
  }

  get customConditionList() {
    if (this._field.isWeekDateMode || this._field.isMonthDateMode) {
      return [FILTER_OPERATIONS_ID.equal];
    }

    if (this._isCustomConditionPeriod()) {
      return [FILTER_OPERATIONS_ID.between];
    }

    return this._field[fields.CustomConditionList];
  }

  get required() {
    return Boolean(this._field[fields.IsRequired]);
  }

  get isReadOnly() {
    return Boolean(this._field[fields.IsReadOnly]);
  }

  get cols() {
    return locateOnGrid(this._field).cols;
  }

  get offset() {
    return locateOnGrid(this._field).offset;
  }

  get newLine() {
    return locateOnGrid(this._field).newLine;
  }

  get displayField() {
    return this._field.LookupDisplayFieldName || this._field[fields.LinkedObjectID_DisplayObjectFieldID_Name];
  }

  get isShowInMainForm() {
    return !!this._field[fields.IsShowInMainForm];
  }

  get isCustomizable() {
    return !!this._field[fields.IsCustomizable];
  }
}
