import validationEngine from 'devextreme/ui/validation_engine';
import {ValidationResult} from 'devextreme/ui/validation_group';

function validate(group: string): Promise<ValidationResult | undefined> {
  try {
    const res = validationEngine.validateGroup(group);
    if (res.status === 'pending') return res.complete!;
    return Promise.resolve(res);
  } catch (e) {
    console.error(e);
  }
  return Promise.resolve(undefined);
}

function validationMessage(validationResult: ValidationResult | undefined): string {
  return validationResult?.brokenRules?.map(item => item.message).join(', ') || '';
}

export async function validateGroup(
  group: string
): Promise<{error?: Error; result?: ValidationResult; brokenNameRules?: string[]}> {
  let validationResult = await validate(group);
  let message = validationMessage(validationResult);
  const brokenNameRules = validationResult?.brokenRules?.map(
    (rule: any) => rule.validator?._validationInfo?.result?.name || ''
  );

  return {
    error: message ? new Error(message) : undefined,
    result: validationResult,
    brokenNameRules
  };
}
