import {ListDataSourceImpl} from 'middlewares/userScript/elems/dataSource';
import {D5ColumnsCollection} from 'middlewares/userScript/elems/listForm/D5ColumnsCollection';
import {D5Error} from 'services/SecondaryMethods/errors';
import {ListDataSource} from 'middlewares/userScript/elems/public-interfaces';
import {D5FormOptions} from 'middlewares/userScript/inner-interfaces';
import {NestedTableDataSource, TreeDataSource} from 'middlewares/userScript/elems/dataSource/D5DataSource';
import {D5ID, FormCreateMode, FormType} from 'services/interfaces/global-interfaces';
import {isEmptyValue, isNumeric, isObject, isString} from 'services/SecondaryMethods/typeUtils';
import {D5Cell, D5Row, getRow} from './D5Row';
import {columnDataToSourceData} from 'utilsOld/columnDataToSourceData';
import {D5FormWithToolbar} from '../D5FormWithToolbar';

type RowsToUpdate = Record<string, any>;

export class D5ListForm extends D5FormWithToolbar {
  protected _selectedRows: D5Row[] = [];
  protected readonly _datasource: ListDataSource;

  private readonly columns: D5ColumnsCollection | undefined;
  private _rowsToUpdate: RowsToUpdate = [];

  nestedFieldName: string | undefined;

  constructor(props: D5FormOptions) {
    super(props);

    const {sysForm, formDataSource, currentForm, columns, nestedData, nestedFieldName} = props;

    const {events, dataSource} = currentForm;
    this.nestedFieldName = nestedFieldName;

    const {Sys_FormFields, Type} = sysForm;

    switch (true) {
      case this.isNested:
        this._datasource = new NestedTableDataSource(
          formDataSource,
          events,
          dataSource!,
          nestedData!,
          Sys_FormFields || []
        );
        break;
      case Type === FormType.TREE:
        this._datasource = new TreeDataSource(formDataSource, events, dataSource!, Sys_FormFields || []);
        break;
      default:
        this._datasource = new ListDataSourceImpl(formDataSource, events, dataSource!, Sys_FormFields || []);
        break;
    }

    this.columns = columns;
  }

  get isNested(): boolean {
    return !!this.nestedFieldName;
  }

  get rowsToUpdate() {
    return this._rowsToUpdate;
  }

  get field() {
    return (name: string) => this.columns && this.columns.getField(name);
  }

  get fields() {
    return this.columns ? this.columns.getFields().filter(col => col.name != null) : [];
  }

  get isFixedOrder() {
    return !!this.currentForm.isFixedOrder;
  }

  set isFixedOrder(flag: boolean) {
    this.currentForm.isFixedOrder = !!flag;
  }

  get focusedCell(): D5Cell {
    const {cellValue, columnIndex, rowIndex, fieldName} = this.currentForm.focusOn;
    return {cellValue, columnIndex, rowIndex, fieldName};
  }

  protected getRows(): D5Row[] {
    return this._datasource.data.map((data: Record<string, any>): D5Row => getRow(data, this.keyField));
  }

  /**
   * Выделяет строки в DataGrid или TreeList.
   * Так же обновляет значение переменно _selectedRows.
   * Если записи еще нет в таблице, то выдает варнинг и ничего не делает.
   */
  protected selectByKeys(keys: Array<D5ID>) {
    this.checkSelectRowKeysType(keys);
    // Для Treelist не обов'язково враховувати ключі, які не видимі
    if (this.currentForm.formType === FormType.TREE) {
      // @ts-ignore
      this._selectedRows = keys;
      this.currentForm.selectedRowKeys = keys;

      if (typeof this.currentForm.events.selectRowsByKeys === 'function') {
        this.currentForm.events.selectRowsByKeys(keys);
      }
      return;
    }
    const [keysExist, notFoundKeys] = this.filterExistRowsByKey(keys);
    /** Если есть существующие ключи или изначально был пустой массив */
    if ((keys.length && keysExist.length) || !keys.length) {
      this._selectedRows = this.rowsByKey(keysExist);
      this.currentForm.selectedRowKeys = keysExist;
      if (typeof this.currentForm.events.selectRowsByKeys === 'function') {
        this.currentForm.events.selectRowsByKeys(keys);
      }
    }

    notFoundKeys.forEach(key => {
      D5Error.log('W2004', [String(key)]);
    });
  }

  /**
   * Фильтруем строки датасорса, по переданным ключам
   */
  private rowsByKey(keys: Array<D5ID>): D5Row[] {
    return this.getRows().filter(({key}: D5Row) => keys.includes(key));
  }

  /**
   * Возвращает два массива ключей.
   * Ключи которые существуют и ключи которые не существуют в датасорсе.
   */
  private filterExistRowsByKey(keys: Array<D5ID>): Array<Array<D5ID>> {
    const rowsKeys = this.getRows().map(row => row.key);
    const notFoundKeys: Array<D5ID> = [];

    const keysExist = keys.filter(key => {
      const rowExists = rowsKeys.includes(key);
      if (!rowExists) {
        notFoundKeys.push(key);
      }
      return rowExists;
    });

    return [keysExist, notFoundKeys];
  }

  private checkSelectRowKeysType(keys: Array<D5ID>) {
    if (!Array.isArray(keys)) {
      throw new TypeError('Select Row/Node keys error. Please check the keys type');
    }
  }

  protected getSelectedRows(): D5Row[] {
    if (typeof this.currentForm.events.selectedSourceRows === 'function') {
      this._selectedRows = this.currentForm.events.selectedSourceRows().map(data => {
        const {editableRowData} = this.currentForm;
        //если идет редактирование, то лучше взять строку отсюда, потому что в дс
        //на onRowEditing данные не актуальные
        if (!isEmptyValue(editableRowData) && editableRowData?.[this.keyField] === data[this.keyField]) {
          const row = columnDataToSourceData({
            data: editableRowData,
            viewSource: this.sysForm.Sys_FormFields || []
          });

          return getRow(row, this.keyField);
        }
        return getRow(data, this.keyField);
      });
    }
    return this._selectedRows || [];
  }

  protected getFocusedRow(): D5Row {
    const {data, key} = this.currentForm.focusOn;
    return {data, key};
  }

  getRowByKey(key: D5ID) {
    return this._datasource.rowsData.find(row => key === row[this.keyField]);
  }

  protected delRows(keys: Array<string | number>, remote: boolean = true) {
    const actions = keys.map(key => ({
      key,
      operation: FormCreateMode.DELETE,
      remote: !!remote
    }));

    if (!this.isNested) {
      this._rowsToUpdate.push(...actions);
    }

    this._datasource.data = (this._datasource.data as []).filter(row => {
      return !keys.includes(row[this.keyField]);
    });
  }

  protected editRow(key: D5ID, newRow: Record<string, any>, remote: boolean = true) {
    if (!isString(key) && !isNumeric(key)) throw D5Error.create('E2006');

    const rowData = this.getRowByKey(key) || {};

    const updatedRow = {...rowData, ...newRow};

    if (!this.isNested) {
      this._rowsToUpdate.push({
        key,
        operation: FormCreateMode.EDIT,
        data: updatedRow,
        remote: !!remote
      });
    }

    this._datasource.data = (this._datasource.data as []).map(row => {
      if (key === row[this.keyField]) return updatedRow;
      return row;
    });
  }

  protected addRow(newRow: Record<string, any>, remote: boolean = true) {
    if (!isObject(newRow)) throw D5Error.create('E2007');

    if (!this.isNested) {
      this._rowsToUpdate.push({
        operation: FormCreateMode.ADD,
        data: newRow,
        remote: !!remote
      });
    }

    this._datasource.data = [newRow, ...(this._datasource.data as [])];
  }

  /**
   * Обновляет записи по указанным ключам. Добавляет новые записи, удаляет ненайденные.
   * @param keys
   * @param [useFilter] - указывает использовать или нет фильтры примененные к форме в запросе на получение данных. По умолчанию - false
   */
  protected async refreshRecords(keys: (number | string)[], useFilter = false) {
    const {events} = this.currentForm;
    if (typeof events.refreshRecords === 'function') {
      return events.refreshRecords(keys, useFilter);
    }
    return true;
  }

  get datasource(): ListDataSource {
    return this._datasource;
  }

  public fixColumnsToLeft(columnNames: string[]) {
    columnNames.forEach(name => {
      const col = this.field(name);
      if (col) col.fixedToLeft = true;
    });
  }

  public openReportGroup(reportName: string, userData?: any) {
    const {events} = this.currentForm;
    if (typeof events.openReportGroup === 'function') {
      return events.openReportGroup(reportName, userData);
    }
    return true;
  }
}
