import {userConfigUrl, userCSSUrl} from 'services/baseUrl';
import {LOGIN_PAGE_US, system} from 'services/objects';

/**
 * !!!!!!!!! Внимание в этот модуль нельзя подключать node_modules
 */

const {
  CONFIG_PARAMS: {LOGIN_PAGE, APPLICATION},
} = system;

const url = userConfigUrl;

let cssId = 'customStyles';
if (!document.getElementById(cssId)) {
  let head = document.getElementsByTagName('head')[0];
  let link = document.createElement('link');
  link.id = cssId;
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = userCSSUrl;
  link.media = 'all';
  head.appendChild(link);
}

const defaultConfig = {
  [APPLICATION]: {
    [LOGIN_PAGE]: {...LOGIN_PAGE_US},
  },
};

/**
 * need for getting user settings from server
 * @type {function}
 * @returns {Promise<config.json | null>}
 * */

export const getConfigData = () =>
  fetch(url)
    .then(async response => {
      if (response.ok) {
        return await response.json();
      }
      return defaultConfig;
    })
    .catch(() => defaultConfig);
