import {createSlice} from '@reduxjs/toolkit';
import {mergeDeep} from 'immutable';
import {LOGIN_PAGE_US, system} from '../objects';
import {setSettings} from '../SecondaryMethods/userSettings';
import {UserSettingsState} from './types';
import {createAsyncThunk} from '../reduxUtils';
import loadDiagramConfig from 'services/userSettings/loadDiagramConfig';
import {IApplication} from '../applications/reducer';

const {
  CONFIG_PARAMS: {
    HOME_PAGE,
    TITLE,
    WINDOW_TITLE,
    FAVICON,
    DEFAULT_FILE,
    LOGO,
    LOGO_HEIGHT,
    VIEW_BOX,
    LOGIN_PAGE,
    SUBSYSTEM_PANEL,
    CUSTOM_FONTS,
    USER_SETTINGS_IS_LOADED,
    DOC_URL
  }
} = system;

const initialState: UserSettingsState = {
  [USER_SETTINGS_IS_LOADED]: false,
  [HOME_PAGE]: {},
  [TITLE]: null,
  [DOC_URL]: '',
  [WINDOW_TITLE]: null,
  [FAVICON]: {
    [DEFAULT_FILE]: null
  },
  [LOGIN_PAGE]: {...LOGIN_PAGE_US},
  [SUBSYSTEM_PANEL]: {
    [LOGO]: null,
    [LOGO_HEIGHT]: null,
    [VIEW_BOX]: null,
    [TITLE]: null
  },
  [CUSTOM_FONTS]: [],
  diagramConfig: undefined
};

type FetchUserConfigParams = Omit<UserSettingsState, 'diagramConfig'>;
const fetchUserConfig = createAsyncThunk<FetchUserConfigParams, FetchUserConfigParams>(
  'userSettings/fetchUserConfig',
  settings => {
    setSettings(system.USER_SETTINGS.TITLE, settings.title);
    return settings;
  }
);

const fetchDiagramConfig = createAsyncThunk(
  'userSettings/fetchDiagramConfig',
  (applications: IApplication[]): Promise<UserSettingsState['diagramConfig']> => loadDiagramConfig(applications)
);

const {
  actions: {userSettingsClear},
  reducer: userSettingsReducer
} = createSlice({
  name: 'userSettings',
  initialState: (): UserSettingsState => initialState,
  reducers: {
    userSettingsClear() {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserConfig.fulfilled, (state, action) => {
        return mergeDeep(state, {...action.payload, [USER_SETTINGS_IS_LOADED]: true});
      })
      .addCase(fetchDiagramConfig.fulfilled, (state, action) => {
        //@ts-ignore
        state.diagramConfig = action.payload;
      });
  }
});

export {fetchUserConfig, fetchDiagramConfig, userSettingsClear};
export default userSettingsReducer;
