import {fields} from 'services/objects';
import {SysForm} from 'services/interfaces/sysObjects';
import {
  ButtonCreators,
  ELayoutType,
  ILayoutItem,
  IListViewOptions
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {SysFormWrapper} from 'utilsOld/systemObjects';

function getProps({
  mainSysForm,
  parentID,
  defaultFilter,
  formKey,
  parentFormID
}: {
  mainSysForm: SysForm;
  parentID: number | null;
  defaultFilter?: string;
  formKey: string;
  parentFormID: string;
}): ILayoutItem<IListViewOptions> {
  const wrapper = new SysFormWrapper(mainSysForm);

  return {
    creator: ButtonCreators.FORM,
    name: mainSysForm.Name,
    //@ts-ignore
    id: mainSysForm.Name,
    itemType: ELayoutType.ListView,
    parentID,
    order: mainSysForm.Order,
    cols: mainSysForm[fields.Cols],
    rows: mainSysForm[fields.Rows],
    isVisible: true,
    title: mainSysForm[fields.Title],
    options: {
      id: `listview-form-${mainSysForm.Name}`,
      formID: mainSysForm.Name,
      parentFormID,
      name: mainSysForm.Name,
      formKey,
      toolBarViewType: mainSysForm.toolBarViewType,
      defaultFilter,
      isSubForm: false,
      listViewIsMultiselect: wrapper.listViewIsMultiselect,
      listViewIsSelectionEnabled: wrapper.listViewIsSelectionEnabled,
      listViewItemContentPadding: wrapper.listViewItemContentPadding,
      listViewItemHeight: wrapper.listViewItemHeight,
      listViewItemMargin: wrapper.listViewItemMargin,
      listViewItemMenuMode: wrapper.listViewItemMenuMode,
      listViewMaxRowCount: wrapper.listViewMaxRowCount,
      listViewShowScrollbarMode: wrapper.listViewShowScrollbarMode
    }
  };
}

const getTileList = ({
  mainSysForm,
  defaultFilter,
  formKey,
  parentFormID
}: {
  mainSysForm: SysForm;
  parentFormID: string;
  defaultFilter: string;
  formKey: string;
}) => {
  return getProps({
    mainSysForm: mainSysForm,
    parentID: mainSysForm.MainGroupID,
    defaultFilter: defaultFilter,
    formKey,
    parentFormID
  });
};

export default getTileList;
