import {createSelector} from 'reselect';
import {RootState} from '../../store';
import {ELEMENTS_NAMES_STATE} from './types';

export const elementsUserSettings = (state: RootState) => state.elementsUserSettings;

export const makeElementsUserSettingsSelector = () => {
  return createSelector(
    elementsUserSettings,
    (_: RootState, formKey: string) => formKey,
    (elementsUserSettings, formKey) => elementsUserSettings[formKey]
  );
};

function elementsSelectorFactory(property: ELEMENTS_NAMES_STATE) {
  return () =>
    createSelector(
      elementsUserSettings,
      (_: RootState, formKey: string) => formKey,
      (elementsUserSettings, formKey) => elementsUserSettings?.[formKey]?.[property]
    );
}

export const makeToolbarButtonsFromElementsUserSettingsSelector = elementsSelectorFactory(
  ELEMENTS_NAMES_STATE.TOOLBAR_BUTTONS
);
