import {D5FormOptions} from '../inner-interfaces';
import {D5FormWithToolbar} from './D5FormWithToolbar';

export default class D5ListView extends D5FormWithToolbar {
  protected _selectedItems: Record<string, any> = [];

  constructor(options: D5FormOptions) {
    super(options);
    this._selectedItems = options.currentForm.selectedRows;
  }

  get datasource() {
    return this.currentForm.dataSource;
  }

  get selectedItems() {
    return this._selectedItems.map((item: any) => {
      return {key: item.ID, data: item} || [];
    });
  }

  public async refresh() {
    const {events} = this.currentForm;
    if (typeof events.refresh === 'function') {
      await events.refresh({params: this.operationsParams});
    }

    return true;
  }
}
