import {D5Error} from 'services/SecondaryMethods/errors';
import {fields, system} from 'services/objects';
import {D5FormEdit} from '../elems/D5FormEdit';
import {D5TableForm, D5TreeForm} from '../elems/listForm';
import {D5ColumnsCollection} from '../elems/listForm/D5ColumnsCollection';
import {FormType} from 'services/interfaces/global-interfaces';
import {isArray, isFunction} from 'services/SecondaryMethods/typeUtils';
import {updateLayoutItemOptions} from 'services/SecondaryMethods/formItems/utils';
import {getFilterName} from 'services/SecondaryMethods/filterUtils';
import D5Kanban from '../elems/D5Kanban';
import D5Core from 'middlewares/userScript/elems/D5Core';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {CommonResult, D5FormOptions} from '../inner-interfaces';
import {FormObject} from './createFormObject';
import {D5BaseForm, ID5Core} from '../elems/public-interfaces';
import {
  ELayoutType,
  IFieldOptions,
  IFilterOptions,
  IFocusedField,
  ILayoutItem
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import deepEqual from 'fast-deep-equal';
import {D5Form} from '../elems/D5Form';
import {D5ReportForm} from '../elems/D5ReportForm';
import D5Scheduler from '../elems/D5Scheduler';
import D5TileList from '../elems/D5TileList';
import D5ListView from '../elems/D5ListView';

export {titlePositionToNum, titlePositionToStr} from './titleLocation';

const {
  FIELD_TYPE: {TEXT, NUMBER, BOOL}
} = system;

export function getSubFormsResult({
  srcSubForms,
  currentSubForms
}: {
  srcSubForms: FormObject[];
  currentSubForms: Array<D5Form>;
}): CommonResult[] {
  return currentSubForms.map((sub, i) => {
    /** должны быть отсортированы по ордеру и индексы совпадают
     * @type {FormObject} - то что сформировал createFormObject
     */
    const sysSub = srcSubForms[i];

    //TODO refactor this to a function which returns CommonResult
    return {
      apiOperation: sysSub.apiOperation.get(),
      buttons: sysSub.tableButtons,
      columns: sysSub.columns instanceof D5ColumnsCollection ? sysSub.columns.getProcessedCols() : null,
      editableRowData: sysSub.editableRowData,
      editorButtons: sysSub.editorButtons,
      elementsUserSettings: sysSub.elementsUserSettings,
      expandedState: sub.expandedState,
      filters: sysSub.filters,
      focusedItem: changedFocusedItem(sysSub.currentForm.focusedItem, sub.focusedItem),
      formData: sysSub.formData,
      formGroups: sysSub.formGroups,
      formID: String(sysSub.sysForm.ID),
      formItems: sysSub.formItems,
      formKey: sysSub.formKey,
      formType: sysSub.currentForm.formType,
      // @ts-ignore
      isFixedOrder: sub.isFixedOrder,
      isModified: sysSub.currentForm.isModified,
      silentClosing: sysSub.currentForm.silentClosing,
      // @ts-ignore
      isShowTitle: sub.isShowTitle,
      nestedData: sysSub.nestedData,
      nestedFieldName: sysSub.nestedFieldName,
      operationsParams: sysSub.currentForm.operationsParams,
      parentFormID: String(sysSub.currentForm.parentFormID || ''),
      rowsPerPage: sysSub.formDataSource.rowsPerPage,
      // @ts-ignore
      rowsToUpdate: sub.rowsToUpdate,
      selectedRowKeys: sysSub.currentForm.selectedRowKeys || [],
      selectedRows: sysSub.currentForm.selectedRows || [],
      title: sysSub.title,
      // @ts-ignore
      toolBarViewType: sub.toolBarViewType,
      userData: sysSub.currentForm.userData,
      filterDockPanel: sysSub.filterDockPanel,
      readOnly: sysSub.currentForm.readOnly,
      summary: sysSub.summaryData,
      schedulerResources: sysSub.schedulerResources,
      schedulerStartDayHour: (sub as D5Scheduler).startDayHour,
      schedulerEndDayHour: (sub as D5Scheduler).endDayHour,
    };
  });
}

export function changedFocusedItem(
  current: IFocusedField | undefined,
  next: IFocusedField | undefined
): IFocusedField | undefined {
  return !deepEqual(current, next) ? next : undefined;
}

/**
 * Проверка, есть ли такой юзерскрипт в window.
 */
export const formHasUserEvent = (formName: string, eventName: string | undefined) => {
  //@ts-ignore
  const core = new D5Core({user: {}});
  //@ts-ignore
  return isFunction(window.userScript?.[formName]?.({}, core)?.[eventName]);
};

/**
 * Проверка, есть ли такой юзерскрипт в window.
 */
export const getFormUserEvent = (formName: string, eventName: string, form: D5BaseForm, core: ID5Core) => {
  if (!formHasUserEvent(formName, eventName)) {
    return null;
  }
  //@ts-ignore
  return window.userScript[formName](form, core)[eventName];
};

export const runFormScriptAsync = async ({form, core, eventName, args}: any) => {
  const formName = form.name;
  const func = getFormUserEvent(formName, eventName, form, core);

  if (!isFunction(func)) {
    return;
  }

  // вызов пользовательской функции
  try {
    const result = func(...args);

    if (result instanceof Promise) {
      core.showLoader();
      await result.finally(() => {
        core.hideLoader();
      });
    }
  } catch (error) {
    D5Error.log('E2010', [formName, eventName, error]);
  }
};

export const runFormScriptSync = ({form, core, eventName, args}: any) => {
  const formName = form.name;
  const func = getFormUserEvent(formName, eventName, form, core);

  if (!isFunction(func)) {
    return;
  }

  // вызов пользовательской функции
  try {
    return func(...args);
  } catch (error) {
    D5Error.log('E2010', [formName, eventName, error]);
  }
};

export function itemGetter<T>(
  collection: T[],
  lambda: (item: T) => boolean,
  itemType: ELayoutType,
  name: string
): T | undefined {
  let result = collection.find(lambda);
  if (!result) D5Error.log('W2001', [name, itemType]);

  return result;
}

export function collectionWrapper<T>(
  collection: T[] & {has?: (name: string) => boolean},
  hasFunc: (name: string) => boolean
) {
  let t = collection;
  t.has = hasFunc;
  return t as Array<T> & {has: typeof hasFunc};
}

export function updateFieldItems(items: ILayoutItem<any>[], formData: Record<any, any>) {
  return updateLayoutItemOptions(
    items,
    item => LayoutType.isField(item.itemType),
    ({options: {objectName, displayExpr}}: ILayoutItem<IFieldOptions>) => {
      return {
        value: formData[objectName],
        displayValue: formData[`${objectName}.${displayExpr}`]
      };
    }
  );
}

export function updateFilterItems(
  items: ILayoutItem<any>[],
  filterModifier: (filterName: string, opts: IFilterOptions) => Partial<IFilterOptions>
) {
  return updateLayoutItemOptions(
    items,
    item => LayoutType.isFilter(item.itemType),
    ({options}) => {
      const filterName = getFilterName({
        objectFieldIDName: options.filterField.objectFieldIDName,
        name: options.filterField.name
      });
      return filterModifier(filterName, options);
    }
  );
}

/**
 * @param {Object} arg
 * @param {ILayoutItem<*>[]} arg.layoutItems
 * @param {string} arg.filterName
 * @returns {ILayoutItem<*>[]}
 */
export function updateLayoutFilterByName({layoutItems, filterName, operation, hasIsBlank, value, displayValue}: any) {
  return updateLayoutItemOptions(
    layoutItems,
    item => {
      let isFilter = LayoutType.isFilter(item.itemType);
      let {filterField} = item.options;
      return (
        isFilter &&
        getFilterName({
          objectFieldIDName: filterField.objectFieldIDName,
          name: filterField.name
        }) === filterName
      );
    },
    () => {
      return {
        realOperation: operation,
        hasIsBlank,
        value,
        displayValue
      };
    }
  );
}

/**
 * @param {D5FormOptions} options
 * @returns {D5Form}
 */
export function formFactory(options: D5FormOptions) {
  switch (options.sysForm[fields.Type]) {
    case FormType.EDIT:
    case FormType.MULTI_EDIT:
    case FormType.FREE_FORM:
      return new D5FormEdit(options);
    case FormType.NAV_EDIT:
      return new D5FormEdit(options);
    case FormType.LIST:
      return new D5TableForm(options);
    case FormType.TREE:
      return new D5TreeForm(options);
    case FormType.KANBAN:
      return new D5Kanban(options);
    case FormType.SCHEDULER:
      return new D5Scheduler(options);
    case FormType.TILE_LIST:
      return new D5TileList(options);
    case FormType.LIST_VIEW:
      return new D5ListView(options);
    case FormType.REPORT:
      return new D5ReportForm(options);
  }
}

/**
 * Валидирует датасорс , который пользователь подставляет в поле с типом Enumerator
 * @param  datasource датасорс-массив
 * @param fieldType тип поля (строка, число, етц.)
 */
export const validateEnumDataSource = (datasource: {key: string | number; value: any}[], fieldType: number) => {
  if (!isArray(datasource)) {
    throw new TypeError('validateEnumDataSource error. The property "datasource" should be an array.');
  }

  const allowedKeyType = {
    [TEXT]: 'string',
    [NUMBER]: 'number',
    [BOOL]: 'number'
  };

  const allowedFieldType = {
    [TEXT]: 'string',
    [NUMBER]: 'number',
    [BOOL]: 'boolean'
  };

  // @ts-ignore
  if (!allowedFieldType[fieldType]) {
    return true;
  }

  // @ts-ignore
  return datasource.some(record => typeof record.key !== allowedKeyType[fieldType]);
};
