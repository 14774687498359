import {
  ButtonColorScheme,
  ButtonCreators,
  ButtonStylingMode,
  ELayoutType,
  IButtonOptions,
  ILayoutItem
} from './itemInterfaces';
import {BUTTON_POSITION, BUTTON_TYPE} from '../../interfaces/global-interfaces';
import {gridConstants} from '../../../components/Layout/utils/variables';
import {system} from '../../objects';

const {TITLE, ICON_AND_TITLE} = system.TITLE_TYPE;

const {
  MIN_WIDTH: {BUTTON_MIN_WIDTH},
  MIN_HEIGHT: {BUTTON_MIN_HEIGHT}
} = gridConstants;

export interface INewButtonProps {
  name: string;
  clickEvent: string;
  groupName?: string;
  order?: number;
  title?: string;
  icon?: string;
}

interface Props {
  button: INewButtonProps;
  formID: string;
  groupID?: number;
}

export const getUSDefButton = ({button, formID, groupID}: Props): ILayoutItem<IButtonOptions> => {
  const buttonOptions: IButtonOptions = {
    icon: button.icon ?? '',
    defaultIcon: button.icon ?? '',
    title: button.title ?? '',
    defaultTitle: button.title ?? '',

    beginGroup: false,
    actionFormID: undefined,
    actionFormName: undefined,
    actionFormObjectName: undefined,
    actionFormType: undefined,
    actionFormViewMode: undefined,
    buttonFillingRules: undefined,
    buttonType: BUTTON_TYPE.CUSTOM,
    customOperation: '{}',
    customParameters: '{}',
    disabled: false,
    formID,
    formIDType: undefined,
    formOpenMode: undefined,
    isHideInOtherActions: false,
    onClick: () => null,
    position: BUTTON_POSITION.ALL,
    style: undefined,
    hotkey: undefined,
    hint: '',
    text: button.title,
    titleType: button.icon ? ICON_AND_TITLE : TITLE,
    clickEventName: button.clickEvent,
    buttonWidth: undefined,
    isEditButtonByDefault: false,
    isCustomizable: false,
    colorScheme: ButtonColorScheme.Default,
    stylingMode: ButtonStylingMode.None
  };

  return {
    creator: ButtonCreators.USERSCRIPT,
    groupID,
    // @ts-ignore
    id: button.name,
    isReadOnly: false,
    isVisible: true,
    cols: undefined,
    minHeight: BUTTON_MIN_HEIGHT,
    minWidth: BUTTON_MIN_WIDTH,
    itemType: ELayoutType.BUTTON,
    name: button.name,
    className: 'item-margin d-layout-item -button',
    idAttr: `${button.name ? `button-${button.name}` : ''}`,
    options: buttonOptions,
    order: button.order ?? 0,
    parentID: groupID ?? null,
    location: 'after',
    formFieldID: undefined,
    hint: '',
    docUrl: ''
  };
};
